import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Stack,
} from "@material-ui/core";
import {
  Timeline,
  TimelineContent,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  timelineItemClasses,
} from "@material-ui/lab";
import {
  ArrowDropDown,
  FiberManualRecord,
} from "@material-ui/icons";
import { useSelector } from "react-redux";
import { fTime } from "../../../../utils/formatTime";
import TimeLineComment from "../TimeLineComment";
import CustomerNoteTimeline from "./CustomerNoteTimeline";
import CustomerOrderTimeline from "./CustomerOrderTimeline";


CustomerTimeLine.propTypes = {
  customer: PropTypes.object,
  check: PropTypes.bool,
};

export default function CustomerTimeLine(props) {
  const { customer,check } = props;
  const store = useSelector((state) => state.auth.profilePayload);

  const [accordion, setAccordion] = useState(false);
  return (
    <>
    {customer?.msg ?(
      <>
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      <TimelineItem>
        <TimelineSeparator>
          <FiberManualRecord style={{ color: "green" }} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <>
            <Stack direction={"row"} justifyContent="space-between">
              <Stack
                direction={"row"}
                style={{ cursor: "pointer" }}
                onClick={() => setAccordion(!accordion)}
              >
                <p style={{ marginTop: -5, fontSize: "13px" }}>{customer.msg}</p>{" "}
                {customer?.tlData && (
                  <ArrowDropDown
                    style={{
                      cursor: "pointer",
                      marginTop: "-6px",
                      marginRight: "15px",
                    }}
                  />
                )}
              </Stack>

              <p
                style={{
                  fontSize: "14px",
                  color: "grey",
                  marginTop: -5
                }}
              >
                {" "}
                {fTime(customer?.cdate)}
              </p>
            </Stack>
            {accordion && (
              <>
              {customer?.type == "updateNote" &&
              <CustomerNoteTimeline note={customer?.tlData}/>
              }
              {customer?.type == "create Order" &&
              <CustomerOrderTimeline order={customer?.tlData}/>
              }
              </>
            )}
          </>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
    </>
    ):(
      <>
      {check && (
        <TimeLineComment comment={customer}/>
    )}
    </>
    )}
    </>
  );
}
