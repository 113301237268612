import axios from "axios";
import { toast } from "react-toastify";

const authToken = localStorage.getItem('accessToken');
console.log(authToken);
const client = axios.create({
  baseURL:  'https://api.planetshopping.com', //"https://api.planetshopping.com", //'http://localhost:5001', //,
  responseType: "json",
  headers: { Authorization: 'Bearer ' + authToken }
});

client.interceptors.response.use(response => {
  return response;
}, error => {
  toast.error('Something went wrong. Please try again.')
  if (error.response.status === 401) {
    window.localStorage.clear();
    window.location.replace('https://accounts.planetshopping.com/auth/logout')
    //place your reentry code
  }
  return error;
});


export default client;