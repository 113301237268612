import { Typography } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

ReturnTrackingTimeline.propTypes = {
  tracking: PropTypes.object,
};

export default function ReturnTrackingTimeline(props) {
  const { tracking } = props;
  return (
    <>
      {tracking?.carrier?.name && (
        <>
          <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
            Carrier
          </Typography>

          <Typography variant="body2">{tracking?.carrier?.name}</Typography>
        </>
      )}
      {tracking?.trackingNo && (
        <>
          <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
            Tracking number
          </Typography>
          <Typography variant="body2">{tracking?.trackingNo}</Typography>
        </>
      )}
    </>
  );
}
