import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { fDate,fDateTime } from "src/utils/formatTime";
import { Typography, Divider, Link } from "@material-ui/core";

OrderPaymentTimeline.propTypes = {
  payment: PropTypes.object,
  paymentTerms: PropTypes.object,
};

export default function OrderPaymentTimeline(props) {
  const { payment, paymentTerms } = props;
  const store = useSelector((state) => state.auth.profilePayload);
  return (
    <>
      {payment && (
        <>
          <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
            Order
          </Typography>
          <Link style={{ cursor: "pointer" }}>#{payment?.ordNo}</Link>
          <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
            Amount
          </Typography>
          <Typography variant="body2">
            {store?.currency?.symbol} {payment?.total}
          </Typography>
          <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
            Gateway
          </Typography>
          <Typography variant="body2">{payment?.gateway}</Typography>
          <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
            Status
          </Typography>
          <Typography variant="body2">{payment?.status}</Typography>
          <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
            Created
          </Typography>
          <Typography variant="body2">
            {fDateTime(payment?.cdate, store.format.timeZone)}
          </Typography>
        </>
      )}
      {paymentTerms && (
        <>
          <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
            Issue date
          </Typography>
          <Typography variant="body2">{fDate(paymentTerms?.cdate)}</Typography>
          <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
            Due date
          </Typography>
          <Typography variant="body2">
            {fDate(paymentTerms?.dueDate)}
          </Typography>
        </>
      )}
    </>
  );
}
