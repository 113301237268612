const initialState = 
{
    imagePayload: []
 }

export default function (state = initialState, action) {
   switch (action.type) {

      case 'GET_IMAGES':
         {
            return {
               ...state,
               imagePayload: action.payload
            };
         }
      default:
         return state;
   }
}