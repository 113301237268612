import React, { useState } from "react";
import PropTypes from "prop-types";
// import dayjs from "dayjs";
import { DateRangePicker } from "react-date-range";
import { ArrowRightAlt } from "@material-ui/icons";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { addDays } from "date-fns";
import { Button, Card, CardActions, Divider, Stack } from "@material-ui/core";

DatePicker.propTypes = {
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
  value: PropTypes.array,
};
export default function DatePicker(props) {
  const { onSelect, onClose, value } = props;
  const [state, setState] = useState(value);

  return (
    <Card>
      <DateRangePicker
        onChange={(item) => setState([item.selection])}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={state}
        maxDate={new Date()}
        direction="horizontal"
        color="#00AB55"
      />
     
      <CardActions>
        <Divider sx={{my: 2}}/>
        <Stack direction={"row"} spacing={2}  style={{position:"absolute", right:5, bottom:10}}>
          <Button
            onClick={() => onClose()}
            color="error"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={() => (onSelect(state), onClose())}
            variant="contained"
          >
            Apply
          </Button>
        </Stack>
      </CardActions>
    </Card>
  );
}
