import { toast } from "react-toastify";
import ApiExt from "../Provider/ApiExt";
import moment from "moment";

// Draft Order List
export function getAllDraftOrders(values) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    ApiExt.post("/api/order/get-all", values)
      .then(function (response) {
        // console.log('Get Product Action=================', response.data.data);
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GETALL_DRAFTORDER",
            payload: response?.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// OrderList
export function getOrders(values) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    ApiExt.post("api/order/get-all", values)
      .then(function (response) {
        // console.log('Get Product Action=================', response.data.data);
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_ORDER",
            payload: response?.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}


// FullfillDetails
export function getfullFillDetails(values) {
  return async function (dispatch) {
    dispatch({
      type: "FULFILL_DETAILS_LOADING",
      payload: true,
    });

    ApiExt.post("api/get-fulfill", {id:values})
      .then(function (response) {

        // console.log('Get Product Action=================', response.data.data);
        dispatch({
          type: "FULFILL_DETAILS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_FULFILL_DETAILS",
            payload: response?.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "FULFILL_DETAILS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}


// SingleOrder
export function getSingleOrder(_id) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    ApiExt.post("api/order/get", { oId: _id })
      .then(function (response) {
        // console.log('Get Product Action=================', response.data.data);
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_SINGLE_ORDER",
            payload: response.data.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// SingleOrder
export function orderPaymentCalculation(values) {
  return async function (dispatch) {
    dispatch({
      type: "IS_SUBMITTING",
      payload: true,
    });
    ApiExt.post("/api/order/payment-calculation", values)
      .then(function (response) {
        // console.log('Get Product Action=================', response.data.data);
        dispatch({
          type: "IS_SUBMITTING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "PAY_CALCULATION",
            payload: response.data.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_SUBMITTING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// InsertOrder
export function saveOrder(values, items, callback, setLoading) {
  return async function (dispatch) {
    setLoading(true);

    const data = {
      cid: values?.cid,
      shipAdd: {
        fName: values?.shipAdd?.fName,
        lName: values?.shipAdd?.lName,
        com: values?.shipAdd?.com,
        apertment: values?.shipAdd?.apertment,
        city: values?.shipAdd?.city,
        country: values?.shipAdd?.country?._id ? values?.shipAdd?.country?._id : values?.shipAdd?.country,
        province: values?.shipAdd?.province?._id ? values?.shipAdd?.province?._id : values?.shipAdd?.province,
        pincode: values?.shipAdd?.pincode,
        address: values?.shipAdd?.address,
        phone: values?.shipAdd?.phone,
        ext: values?.shipAdd?.ext?._id ? values?.shipAdd?.ext?._id : values?.shipAdd?.ext,
      },
      billAdd: {
        fName: values?.billAdd?.fName,
        lName: values?.billAdd?.lName,
        com: values?.billAdd?.com,
        apertment: values?.billAdd?.apertment,
        city: values?.billAdd?.city,
        country: values?.billAdd?.country?._id ? values?.billAdd?.country?._id : values?.billAdd?.country,
        province: values?.billAdd?.province?._id ? values?.billAdd?.province?._id : values?.billAdd?.province,
        pincode: values?.billAdd?.pincode,
        address: values?.billAdd?.address,
        phone: values?.billAdd?.phone,
        ext: values?.billAdd?.ext?._id ? values?.billAdd?.ext?._id : values?.billAdd?.ext,
      },

      payMethod: values?.payMethod,
      items: items?.map((item) => {
        if (item?.product) {
          return {
            qty: item?.qty,
            pid: item?.pid,
            vid: item?._id,
            rate: item?.price,
          };
        } else {
          return {
            qty: item?.qty,
            pid: item?._id,
            rate: item?.price,
          };
        }
      }),
      note: {
        value: values?.note || "",
      },
      tags: values.tags,
      discount:
        values?.discount == {}
          ? values?.discount
          : {
              type: values.discount?.disType || "",
              value: +values.discount?.disVal || 0,
              disReason: values.discount?.disReason || "",
            },
      shipping:{
              id: "",
              value: +values.shipping?.shipval || 0,
              shipReason: values.shipping?.shipReason || "",
            },
      dueDate: values?.dueDate || "",
      tax: values?.tax,
    };
    ApiExt.post("/api/order/insert", data)
      .then(function (response) {
        setLoading(false);
        if (response.data.status) {
          if (response.data.url) {
            window.location = response.data.url;
          }
          toast.success(response.data.msg);
          callback(response.data);
        }else{
          toast.warning(response.data.msg);
          callback(response.data);
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
        
      });
  };
}

//UpdateOrderNotes
export function UpdateOrderNotes(id, values, action, onClose) {
  return async function (dispatch) {
    action.setSubmitting(true);

    const data = {
      orderId: id,
      note: {
        value: values.value,
      },
    };

    ApiExt.post("api/order/update-note", data)
      .then(function (response) {
        action.setSubmitting(false);
        onClose();
        if (response.data.status === true) {
          action.resetForm();
          toast.success("Notes has been updated");
          dispatch({
            type: "UPDATE_ORDER_NOTE",
            payload: values.value,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        action.setSubmitting(false);

        onClose();
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// UpdateShippingAddress
export function UpdateShippingAddress(id, type, values, onClose, setLoading) {
  return async function (dispatch) {
    setLoading(true);
    const data = {
      oid: id,
      type: type,
      address: {
        fName: values.fName,
        lName: values.lName,
        com: values.com,
        apertment: values.apertment,
        city: values.city,
        country: values.country?._id,
        province: values.province?._id,
        pincode: values.pincode.toString(),
        address: values.address,
        phone: values.phone.toString(),
        ext: values?.ext?.dial_code,
      },
    };
    ApiExt.post("/api/order/update-address", data)
      .then(function (response) {
        // console.log('Update Order Address=================', response.data);
        setLoading(false);
        onClose();
        if (response.data.status === true) {
          toast.success("Address Updated");
          dispatch({
            type: "UPDATE_SHIPPING_ADDRESS",
            payload: data.address,
          });

          // dispatch({
          //   type: "UPDATE_FULFILL_SHIPPING_ADDRESS",
          //   payload: data.address,
          // });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        onClose();
        toast.error("Something went wrong. Please try again.");
      });
  };
}

//UpdateOrderTags
export function updateOrderTags(_id, values, action, onClose) {
  return async function (dispatch) {
    action.setSubmitting(true);
    const data = {
      orderId: _id,
      tags: values.tags,
    };

    ApiExt.post("api/order/update-tags", data)
      .then(function (response) {
        // console.log('Update Order Tag=================', response.data);
        action.setSubmitting(false);
        onClose();
        if (response.data.status === true) {
          action.resetForm();
          toast.success("Tag has been updated");
          dispatch({
            type: "UPDATE_ORDER_TAGS",
            payload: values.tags,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        action.setSubmitting(false);

        onClose();
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// DeleteOrder
export const deleteOrder = (_id) => {
  return async function (dispatch) {
    ApiExt.post("api/order/delete", { oId: _id })
      .then(function (response) {
        if (response.data.status === true) {
          toast.success(response.data.msg);
        } else {
          toast.warn(response.data.msg);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong. Please try again.");
      });
  };
};

// Archive Order
export const ArchiveOrder = (_id) => {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });
    ApiExt.post("/api/order/archive", { oId: _id })
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });

        if (response.data.status === true) {
          toast.success(response.data.msg);
          dispatch({
            type: "ORDER_ARCHIVE",
            payload: _id,
          });
        } else {
          toast.warn(response.data.msg);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong. Please try again.");
      });
  };
};

// UnArchive Order
export const UnArchiveOrder = (_id) => {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });
    ApiExt.post("/api/order/unarchive", { oId: _id })
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          toast.success(response.data.msg);
          dispatch({
            type: "ORDER_UNARCHIVE",
            payload: _id,
          });
        } else {
          toast.warn(response.data.msg);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong. Please try again.");
      });
  };
};

// markAsReturn
export const MarkReturn = (_id, returnId) => {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    const data = {
      returnId: returnId,
      oId: _id,
    };

    ApiExt.post("/api/order/mark-return", data)
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });

        if (response.data.status === true) {
          toast.success("Order marked as returned");
          dispatch({
            type: "MARK_AS_RETURN",
            payload: returnId,
          });
        } else {
          toast.warn(response.data.msg);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong. Please try again.");
      });
  };
};

// cancelReturn
export const cancelReturn = (_id, returnId, callback, setLoading) => {
  return async function (dispatch) {
    // dispatch({
    //   type: "IS_LOADING",
    //   payload: true,
    // });
    setLoading(true);
    const data = {
      returnId: returnId,
      oId: _id,
    };

    ApiExt.post("/api/order/cancel-return", data)
      .then(function (response) {
        // dispatch({
        //   type: "IS_LOADING",
        //   payload: false,
        // });
        setLoading(false);
        if (response.data.status === true) {
          toast.success(response.data.msg);
          dispatch({
            type: "CANCEL_RETURN",
            payload: returnId,
          });
          callback(response.data.status);
        } else {
          toast.warn(response.data.msg);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
};

// cancelFulFill
export const cancelFulFill = (_id, fullfillId, callback, setLoading) => {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });
    setLoading(true);

    const data = {
      fulfillId: fullfillId,
      oId: _id,
    };

    ApiExt.post("api/order/cancel-fulfill ", data)
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        setLoading(false);

        if (response.data.status === true) {
          toast.success("Fulfill canceled");
          dispatch({
            type: "CANCEL_FULFILL",
            payload: fullfillId,
          });
          callback();
        } else {
          toast.warn(response.data.msg);
        }
      })
      .catch((error) => {
        setLoading(false);
        // toast.error("Something went wrong. Please try again.");
      });
  };
};

// Get All Country
export function cancelReason(values) {
  return async function (dispatch) {
    ApiExt.post("/api/cancel-reason", values)
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "CANCEL_REASON",
            payload: response?.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

//Cancel Order
export function cancelOrder(_id, values, onClose, setLoading, callback) {
  return async function (dispatch) {
    setLoading(true);
    const data = {
      oId: _id,
      reasonId: values.reasonId,
      items: values?.items
    };

    ApiExt.post("/api/order/cancel", data)
      .then(function (response) {
        // console.log('Update Order Tag=================', response.data);
        onClose();
        if (response.data.status === true) {
          setLoading(false);
          toast.success(response?.data?.msg);
          dispatch({
            type: "CANCEL_ORDER",
            payload: data,
          });
          callback();
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        onClose();
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Insert Fulfill Items
export function insertFulfill(id, values, action) {
  return async function (dispatch) {
    action.setSubmitting(true);
    const data = {
      oId: id,
      data: {
        locId: values?.locId,
        shipAdd: {
          fName: values?.shipAdd?.fName,
          lName: values?.shipAdd?.lName,
          com: values?.shipAdd?.com,
          address: values?.shipAdd?.address,
          apertment: values?.shipAdd?.apertment,
          city: values?.shipAdd?.city,
          country: values?.shipAdd?.country,
          province: values?.shipAdd?.province,
          pincode: values?.shipAdd?.pincode,
          phone: values?.shipAdd?.phone,
          ext: values?.shipAdd?.ext,
        },
        items: values?.items?.map((item) => {
          return {
            qty: item?.qty,
            id: item?.pid,
            rate: item?.rate,
            disc: item?.disc,
            total: item?.qty * item?.rate,
            status: "active",
          };
        }),
        trackingNo: values?.trackingNo,
        carrier: values?.carrier,
        status: "active",
      },
    };

    ApiExt.post("/api/order/insert-fulfill", data)
      .then(function (response) {
        // console.log("Insert Fulfill=================", response.data);
        action.setSubmitting(false);

        if (response.data.status === true) {
          action.resetForm();
          toast.success("Order fulfilled");
          dispatch({
            type: "INSERT_FULFILL",
            payload: data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        action.setSubmitting(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Payment Method
export function getPayment(values) {
  return async function (dispatch) {
    ApiExt.post("/api/payment-method", values)
      .then(function (response) {
        // console.log('Get Product Action=================', response.data.data);
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "PAYMENT_METHOD",
            payload: response?.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Update Order
export function updateOrder(_id, values, setLoading, callback) {
  return async function (dispatch) {
    setLoading(true);
    const value = {
      oId: _id,
      data: {
        items: values?.items?.map((op) => {
          return {
            qty: op?.qty,
            changeQty: op?.changeQty,
            pid: op?.pid,
            vid: op?.vid || "",
            rate: op?.rate,
            total: op?.total,
            fQty: op?.fQty || 0,
            retQty: op?.retQty || 0,
            ufQty: op?.ufQty || 0,
            newProduct: op?.newProduct ? op?.newProduct : false,
          };
        }),
      },
    };
    ApiExt.post("api/order/update", value)
      .then(function (response) {
        setLoading(false);

        if (response.data.status === true) {
          // action.resetForm();
          toast.success("Order has been updated");
          dispatch({
            type: "UPDATE_ORDER",
            payload: value,
          });
          callback();
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// MarkAsPaid
export const submitMarkAsPaid = (_id, setLoading, callback) => {
  return async function (dispatch) {
    setLoading(true);

    ApiExt.post("/api/order/mark-Paid", { oId: _id })
      .then(function (response) {
        setLoading(false);

        if (response.data.status === true) {
          toast.success(response.data.msg);
          dispatch({
            type: "MARK_AS_PAID",
            payload: _id,
          });
          callback();
        } else {
          toast.warn(response.data.msg);
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error("Something went wrong. Please try again.");
      });
  };
};

// UpdatePayment
export function UpdatePayment(id, payMethod, dueDate, setLoading) {
  return async function (dispatch) {
    setLoading(true);
    const data = {
      oId: id,
      payMethod: payMethod,
      dueDate: dueDate,
    };
    ApiExt.post("/api/order/update-payment-method", data)
      .then(function (response) {
        setLoading(false);

        if (response.data.status === true) {
          toast.success("Update Payment Method");

          dispatch({
            type: "UPDATE_PAYMENT_METHOD",
            payload: data,
          });
          if (response.data.url) {
            window.location = response.data.url;
          }
        } else {
          toast.warn(response.data.msg);
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error("Something went wrong. Please try again.");
      });
  };
}

//Customer Update
export function UpdateCustomer(id, values) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });
    const data = {
      oId: id,
      data: {
        cid: values._id,
        shipAdd: values?.addresses?.find((x) => x.default),
        billAdd: values?.addresses?.find((x) => x.default),
      },
    };

    ApiExt.post("/api/order/update-customer", data)
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          toast.success(response?.data?.msg);
          dispatch({
            type: "UPDATE_CUSTOMER",
            payload: values,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

//Customer Remove
export function RemoveCustomer(id) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    ApiExt.post("/api/order/remove-customer", { oId: id })
      .then(function (response) {
        // console.log('Get Product Action=================', response.data.data);
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          toast.success(response?.data?.msg);
          dispatch({
            type: "REMOVE_CUSTOMER",
            payload: response.data.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Print Order
export const printOrder = (_id, callback) => {
  return async function (dispatch) {
    ApiExt.post("/api/order/print", { oId: _id })
      .then(function (response) {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong. Please try again.");
      });
  };
};

// Print Order
export const orderSlip = (_id, callback) => {
  return async function (dispatch) {
    // setLoading(true);

    ApiExt.post("/api/order/packing-slip", { oId: _id })
      .then(function (response) {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong. Please try again.");
      });
  };
};

export function orderExport(values, setLoading, callback) {
  return async function (dispatch) {
    setLoading(true);

    ApiExt.post("/api/order/export", values)
      .then(function (response) {
        setLoading(false);
        if (response.data.status === true) {
          toast.success(response?.data?.msg);
          callback(response?.data?.data);
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// SingleOrder
export function OrderTransaction(_id) {
  return async function (dispatch) {
    ApiExt.post("/api/order/transaction", { oId: _id })
      .then(function (response) {
        // console.log('Get Product Action=================', response.data.data);

        if (response.data.status === true) {
          dispatch({
            type: "ORDER_TRANCTION",
            payload: response.data.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        toast.error("Something went wrong. Please try again.");
      });
  };
}
