import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import {
  Typography,
  FormControl,
  FormControlLabel,
  Divider,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Checkbox,
} from "@material-ui/core";
import { Close, CloudUpload } from "@material-ui/icons";
import { LoadingButton } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import { DefaultWorkBook } from "src/assets";
import {
    ExportSalesbyproductVariant,
  SalesbyproductVendorExport
} from "src/redux/actions/AnalyticsAction";

SalesbyproductVariantExport.propTypes = {
  sethandleExport: PropTypes.func,
  data: PropTypes.object,
};

export default function SalesbyproductVariantExport(props) {
  const { sethandleExport, data } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const submitExport = () => {
    dispatch(
        ExportSalesbyproductVariant(data, (response) => {
            ExportProducts(response);
      }, setLoading)
    );
    sethandleExport(false);
    setOpen(false);
  };

  const ExportProducts = (products) => {
    if (products?.length > 0) {
      products.map((op) => {
        op.title = op.variant.product.title;
        op.variantTitle = op.variant.title;
        op.sku = op.variant.sku;
      });

      const fields = [];      
      fields.push({
        display: "Product Title",
        key: "title",
        excelKey: "Product Title",
        type: "s",
      });
      fields.push({
        display: "Variant Title",
        key: "variantTitle",
        excelKey: "Variant Title",
        type: "s",
      });
      fields.push({
        display: "Variant SKU",
        key: "sku",
        excelKey: "Variant SKU",
        type: "s",
      });
      fields.push({
        display: "Net Quantity",
        key: "qty",
        excelKey: "Net Quantity",
        type: "n",
      }); 
      fields.push({
        display: "Discounts",
        key: "discount",
        excelKey: "Discounts",
        type: "n",
      });
      fields.push({
        display: "Tax",
        key: "tax",
        excelKey: "Tax",
        type: "n",
      });
      fields.push({
        display: "Total Sale",
        key: "total",
        excelKey: "Total Sale",
        type: "n",
      });
      const wbResult = DefaultWorkBook(products, fields, "ProductVariantReport_");
    } else {
      toast.warn("No data selected for export");
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => (setOpen(false), sethandleExport(false), setValue(""))}
        maxWidth="sm"
      >
        <DialogTitle id="responsive-dialog-title">
          Export your report
          <IconButton
            aria-label="close"
            onClick={() => (setOpen(false), sethandleExport(false))}
            sx={{
              position: "absolute",
              right: 8,
              top: 10,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider style={{ marginTop: 10 }} />
        <DialogContent>
          <DialogContentText>
            <Typography style={{ fontSize: "14px" }}>
              Report will be exported as a CSV (comma separated values) table.
            </Typography>

            
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => (
              setOpen(false), sethandleExport(false), setValue("")
            )}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            onClick={() => submitExport()}
            loading={loading}
          >
            Export Prodcut Variant
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
