const initialState = { inventoryPayload: [] }

export default function (state = initialState, action) {
   switch (action.type) {

      case 'GET_ALL_INVENTORY':
         {
            return {
               ...state,
               inventoryPayload: action.payload
            };
         }

         // case 'UPDATE_INVENTORY':
         //    console.log("a", action.payload)
         // {
         //    const inventory = state.inventoryPayload.map((item) => {
         //       if (item._id == action.payload.id) {
         //         item.value = true;
         //         return item;
         //       } else {
         //         item.default = false;
         //         return item;
         //       }
         //     });
       
         //     return {
         //       ...state,
         //       singlecustomerPayload: {
         //         ...state.singlecustomerPayload,
         //         addresses: addresses,
         //       },
         //     };
         // }
      default:
         return state;
   }
}
