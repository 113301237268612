const initialState = {
  allNotificationPayload: [],
  notificationPreviewPayload: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case "GET_ALL_NOTIFICATIONS": {
      return {
        ...state,
        allNotificationPayload: action.payload,
      };
    }
    case "GET_NOTIFICATION_PREVIEW": {
      return {
        ...state,
        notificationPreviewPayload: action.payload,
      };
    }

    case "GET_SINGLE_NOTIFICATION": {
      return {
        ...state,
        singleNotificationPayload: action.payload,
      };
    }

    // case "UPDATE_NOTIFICATIONS": {
    //   return {
    //     ...state,
    //     singleNotificationPayload: action.payload,
    //   };
    // }

    case "UPDATE_SINGLE_NOTIFICATION": {
      const updateNotification = state.allNotificationPayload.map((item) => {
        if (item.name == action.payload.name) {
          item.status = action.payload.status;
        }
        return item;
      });
      return {
        ...state,
        allNotificationPayload: updateNotification,
      };
    }
    default:
      return state;
  }
}
