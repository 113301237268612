const initialState = { productPayload: [], productTypePayload: [], exportproductPayload: [] };

export default function (state = initialState, action) {
  switch (action.type) {
    case "GET_PRODUCT": {
      return {
        ...state,
        productPayload: action.payload,
      };
    }
    case "EXPORT_PRODUCT": {
      return {
        ...state,
        exportproductPayload: action.payload,
      };
    }
    case "GET_ALL_PRODUCT": {
      return {
        ...state,
        allProductPayload: action.payload,
      };
    }
    case "PRODUCT_TYPE": {
      return {
        ...state,
        productTypePayload: action.payload,
      };
    }
    case "GET_SINGLE_PRODUCT": {
      return {
        ...state,
        singleproductPayload: action.payload,
      };
    }
    case "UNARCHIVED_PRODUCT": {
      return {
        ...state,
        singleproductPayload: {
          ...state.singleproductPayload,
          status: "draft",
        },
      };
    }

    case "ARCHIVED_PRODUCT": {
      return {
        ...state,
        singleproductPayload: {
          ...state.singleproductPayload,
          status: "archive",
        },
      };
    }

   //  case "DELETE_PRODUCT": {
   //    console.log(...state.productPayload.filter((x) => x._id != action.payload));
   //    return {
   //      ...state,
   //      productPayload: {
   //       ...state.productPayload.filter((x) => x._id != action.payload)?.map((value) => {
   //          return value
   //        }),
   //      },
   //    };
   //  }
    default:
      return state;
  }
}
