// hooks
// import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';
import { useSelector } from 'react-redux';
// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  // const { user } = useAuth();
  const user = useSelector(state => state.auth.profilePayload)
  return (
    <MAvatar
      src={ user?.user?.avatar === "" ? '/static/mock-images/avatars/avatar_default.jpg' : user?.user?.avatar}
      alt={user?.user?.firstName + user?.user?.lastName}
      color={user?.user?.avatar ? 'default' : createAvatar(user?.user?.firstName + user?.user?.lastName).color}
      {...other}
    >
      {createAvatar(user?.user?.firstName + user?.user?.lastName).name}
    </MAvatar>
  );
}
