import React from "react";
import {
  Container,
  Card,
  Button,
  Stack,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Checkbox,
  Typography,
  Divider,
  useTheme,
  Chip,
  LinearProgress,
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  Link,
  Pagination,
  Paper,
} from "@material-ui/core";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";
import { PATH_DASHBOARD } from "../../routes/paths";
import Scrollbar from "src/components/Scrollbar";
import { FilterList, ViewColumn } from "@material-ui/icons";
import { useNavigate } from "react-router";

export default function Reports() {
  const navigate = useNavigate();
  return (
    <>
      <Page title="Reports">
        <Container>
          <Typography variant="h4" marginBottom={3}>
            Reports
          </Typography>
          <Card sx={{ p: 3, mt: 2 }}>
            
            <Divider sx={{ mx: "-24px", my: 2 }} />
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableRow style={{ borderBottom: "1px solid #d5d5d5" }}>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Category</TableCell>
                  <TableCell align="left">Author</TableCell>
                </TableRow>

                <TableRow
                  style={{
                    borderBottom: "1px solid #d5d5d5",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    navigate(PATH_DASHBOARD.report.sales_over_time)
                  }
                >
                  <TableCell align="left">Sales over time</TableCell>
                  <TableCell align="left">Sells</TableCell>
                  <TableCell align="left">Demo Test</TableCell>
                </TableRow>

                <TableRow
                  style={{
                    borderBottom: "1px solid #d5d5d5",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    navigate(PATH_DASHBOARD.report.average_order_value)
                  }
                >
                  <TableCell align="left">
                    Average order value over time
                  </TableCell>
                  <TableCell align="left">Sells</TableCell>
                  <TableCell align="left">Demo Test</TableCell>
                </TableRow>

                <TableRow
                  style={{
                    borderBottom: "1px solid #d5d5d5",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    navigate(PATH_DASHBOARD.report.sales_by_product)
                  }
                >
                  <TableCell align="left">Sales by products</TableCell>
                  <TableCell align="left">Sells</TableCell>
                  <TableCell align="left">Demo Test</TableCell>
                </TableRow>

                <TableRow
                  style={{
                    borderBottom: "1px solid #d5d5d5",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    navigate(PATH_DASHBOARD.report.orders_over_time)
                  }
                >
                  <TableCell align="left">Orders over time</TableCell>
                  <TableCell align="left">Sells</TableCell>
                  <TableCell align="left">Demo Test</TableCell>
                </TableRow>

                <TableRow
                  style={{
                    borderBottom: "1px solid #d5d5d5",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    navigate(PATH_DASHBOARD.report.sales_by_product_vendor)
                  }
                >
                  <TableCell align="left">Sales by product vendor</TableCell>
                  <TableCell align="left">Sells</TableCell>
                  <TableCell align="left">Demo Test</TableCell>
                </TableRow>

                <TableRow
                  style={{
                    borderBottom: "1px solid #d5d5d5",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    navigate(PATH_DASHBOARD.report.sales_by_product_variant_sku)
                  }
                >
                  <TableCell align="left">
                    Sales by product variant SKU
                  </TableCell>
                  <TableCell align="left">Sells</TableCell>
                  <TableCell align="left">Demo Test</TableCell>
                </TableRow>

                {/* <TableRow style={{ borderBottom: "1px solid #d5d5d5", cursor:"pointer" }}
                onClick={() =>
                    navigate(PATH_DASHBOARD.report.average_order_value)
                  }>
                  <TableCell align="left">Sales By Products</TableCell>
                  <TableCell align="left">Sells</TableCell>
                  <TableCell align="left">Demo Test</TableCell>
                </TableRow>

                <TableRow style={{ borderBottom: "1px solid #d5d5d5", cursor:"pointer" }}
                onClick={() =>
                    navigate(PATH_DASHBOARD.report.average_order_value)
                  }>
                  <TableCell align="left">Sales By Products</TableCell>
                  <TableCell align="left">Sells</TableCell>
                  <TableCell align="left">Demo Test</TableCell>
                </TableRow> */}
              </Table>
            </TableContainer>
          </Card>
        </Container>
      </Page>
    </>
  );
}
