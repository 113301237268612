const initialState = {
    preferencePayload: {},
    // singleOrderPayload :[]
    //  productTypePayload: []
  };

  export default function (state = initialState, action) {
    switch (action.type) {
      case "GET_PREFERENCE": {
        return {
          ...state,
          preferencePayload: action.payload,
        };
      }
  
      case "UPDATE_PREFERENCE": {
        return {
            ...state,
            preferencePayload: {
              ...state.preferencePayload,
              preference: action.payload,
            },
          };
      }
      
    default:
        return state;
    }
  }
  