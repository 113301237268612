import React from "react";
import PropTypes from "prop-types";
import {
  Divider,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useNavigate } from "react-router";

RcvInventoryTimeline.propTypes = {
  product: PropTypes.object,
};

export default function RcvInventoryTimeline(props) {
  const { product } = props;
  const navigate = useNavigate();
  return (
    <>
      <Table size="small">
        <TableBody>
          <TableRow
            style={{
              cursor: "pointer",
              borderBottom: "1px solid #d5d5d5",
            }}
          >
            <TableCell>
              <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
                Products
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
                Accepted
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
                Rejected
              </Typography>
            </TableCell>
          </TableRow>
          {product?.items?.map((value) => {
            return (
              <>
                <TableRow
                  style={{
                    cursor: "pointer",
                    borderBottom: "1px solid #d5d5d5",
                  }}
                >
                  <TableCell>
                    <Link
                      onClick={() =>
                        navigate(
                          `${PATH_DASHBOARD.products.edit}/${value?.pid}`
                        )
                      }
                      style={{ cursor: "pointer", fontSize: "13px" }}
                    >
                      {value?.variant
                        ? value?.product + " " + "/" + " " + value?.variant
                        : value?.product}{" "}
                    </Link>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body2">{value?.acptQty}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body2">{value?.rejctQty}</Typography>
                  </TableCell>
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}
