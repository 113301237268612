import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";

CancelReturnTimeline.propTypes = {
  canRet: PropTypes.object,
};

export default function CancelReturnTimeline(props) {
  const { canRet } = props;
  return (
    <>
      <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
        Items
      </Typography>
      {canRet?.items?.map((value) => {
        return (
          <>
            <Typography variant="body2">
              {value?.qty} x{" "}
              {value?.variant?.title
                ? value?.product?.title +
                  " " +
                  "/" +
                  " " +
                  value?.variant?.title
                : value?.product?.title}
            </Typography>
          </>
        );
      })}
    </>
  );
}
