import { toast } from "react-toastify";
import ApiExt from "../Provider/ApiExt";

//Get All Checkout
export function getAllCheckout(values) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    ApiExt.post("/api/checkout/get-all", values)
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_ALL_CHECKOUTS",
            payload: response?.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

//Get All Checkout
export function getCheckout(id) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    ApiExt.post("/api/checkout/get", { checkoutId: id })
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_SINGLE_CHECKOUT",
            payload: response?.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Update Abendoned checkout
export function updateAbendoned(id, note, setLoading) {
  return async function (dispatch) {
    setLoading(true);

    const data = {
      checkoutId: id,
      note: note,
    };

    ApiExt.post("/api/checkout/update-abandoned", data)
      .then(function (response) {
        setLoading(false);

        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          toast.success(response?.data?.msg);
          dispatch({
            type: "UPDATE_BRAND",
            payload: data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        // toast.error("Something went wrong. Please try again.");
      });
  };
}

//Get Setting Checkout
export function getSettingCheckout(values) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    ApiExt.post("/api/settings/get-checkout", values)
    
      .then(function (response) {
        console.log("response", response?.data?.status);
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status) {
          dispatch({
            type: "GET_SETTING_CHECKOUT",
            payload: response?.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Update Setting checkout
export function UpdateSettingCheckout(values, setLoading,callback) {
  return async function (dispatch) {
    setLoading(true);

    const data = {
      cust_contact: values?.cust_contact || "",
      cust_info: {
        name: values?.name || "",
        com: values?.com || "",
        address: values?.address || "",
        shipping: values?.shipping || "",
      },
      ship_use_in_bill: values?.ship_use_in_bill || false,
      use_add_auto: values?.use_add_auto || false,
      arch_aft_fnf: values?.arch_aft_fnf || false,
      auto_abd_mail: values?.auto_abd_mail || false,
      abd_mail_to: values?.abd_mail_to || "",
      abd_mail_aft: values?.abd_mail_aft || "",
      is_email: values?.is_email || false,
      is_sms: values?.is_sms || false,
      paid_order: values?.paid_order || "",
    };

    ApiExt.post("/api/settings/update-checkout", data)
      .then(function (response) {
        setLoading(false);

        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          toast.success(response?.data?.msg);
          callback();
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        // toast.error("Something went wrong. Please try again.");
      });
  };
}
