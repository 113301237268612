const initialState = { domainPayload: [] };

export default function (state = initialState, action) {
  switch (action.type) {
    case "GETALL_DOMAIN": {
      return {
        ...state,
        domainPayload: action.payload,
      };
    }
    case "GET_DOMAIN": {
      return {
        ...state,
        getdomainPayload: action.payload,
      };
    }
    case "DELETE_DOMAIN": {
      const items = state.domainPayload.filter(
        (id) => id._id != action.payload
      );
      return {
        ...state,
        domainPayload: items,
      };
    }
    case "CONNECTED_DOMAIN": {
      const items = state.domainPayload.map((item) => {
         if (item._id == action.payload) {
           item.status = "connect";
           return item;
         } else {
           return item;
         }
       });
      return {
        ...state,
        domainPayload: items,
      };
    }
    default:
      return state;
  }
}
