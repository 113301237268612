const initialState = { submitPayload: false }

export default function (state = initialState, action) {
    switch (action.type) {
 
       case 'IS_SUBMITTING':
          {
             return {
                ...state,
                submittingPayload: action.payload
             };
          }
       case 'IS_LOADING':
          {
             return {
                ...state,
                loadingPayload: action.payload
             };
          }

          case 'FULFILL_DETAILS_LOADING':
            {
               return {
                  ...state,
                  fullFillLoading: action.payload
               };
            }   
       default:
          return state;
    }
 }
 