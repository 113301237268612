const initialState = {
  FulfillPayload: [],
  //  productTypePayload: []
};
export default function (state = initialState, action) {
  switch (action.type) {
    case "SET_FULFILL": {
      return {
        ...state,
        FulfillPayload: action.payload,
      };
    }

    case "UPDATE_FULFILL_QTY": {
      const items = state.FulfillPayload.map((item) => {
        if (item._id == action.payload.id) {
          item.qty = action.payload.qty;
          return item;
        } else {
          return item;
        }
      });

      return {
        ...state,
        FulfillPayload: items,
      };
    }

   

    case "GET_SHIPPING_CARRIER": {
      return {
        ...state,
        carrierPayload: action.payload,
      };
    }

    case "UPDATE_FULFILL_SHIPPING_ADDRESS": {
      return {
        ...state,
        FulfillPayload: {
          ...state.FulfillPayload,
          shipAdd: action.payload,
        },
      };
    }
    default:
      return state;
  }
}
