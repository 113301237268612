import React, { useEffect, useState } from "react";
import Page from "../../../components/Page";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import { PATH_DASHBOARD } from "../../../routes/paths";

import {
  Card,
  Grid,
  Typography,
  Stack,
  Avatar,
  Link,
  CardHeader,
  Button,
  LinearProgress,
  Container,
  CardContent,
  CardActions,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
  DialogActions,
  Alert,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { getPlans, updatePlan } from "src/redux/actions/billingAction";
import { Close, Star } from "@material-ui/icons";
import moment from "moment";

export default function UpgradePlans() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const store = useSelector((state) => state.auth.profilePayload);
  const plans = useSelector((state) => state.billing.plansPayload);
  const isLoading = useSelector((state) => state.loader.loadingPayload);
  const [downgradeModel, setdowngradeModel] = useState(false);
  const [open, setOpen] = useState(false);

  const [upgrade, setupgrade] = useState(false);
  const [planId, setPlanId] = useState("");
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState();
  const [currentPrice, setCurrentPrice] = useState();
  const [msg, setMsg] = useState("");

  const newDate = moment().format("MM/DD/YYYY");
  const expiry = moment(store?.plan?.exp?.end).format("MM/DD/YYYY");
  useEffect(() => {
    dispatch(getPlans());
  }, []);

  const SelectPlan = (selectedPlan) => {
    setOpen(true);
    setMsg("");
    setPlanId(selectedPlan?._id);
    const currentPlan = plans.find((op) => op?._id == store?.plan?.id);

    if (currentPlan?.size <= selectedPlan?.size) {
      setupgrade(true);
      setAmount(selectedPlan.price);
    } else {
      setupgrade(false);
      setAmount(currentPlan.price);
    }
  };
  const handleUpdatePlan = () => {
    dispatch(
      updatePlan(
        planId,
        (data) => {
          if (data?.status) {
            setOpen(false);
          } else {
            setMsg(data?.msg);
          }
        },
        setLoading
      )
    );
  };

  return (
    <>
      <Page title="Upgrade Plan">
        <Container>
          {isLoading && <LinearProgress style={{ width: "100%" }} />}
          {!isLoading && (
            <>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="outlined"
                  style={{
                    marginBottom: "16px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    // minHeight: "42px",
                    minWidth: "0px",
                    height: "43px",
                    borderRadius: "5px",
                  }}
                  component={RouterLink}
                  to={PATH_DASHBOARD.setting.plan}
                >
                  <img
                    width="17px"
                    src="https://th.bing.com/th/id/R.9562e3998adf90b7ea409f041aab337d?rik=ERFU3YLhocBkdA&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2fKin%2feBp%2fKineBpprT.png&ehk=t39BBIm39HbdTD3%2bBxa%2bIfTm948yrYikFKGQivAW9UU%3d&risl=&pid=ImgRaw&r=0"
                  />
                </Button>
                <HeaderBreadcrumbs
                  heading="Upgrade Plan"
                  links={[
                    { name: "Dashboard", href: PATH_DASHBOARD.root },
                    {
                      name: "Setting",
                      href: PATH_DASHBOARD.setting.root,
                    },
                    { name: "Plan", href: PATH_DASHBOARD.setting.plan },
                    { name: "Upgrade Plan" },
                  ]}
                />
              </Stack>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Card sx={{ p: 3, mt: 5 }}>
                    <h4>Upgrade Your Plan</h4>
                    <Container maxWidth="md" component="main" sx={{ mt: 5 }}>
                      <Grid container spacing={5} alignItems="flex-end">
                        {plans?.map((plan) => (
                          <Grid item key={plan?.title} xs={12} sm={6} md={4}>
                            <Card
                              style={{
                                backgroundColor:
                                  plan?._id === store?.plan?.id
                                    ? "#4caf5017"
                                    : plan?.title == "Trial Pack" &&
                                      "#9e9e9e36",
                              }}
                            >
                              <CardHeader
                                title={plan?.title}
                                subheader={plan?.subheader}
                                titleTypographyProps={{ align: "center" }}
                                action={
                                  plan?._id == store?.plan?.id ||
                                  plan?.title == "Trial Pack" ? (
                                    <Star color={"success"} />
                                  ) : null
                                }
                                subheaderTypographyProps={{
                                  align: "center",
                                }}
                                sx={{
                                  backgroundColor: (theme) =>
                                    theme.palette.mode === "light"
                                      ? theme.palette.grey[200]
                                      : theme.palette.grey[700],
                                  mb: 5,
                                }}
                              />
                              <CardContent>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "baseline",
                                  }}
                                >
                                  <Typography
                                    component="h2"
                                    variant="h3"
                                    color="text.primary"
                                  >
                                    {plan?.symbol}
                                    {plan?.price}
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    color="text.secondary"
                                  >
                                    {plan?.days == 60 ? "for 2" : "/"}mo
                                  </Typography>
                                </Box>
                                <Typography color="text.primary" align="center">
                                  Renewal : {plan?.symbol}
                                  {plan?.renewal ? plan?.renewal : 0}
                                </Typography>
                                <Typography
                                  align="center"
                                  style={{ height: "120px" }}
                                >
                                  {plan?.desc}
                                </Typography>
                              </CardContent>
                              <CardActions>
                                <Button
                                  fullWidth
                                  variant={"outlined"}
                                  disabled={
                                    plan?.title == "Trial Pack"
                                      ? true
                                      : plan?._id == store?.plan?.id
                                      ? newDate > expiry
                                        ? false
                                        : true
                                      : false
                                  }
                                  onClick={() => {
                                    SelectPlan(plan);
                                  }}
                                >
                                  {plan?._id == store?.plan?.id
                                    ? newDate > expiry
                                      ? "Renew Plan"
                                      : "Active Plan"
                                    : "Select Plan"}
                                </Button>
                              </CardActions>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </Container>
                  </Card>
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      </Page>

      {/* upgrade downgrade && upgrade model*/}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        fullWidth
      >
        <DialogTitle id="responsive-dialog-title">
          {upgrade ? "Upgarade Your Plan" : "Downgarade Your plan"}
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 10,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider style={{ marginTop: 10 }} />
        <DialogContent>
          <DialogContentText>
            {msg && (
              <Alert variant="outlined" severity="warning" sx={{ mb: 5 }}>
                {msg}
              </Alert>
            )}
            {upgrade ? (
              <Typography>
                {" "}
                You want to Upgrade your plan, so you have to pay{" "}
                <b>
                  ({store?.currency?.symbol}
                  {amount})
                </b>
                .
              </Typography>
            ) : (
              <Typography>
                You have to alredy paid{" "}
                <b>
                  ({store?.currency?.symbol}
                  {amount})
                </b>{" "}
                for previous plan and now you want to downgrade your plan so
                your payable amount is not refunded.
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Close
          </Button>
          <Button
            variant="outlined"
            loading={loading}
            onClick={() => handleUpdatePlan()}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
