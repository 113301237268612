const initialState = {
    allCarrierPayload: [],
    allShippingPayload: []
  };
  export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_ALL_CARRIER": {
          return {
            ...state,
            allCarrierPayload: action.payload,
          };
        }

        case "GET_CARRIER": {
          return {
            ...state,
            carrierPayload: action.payload,
          };
        }

        case "CREATE_CARRIER": {
          return {
            ...state,
            allCarrierPayload: [
              ...state.allCarrierPayload,
                action.payload,
            ]
             
          };
        }

        case "UPDATE_CARRIER": {
         
          const map = state.allCarrierPayload.map((val) => {
            if (val?._id == action.payload.id) {
              return {
                ...val,
                name: action.payload.name,
                tUrl: action.payload.tUrl,
              };
            } else {
              return val;
            }
          });

          return {
            ...state,
            allCarrierPayload: map             
          };
        }

        case "DELETE_CARRIER": {
          
          return {
            ...state,
            allCarrierPayload: [
              ...state.allCarrierPayload?.filter((x) => x._id != action.payload)
            ]
             
          };
        }

        case "COUNTRY_STATE": {
          return {
            ...state,
            countryStatePayload: action.payload,
          };
        }

        case "GET_ALL_SHIPPING": {
          return {
            ...state,
            allShippingPayload: action.payload,
          };
        }

        case "GET_SHIPPING": {
          return {
            ...state,
            shippingPayload: action.payload,
          };
        }

        default:
      return state;
    }
  }