import {
  Stack,
  Box,
  Card,
  Divider,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Paper,
  Container,
  Button,
  Typography,
  LinearProgress,
  TableBody,
  Popover,
} from "@material-ui/core";
import Page from "src/components/Page";
import React, { useEffect, useRef, useState } from "react";
import {
  FilterList,
  ViewColumn,
  Today,
  ArrowDropDown,
} from "@material-ui/icons";
import { PATH_DASHBOARD } from "src/routes/paths";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Salesbyproduct } from "src/redux/actions/AnalyticsAction";
import { fDate } from "src/utils/formatTime";
import ListHead from "../ListTable/ListHead";
import SalesbyProductExport from "../Export-analytics-report/SalesbyProductExport";
import ReactToPrint from "react-to-print";
import DatePicker from "../DateTimePicket/DatePicker";
import { addDays } from "date-fns";

export default function SalesByProductReport() {
  const dispatch = useDispatch();
  const componentRef = useRef();
  const [searchParams] = useSearchParams();
  const product = useSelector((state) => state.analytics.SalesbyproductPayload);
  const isLoading = useSelector((state) => state.loader.loadingPayload);
  const store = useSelector((state) => state.auth.profilePayload);
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState([
    {
      startDate: addDays(new Date(), -30),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [handleExport, sethandleExport] = useState(false);
  const [dataforImport, setDataforImport] = useState(date);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (searchParams?.get("since")) {
      setDate([
        {
          startDate: new Date(searchParams?.get("since")),
          endDate: new Date(searchParams?.get("until")),
          key: "selection",
        },
      ]);
      dispatch(
        Salesbyproduct([
          {
            startDate: new Date(searchParams?.get("since")),
            endDate: new Date(searchParams?.get("until")),
            key: "selection",
          },
        ])
      );
    } else {
      dispatch(Salesbyproduct(date));
    }
  }, []);

  let qty = 0;
  let gross = 0;
  let discount = 0;
  let tax = 0;
  let total = 0;

  product?.map((op) => {
    qty += op.qty;
    gross += op.gross;
    discount += op.discount;
    tax += op.tax;
    total += op.total;
  });

  const ComponentToPrint = React.forwardRef((props, ref) => {
    const { value } = props;
    return (
      <div className="print-source" ref={ref}>
        <Page>
          <Container>
            {/* dates */}
            <Stack direction="row" spacing={1} marginTop={2} marginBottom={2}>
              <Button
                variant="outlined"
                startIcon={<Today />}
                onClick={(event) => (
                  setOpen(true), setAnchorEl(event.currentTarget)
                )}
              >
                {fDate(date[0]?.startDate) == fDate(date[0]?.endDate)
                  ? (fDate(date[0]?.startDate) && fDate(date[0]?.endDate)) ==
                    fDate(new Date())
                    ? "Today"
                    : (fDate(date[0]?.startDate) &&
                        fDate(date[0]?.endDate)) ==
                      fDate(addDays(new Date(), -1))
                    ? "Yesterday"
                    : fDate(date[0]?.startDate)
                  : fDate(date[0]?.startDate) +
                    " - " +
                    fDate(date[0]?.endDate)}
              </Button>
              {open && (
                <Popover
                  id={Boolean(anchorEl) ? "simple-popper" : undefined}
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <DatePicker
                    onSelect={(selected) => {
                      if (selected) {
                        setDate(selected);
                        dispatch(Salesbyproduct(selected));
                      }
                    }}
                    onClose={() => setAnchorEl(null)}
                    value={date}
                  />
                </Popover>
              )}
            </Stack>
            <Card sx={{ p: 3, mt: 2 }}>
            <TableContainer component={Paper}>
              {isLoading && <LinearProgress style={{ width: "100%" }} />}
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableRow
                  style={{
                    borderBottom: "1px solid #d5d5d5",
                    backgroundColor: "#80808024",
                  }}
                >
                  <TableCell align="left">Product title</TableCell>
                  <TableCell align="left">Product vendor</TableCell>
                  <TableCell align="right">Net quantity</TableCell>
                  <TableCell align="right">Gross sales</TableCell>
                  <TableCell align="right">Discounts</TableCell>
                  <TableCell align="right">Tax</TableCell>
                  <TableCell align="right">Total sales</TableCell>
                </TableRow>

                {!isLoading && (
                  <>
                    <TableBody>
                      {value?.length > 0 ? (
                        <>
                          {value?.length > 1 && (
                            <TableRow
                              style={{ borderBottom: "1px solid #d5d5d5" }}
                            >
                              <TableCell>
                                <b>Summary</b>
                              </TableCell>
                              <TableCell></TableCell>
                              <TableCell align="right">{qty}</TableCell>
                              <TableCell align="right">
                                {store.currency.symbol} {gross}
                              </TableCell>
                              <TableCell align="right">
                                {store.currency.symbol} {discount}
                              </TableCell>
                              <TableCell align="right">
                                {store.currency.symbol} {tax}
                              </TableCell>
                              <TableCell align="right">
                                {store.currency.symbol} {total}
                              </TableCell>
                            </TableRow>
                          )}
                          {value?.map((item) => {
                            return (
                              <TableRow
                                style={{ borderBottom: "1px solid #d5d5d5" }}
                              >
                                <TableCell align="left">
                                  {item?.product?.title}
                                </TableCell>
                                <TableCell align="left">
                                  {item?.vendor?.name}
                                </TableCell>
                                <TableCell align="right">{item.qty}</TableCell>
                                <TableCell align="right">
                                  {store.currency.symbol} {item.gross}
                                </TableCell>
                                <TableCell align="right">
                                  {store.currency.symbol} {item.discount}
                                </TableCell>
                                <TableCell align="right">
                                  {store.currency.symbol} {item.tax}
                                </TableCell>
                                <TableCell align="right">
                                  {store.currency.symbol} {item.total}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </>
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan={7}>
                            <Typography style={{ marginTop: 50, fontSize: 15 }}>
                              <b>No data found for the date range selected</b>
                            </Typography>
                            <Typography
                              style={{
                                fontSize: 13,
                                marginBottom: 20,
                                color: "grey",
                              }}
                            >
                              Please select a different period.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </>
                )}
              </Table>
            </TableContainer>
            </Card>
          </Container>
        </Page>
      </div>
    );
  });
  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <Button
            variant="outlined"
            style={{
              //marginBottom: "16px",
              paddingTop: "5px",
              paddingBottom: "5px",
              // minHeight: "42px",
              minWidth: "0px",
              height: "43px",
              borderRadius: "5px",
            }}
            component={RouterLink}
            to={
              searchParams?.get("since")
                ? PATH_DASHBOARD.general.analytics
                : PATH_DASHBOARD.report.reports
            }
          >
            <img
              width="17px"
              src="https://th.bing.com/th/id/R.9562e3998adf90b7ea409f041aab337d?rik=ERFU3YLhocBkdA&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2fKin%2feBp%2fKineBpprT.png&ehk=t39BBIm39HbdTD3%2bBxa%2bIfTm948yrYikFKGQivAW9UU%3d&risl=&pid=ImgRaw&r=0"
            />
          </Button>
          <Typography variant="h5">Sales by products</Typography>
        </Stack>
        <Stack spacing={2} direction="row">
          <Button
            style={{ marginRight: 15 }}
            onClick={() => sethandleExport(true)}
          >
            Export
          </Button>
          <ReactToPrint
            trigger={() => <Button>Print</Button>}
            content={() => componentRef.current}
          />
          {handleExport && (
            <SalesbyProductExport
              sethandleExport={sethandleExport}
              data={dataforImport}
            />
          )}
        </Stack>
      </Stack>
      <ComponentToPrint ref={componentRef} value={product} />
    </>
  );
}
