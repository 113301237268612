import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import {
  Typography,
  FormControl,
  FormControlLabel,
  Divider,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Checkbox,
} from "@material-ui/core";
import { Close} from "@material-ui/icons";
import { LoadingButton } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { DefaultWorkBook } from "src/assets";
import { ExportTotalSale } from "src/redux/actions/AnalyticsAction";
import moment from "moment";

SalesoverTimeExport.propTypes = {
  sethandleExport: PropTypes.func,
  data: PropTypes.object,
};

export default function SalesoverTimeExport(props) {
  const { sethandleExport, data } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const store = useSelector((state) => state.auth.profilePayload);
  const isLoading = useSelector((state) => state.loader.loadingPayload);

  const submitExport = () => {
    dispatch(
      ExportTotalSale(data.today, data.compare, setLoading, (response) => {
         Exportorder(response);
      })
    );
    sethandleExport(false);
    setOpen(false);
  };

  const Exportorder = (orders) => {

    const todayArray = [];
    const compareArray = [];

    const todayloop = orders?.today?.map((op) => {
      var Difference_In_Time =
      data.today[0].endDate.getTime() - data.today[0].startDate.getTime();
      var Days_Calcualtion = Difference_In_Time / (1000 * 3600 * 24);
      if (Days_Calcualtion == 0.999999988425926 && Days_Calcualtion == 0) {
        var convertDate = moment(op._id)
          .format("MMM D YYYY hh:mm A")
          .toLocaleString();
      } else if (Days_Calcualtion > 0 && Days_Calcualtion <= 90) {
        var convertDate = moment(op._id).format("MMM D YYYY");
      } else if (Days_Calcualtion > 90) {
        var convertDate = moment(op._id).format("MMM YYYY");
      }
      return {
        ...op,
        day: convertDate,
      };
    });

    const todayLabel = orders?.todayLabel?.map((label) => {
      const findToday = todayloop.find((val) => val.day == label);
      if (findToday) {
        if (findToday.day == label) {
          todayArray.push({
            day: label,
            ordreCount: findToday.ordreCount,
            total: findToday.total,
            discount: findToday.discount,
            grossAmt: findToday.grossAmt,
            netAmt: findToday.netAmt,
            shipping: findToday.shipping,
            tax: findToday.tax,
          });
        }
      } else {
        todayArray.push({
          day: label,
          ordreCount: 0.0,
          total: 0.0,
          discount: 0.0,
          grossAmt: 0.0,
          netAmt: 0.0,
          shipping: 0.0,
          tax: 0.0,
        });
      }
    });

    const compareloop = orders?.comapre?.map((com) => {
      var Difference_In_Time =
      data.compare[0].endDate.getTime() - data.compare[0].startDate.getTime();
      var Days_Calcualtion = Difference_In_Time / (1000 * 3600 * 24);
      if (Days_Calcualtion == 0.999999988425926 && Days_Calcualtion == 0) {
        var convertDate = moment(com._id).format("MMM D YYYY hh:mm A");
      } else if (Days_Calcualtion > 0 && Days_Calcualtion <= 90) {
        var convertDate = moment(com._id).format("MMM D YYYY");
      } else if (Days_Calcualtion > 90) {
        var convertDate = moment(com._id).format("MMM YYYY");
      }
      return {
        ...com,
        day: convertDate,
      };
    });

    const compareLabel = orders?.compareLabel?.map((comlabel) => {
    
      const findCompare = compareloop.find((item) => item.day == comlabel);
      if (findCompare) {
        if (findCompare.day == comlabel) {
          compareArray.push({
            day: comlabel,
            ordreCount: findCompare.ordreCount,
            total: findCompare.total,
            discount: findCompare.discount,
            grossAmt: findCompare.grossAmt,
            netAmt: findCompare.netAmt,
            shipping: findCompare.shipping,
            tax: findCompare.tax,
          });
        }
      } else {
        compareArray.push({
          day: comlabel,
          ordreCount: 0.0,
          total: 0.0,
          discount: 0.0,
          grossAmt: 0.0,
          netAmt: 0.0,
          shipping: 0.0,
          tax: 0.0,
        });
      }
    });

    if (todayArray?.length > 0) {
     
      const fields = [];
      fields.push({
        display: "Day",
        key: "day",
        excelKey: "Day",
        type: "s",
      });
      fields.push({
        display: "Orders",
        key: "ordreCount",
        excelKey: "Orders",
        type: "n",
      });
      fields.push({
        display: "Gross Sale",
        key: "grossAmt",
        excelKey: "Gross Sale",
        type: "n",
      });
      fields.push({
        display: "Discounts",
        key: "discount",
        excelKey: "Discounts",
        type: "n",
      });
      fields.push({
        display: "Shipping",
        key: "shipping",
        excelKey: "Shipping",
        type: "n",
      });
      fields.push({
        display: "Tax",
        key: "tax",
        excelKey: "Tax",
        type: "n",
      });
      fields.push({
        display: "Total Sale",
        key: "total",
        excelKey: "Total Sale",
        type: "n",
      });
      const wbResult = DefaultWorkBook(todayArray, fields, "OrderTotalSale_");
    } else {
      toast.warn("No data selected for export");
    }
    if(value){
    if (compareArray?.length > 0) {     
      const fields = [];
      fields.push({
        display: "Day",
        key: "day",
        excelKey: "Day",
        type: "s",
      });
      fields.push({
        display: "Orders",
        key: "ordreCount",
        excelKey: "Orders",
        type: "n",
      });
      fields.push({
        display: "Gross Sale",
        key: "grossAmt",
        excelKey: "Gross Sale",
        type: "n",
      });
      fields.push({
        display: "Discounts",
        key: "discount",
        excelKey: "Discounts",
        type: "n",
      });
      fields.push({
        display: "Shipping",
        key: "shipping",
        excelKey: "Shipping",
        type: "n",
      });
      fields.push({
        display: "Tax",
        key: "tax",
        excelKey: "Tax",
        type: "n",
      });
      fields.push({
        display: "Total Sale",
        key: "total",
        excelKey: "Total Sale",
        type: "n",
      });
      const wbResult = DefaultWorkBook(todayArray, fields, "OrderTotalSaleCompare_");
    } else {
      toast.warn("No data Found for Comprasion for export");
    }
  }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => (setOpen(false), sethandleExport(false), setValue(""))}
        maxWidth="sm"
      >
        <DialogTitle id="responsive-dialog-title">
          Export your report
          <IconButton
            aria-label="close"
            onClick={() => (setOpen(false), sethandleExport(false))}
            sx={{
              position: "absolute",
              right: 8,
              top: 10,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider style={{ marginTop: 10 }} />
        <DialogContent>
          <DialogContentText>
            <Typography style={{ fontSize: "14px" }}>
              Report will be exported as a CSV (comma separated values) table.
            </Typography>

            <FormControl component="fieldset">
              <Typography style={{ fontSize: "13px" }}>Export</Typography>
              <FormControlLabel
                value={value}
                onChange={(e) => setValue(e.target.checked)}
                control={<Checkbox />}
                label="Include comparison data (separate csv file)"
              />
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => (
              setOpen(false), sethandleExport(false), setValue("")
            )}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            onClick={() => submitExport()}
            loading={loading}
          >
            Export order
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
