import { format, formatDistanceToNow } from "date-fns";
import moment from "moment-timezone";

// import moment from 'moment/moment';
// ----------------------------------------------------------------------

export function fDate(date, zone) {
  var _format = "dd MMMM yyyy";

    if (!zone) {
      return format(new Date(date), _format);
    } else {
      return moment(new Date(date), _format).tz(zone).format(_format);
    }

}

export function cDate(date) {
  return format(new Date(date), "dd MMMM");
}

export function fTime(time) {
  return format(new Date(time), "hh:mm a");
}

export function fDateTime(date, zone, _format) {
  if (!_format) {
    _format = "DD MMM YY, hh:mm a";
  }

  if (date) {
    if (!zone) {
      return format(new Date(date), _format);
    } else {
      return moment(new Date(date), _format).tz(zone).format(_format);
    }
  }
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function formattedNumber(number) {
  const num = number;
  const options = { maximumFractionDigits: 2 };
  const formattedNumber = Intl.NumberFormat("en-US", options).format(num);
  return formattedNumber;
}
