const initialState = 
{
    LegalLoading: false,
    isToast:false,
 }

export default function (state = initialState, action) {
   switch (action.type) {

      case 'IS_LOADING':
         {
            return {
               ...state,
               LegalLoading: action.payload
            };
         }
      default:
         return state;
   }
}