import React from "react";
import PropTypes from "prop-types";
import { Typography, Link } from "@material-ui/core";
import { useNavigate } from "react-router";
import { PATH_DASHBOARD } from "src/routes/paths";

CustomerOrderTimeline.propTypes = {
  order: PropTypes.object,
};

export default function CustomerOrderTimeline(props) {
  const { order } = props;
  const navigate = useNavigate();
  return (
    <>
      <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
        Order :
      </Typography>
      <Link
        style={{ cursor: "pointer", fontSize: "13px" }}
        onClick={() =>
          navigate(`${PATH_DASHBOARD.order.view}/${order?.oId}`)
        }
      >
        {order?.ordNo}
      </Link>
    </>
  );
}
