const initialState = { transferPayload: [] };

export default function (state = initialState, action) {
  switch (action.type) {
    case "GET_ALL_TRANSFER": {
      return {
        ...state,
        transferPayload: action.payload,
      };
    }
    case "GET_SINGLE_TRANSFER": {
      return {
        ...state,
        singleTransferPayload: action.payload,
      };
    }

    case "GET_ALL_RECEIV_INVENTORY": {
      return {
        ...state,
        receiveInventoryPayload: action.payload,
      };
    }
    default:
      return state;
  }
}
