import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useLocation, Link as RouterLink, useParams } from 'react-router-dom';
import uuid from 'react-uuid';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Container, Drawer } from '@material-ui/core';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import { MHidden } from '../../components/@material-extend';
//
import { Form, FormikProvider, useFormik } from "formik";
import { LoadingButton, TreeView, TreeItem } from "@material-ui/lab";
import {
  Stack,
  Switch,
  TextField,
  FormControlLabel,
  Divider,
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem
} from "@material-ui/core";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MailIcon from '@material-ui/icons/Mail';
import AddIcon from '@material-ui/icons/AddCircleRounded';
import HeaderIcon from '@material-ui/icons/PictureInPictureRounded';
import FooterIcon from '@material-ui/icons/PictureInPictureAltRounded';
import {
  setEditorPage, publishTheme, setSettingsData
} from "../../redux/actions";

import { ThemeEditor, SettingsEditor } from 'src/components/editor';
import { useDispatch, useSelector } from "react-redux";
import {
  ChromeReaderModeOutlined, SubjectOutlined, ArrowBack, Delete, Visibility,
  VisibilityOff, ViewColumn, Report, ListAlt, Collections, LocalOffer, Https,
  PhonelinkLock, Inventory, PhotoSizeSelectLarge, BurstMode, HowToReg, Lock,
  WebAsset, Photo, ViewCarousel, Videocam, EditAttributes, Note, Notes, List,
  Settings, CalendarViewDay
} from '@material-ui/icons';
import { flexibleCompare } from '@fullcalendar/react';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 350;

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    color: theme.palette.text.primary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,

    },
  },
  secondaryContent: {
    color: "#00AB55",
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular
    },
  },
  group: {
    marginLeft: 0,
    '& $content, $secondaryContent': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1)
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
  iconContainer: {
    fontSize: '18px',
    width: '18px !important'
  }
}));

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

// ----------------------------------------------------------------------

EditorSidebar.propTypes = {
  page: PropTypes.string,
  frameRef: PropTypes.object,
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  schema: PropTypes.object,
  data: PropTypes.object,
  themeId: PropTypes.string,
  onUpdate: PropTypes.func,
};

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, add, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        group: classes.group,
        label: classes.label,
        expanded: classes.expanded,
        selected: classes.selected,
        content: add ? classes.secondaryContent : classes.content,
        iconContainer: classes.iconContainer
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  root: {

    flexGrow: 1,
    maxWidth: 400,
  },
  header_title: {
    padding: '16px'
  },
  divider: {
    marginTop: '16px'
  },
  actionButton: {
    padding: '8px',
    display: 'block',
    margin: '5px',
    borderRadius: '5px',
    color: '#212b36',
  },
  activeButton: {
    backgroundColor: '#c4e6c4'
  }
});

const sectionStyle = {
  //Sections
  "404": { title: "404", icon: Report },
  "blogs": { title: "Blogs", icon: ListAlt },
  "collage": { title: "Collage", icon: Collections },
  "collection-list": { title: "Collection List", icon: ViewColumn },
  "discount": { title: "Discount", icon: LocalOffer },
  "footer": { title: "Footer", icon: FooterIcon },
  "header": { title: "Header", icon: HeaderIcon },
  "forgot-password": { title: "Forgot Password", icon: Https },
  "forgot-password-confirm": { title: "Forgot Password Confirm", icon: Https },
  "login": { title: "Login", icon: PhonelinkLock },
  "product-list": { title: "Product List", icon: PhotoSizeSelectLarge },
  "product-slider": { title: "Product Slider", icon: BurstMode },
  "register": { title: "Register", icon: HowToReg },
  "reset-password": { title: "Reset Password", icon: Lock },
  "reset-password-confirm": { title: "Reset Password Cconfirm", icon: PhonelinkLock },
  "single-blog": { title: "Single Blog", icon: PhonelinkLock },
  "single-product": { title: "Single Product", icon: PhotoSizeSelectLarge },
  "featured-product": { title: "Featured Product", icon: PhotoSizeSelectLarge },
  "featured-banner": { title: "Featured Banner", icon: Photo },
  "featured-collection": { title: "Featured Collection", icon: ViewColumn },
  "slider": { title: "Slider", icon: BurstMode },
  "breadcrumb-navigation": { title: "Breadcrumb Navigation", icon: WebAsset },
  "rich-text": { title: "Rich Text", icon: SubjectOutlined },
  "image-text": { title: "Image with text", icon: ChromeReaderModeOutlined },

  //Blocks
  "blog": { title: "Blog", icon: WebAsset },
  "cart-drawer": { title: "Cart Drawer", icon: WebAsset },
  "collection": { title: "Collection", icon: WebAsset },
  "menu": { title: "Menu", icon: WebAsset },
  "product": { title: "Product", icon: WebAsset },
  "quick-view": { title: "Quick View", icon: WebAsset },
  "video": { title: "Video", icon: Videocam },
  "image": { title: "Image", icon: Photo },
  "slide": { title: "Slide", icon: ViewCarousel },
  "button": { title: "Button", icon: EditAttributes },
  "heading": { title: "Heading", icon: Notes },
  "list": { title: "List", icon: List },
  "discount-block": { title: "Discount Block", icon: LocalOffer },

}

export default function EditorSidebar({ page, frameRef, isOpenSidebar, onCloseSidebar, themeId, onUpdate }) {
  //console.log(data);
  const classes = useStyles();
  const user = useSelector(state => state.auth.profilePayload);
  const editorSchemaData = useSelector(state => state.storepage.editorSchemaData);
  const settingsSchema = useSelector(state => state.storepage.settingsSchema);
  const settingsData = useSelector(state => state.storepage.settingsData);
  const editorData = useSelector(state => state.storepage.pageEditorData);
  const headerData = useSelector(state => state.storepage.pageHeaderData);
  const footerData = useSelector(state => state.storepage.pageFooterData);
  const isLoading = useSelector(state => state.loader.loadingPayload);
  const authToken = localStorage.getItem('accessToken');

  const [loading, setLoading] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [action, setAction] = useState('section');
  const [showSchema, setShowSchema] = useState(false);
  const [schema, setSchema] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorMenu, setAnchorMenu] = useState([]);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const initValue = schema?.data?.settings;
  // schema?.settings?.map((item) => {
  //   try {
  //     return initValue[item.id] = data[item.id] || item?.default || "";
  //   }
  //   catch {
  //     return initValue[item.id] = item?.default || "";
  //   }
  // });

  useEffect(() => {
    console.log('settingsSchema', settingsSchema);
  }, [settingsSchema])

  const updateEditor = () => {
    //console.log('page,,,,,', page);
    dispatch(
      setEditorPage(user?.domain, themeId, page?.page, editorData, authToken, (data) => {
        onUpdate();
      })
    );
  }

  const updateHeader = () => {
    dispatch(
      setEditorPage(user?.domain, themeId, "header", headerData, authToken, (data) => {
        onUpdate();
      })
    );
  }

  const updateFooter = () => {
    dispatch(
      setEditorPage(user?.domain, themeId, "footer", footerData, authToken, (data) => {
        onUpdate();
      })
    );
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: schema?.data?.settings,
    onSubmit: async (values) => {
      try {
        //Mark change
        setIsChange(true);
        //Update Formik Initial Value
        setSchema({ ...schema, data: { ...schema.data, settings: values } });

        if (schema.cat == "editor_block") {
          dispatch({
            type: 'EDIT_EDITOR_BLOCK',
            payload: {
              section: schema.sectionId,
              id: schema.blockId,
              data: values
            }
          });
        }
        else if (schema.cat == "editor_section") {
          dispatch({
            type: 'EDIT_EDITOR_SECTION',
            payload: {
              section: schema.sectionId,
              data: values
            }
          });
        }
        else if (schema.cat == "header_section") {
          dispatch({
            type: 'EDIT_HEADER_SECTION',
            payload: {
              section: schema.sectionId,
              data: values
            }
          });
        }
        else if (schema.cat == "footer_section") {
          dispatch({
            type: 'EDIT_FOOTER_SECTION',
            payload: {
              section: schema.sectionId,
              data: values
            }
          });
        }
        //update last selected section
        dispatch({
          type: 'SET_EDITOR_SECTION',
          payload: schema.sectionId
        });
      } catch (error) { }
    },
  });

  const formikSettings = useFormik({
    enableReinitialize: true,
    initialValues: settingsData?.current,
    onSubmit: async (values) => {
      try {
        //Mark change
        setIsChange(true);
        //Update Formik Initial Value
        console.log('action', values);
        const finalData = { current: values, presets: settingsData?.presets }
        dispatch(
          setSettingsData(user?.domain, themeId, finalData, authToken, (data) => {
            onUpdate();
          })
        );

      } catch (error) { }
    },
  });

  const deleteBlock = () => {
    if (schema.cat == "editor_block") {
      dispatch({
        type: 'DELETE_EDITOR_BLOCK',
        payload: {
          section: schema.sectionId,
          block: schema.blockId
        }
      });
    }
    else if (schema.cat == "editor_section") {
      dispatch({
        type: 'DELETE_EDITOR_SECTION',
        payload: {
          section: schema.sectionId
        }
      });
    }
    else if (schema.cat == "header_block") {
      dispatch({
        type: 'DELETE_EDITOR_BLOCK',
        payload: {
          section: schema.sectionId,
          block: schema.blockId
        }
      });

    }
    else if (schema.cat == "header_section") {
      dispatch({
        type: 'DELETE_EDITOR_BLOCK',
        payload: {
          section: schema.sectionId,
          block: schema.blockId
        }
      });

    }
    else if (schema.cat == "footer_block") {
      dispatch({
        type: 'DELETE_EDITOR_BLOCK',
        payload: {
          section: schema.sectionId,
          block: schema.blockId
        }
      });

    }
    else if (schema.cat == "footer_section") {
      dispatch({
        type: 'DELETE_EDITOR_BLOCK',
        payload: {
          section: schema.sectionId,
          block: schema.blockId
        }
      });

    }
    setShowSchema(false);
    setIsChange(true);
  }

  const {
    values,
    handleSubmit,
    setFieldValue,
    getFieldProps,
    handleChange
  } = formik;

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('update editor', isChange);
    if (isChange) {
      updateEditor();
      setIsChange(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorData]);

  useEffect(() => {
    if (isChange) {
      updateHeader();
      setIsChange(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerData]);

  useEffect(() => {
    if (isChange) {
      updateFooter();
      setIsChange(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [footerData]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePublish = () => {
    dispatch(
      publishTheme(user?.domain, themeId, authToken, (data) => {
      })
    );
  }

  const blockMenuClick = (section, item, parent) => {
    setAnchorEl(null);

    if (parent?.max_blocks > editorData.sections[section].block_order.length) {
      const id = uuid();
      let block = {
        settings: {},
        type: item.type
      }
      item.settings.map((sett) => {
        block.settings[sett.id] = "";
      });

      dispatch({
        type: 'ADD_EDITOR_BLOCK',
        payload: {
          section: section,
          id: id,
          data: block
        }
      });
    }
    else {
      alert('max length exceed');
    }
  };

  const sectionMenuClick = (item) => {
    setAnchorEl(null);

    const id = uuid();
    let section = {
      settings: {},
      type: item.tag,
      blocks: {},
      block_order: []
    }

    item.settings.map((sett) => {
      section.settings[sett.id] = sett?.default || "";
    });

    if (item?.presets) {
      item.presets.map((pre) => {
        const preId = uuid();
        const blockItem = item.blocks.find(x => x.type == pre.type);

        let block = {
          settings: {},
          type: blockItem.type
        }
        blockItem.settings.map((sett) => {
          block.settings[sett.id] = sett?.default || "";
        });

        section.blocks[preId] = block;
        section.block_order.push(preId);

      });
    }

    dispatch({
      type: 'ADD_EDITOR_SECTION',
      payload: {
        id: id,
        data: section
      }
    });

    setIsChange(true);
  };

  const renderContent = (
    <Scrollbar>
      <Box sx={{ p: 1, pb: 5, height: '100%' }}>
        <MHidden width="mdUp">
          <Box sx={{ px: 2, py: 3 }}>
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </MHidden>

        <Stack direction="row" spacing={2} style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
          <Stack direction="row" spacing={2} style={{ alignItems: 'center' }}>
            <Button
              variant="outlined"
              style={{
                paddingTop: "2px",
                paddingBottom: "2px",
                // minHeight: "42px",
                minWidth: "0px",
                height: "40px",
                borderRadius: "5px",

              }}
              onClick={() => { setShowSchema(false); }}
            >
              {<ArrowBack />}
            </Button>
            {/* <h5>{sectionStyle[schema?.data?.type]?.title}</h5> */}
            <h3>{schema?.title}</h3>
          </Stack>
          {values?.isVisible ?
            <Visibility fontSize="medium" title="Show" onClick={() => setFieldValue('isVisible', false)} /> :
            <VisibilityOff fontSize="medium" title="Hide" color='error' onClick={() => setFieldValue('isVisible', true)} />
          }
        </Stack>

        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <ThemeEditor getFieldProps={getFieldProps} setFieldValue={setFieldValue} value={schema} fieldsValue={values}></ThemeEditor>
            <Divider variant="middle" sx={{ marginTop: '20px' }} />
            <Grid container style={{ marginTop: 20 }} justifyContent={"center"}>
              <Grid item xs={6}>
                <LoadingButton
                  sx={{ pl: 2, pr: 2 }}
                  variant="outlined"
                  loading={isLoading}
                  onClick={deleteBlock}
                  startIcon={<Delete />}
                  color="error"
                >
                  Remove
                </LoadingButton>
              </Grid>
              <Grid item xs={6}>
                <LoadingButton
                  sx={{ pl: 2, pr: 2 }}
                  variant="contained"
                  loading={isLoading}
                  onClick={handleSubmit}
                  disabled={!formik.dirty}
                >
                  Update
                </LoadingButton>

              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Box>
    </Scrollbar>
  );

  const renderAction = (
    <Scrollbar>
      <Box sx={{ p: 1, pb: 5, height: '100%' }}>
        <MHidden width="mdUp">
          <Box sx={{ px: 2, py: 3 }}>
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </MHidden>

        <Stack direction="row" spacing={2} style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
          <Stack direction="row" spacing={2} style={{ alignItems: 'center' }}>
            <h3>Theme Settings</h3>
          </Stack>

        </Stack>

        <FormikProvider value={formikSettings}>
          <Form noValidate autoComplete="off" onSubmit={formikSettings.handleSubmit}>
            <SettingsEditor getFieldProps={formikSettings.getFieldProps} setFieldValue={formikSettings.setFieldValue} value={settingsSchema} fieldsValue={formikSettings.values}></SettingsEditor>
            <Divider variant="middle" sx={{ marginTop: '20px' }} />
            <LoadingButton
              sx={{ pl: 2, pr: 2 }}
              variant="contained"
              fullWidth
              loading={isLoading}
              onClick={formikSettings.handleSubmit}
              disabled={!formikSettings.dirty}
            >
              Update
            </LoadingButton>
          </Form>
        </FormikProvider>
      </Box>
    </Scrollbar>
  );

  const renderMenu = (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      // anchorOrigin={{
      //   vertical: 'bottom',
      //   horizontal: 'right',
      // }}
      // transformOrigin={{
      //   vertical: 'top',
      //   horizontal: 'right',
      // }}
      PaperProps={{
        style: {
          maxHeight: 400
        },
        elevation: 1,
        sx: {
          overflow: 'scroll-x',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
    >
      {
        anchorMenu.map((itm) => {
          return itm;
        })
      }
    </Menu>
  )

  const handleAction = (action) => {
    setAction(action);
    setShowSchema(false);
    postMessage('');
  }

  const postMessage = (id) => {
    frameRef?.contentWindow?.postMessage(`${id}`, `https://${user?.domain}`);
  }

  return (
    <RootStyle >
      <Drawer
        open
        variant="permanent"
        PaperProps={{
          sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' }
        }}
      >
        <Box sx={{ display: 'flex', minHeight: '100%', overflow: 'hidden', paddingTop: '80px' }}>
          <Box sx={{ width: '50px', borderRight: '1px solid rgba(145, 158, 171, 0.24)' }}>
            <Box className={[classes.actionButton, action == 'section' && classes.activeButton]}
              title="Sections"
              onClick={() => {
                handleAction('section');
              }}>
              <CalendarViewDay sx={{ verticalAlign: 'middle' }} />
            </Box>
            {settingsSchema &&
              <Box className={[classes.actionButton, action == 'settings' && classes.activeButton]}
                title="Theme Settings"
                onClick={() => {
                  handleAction('settings');
                }}>
                <Settings sx={{ verticalAlign: 'middle' }} />
              </Box>
            }
          </Box>
          {action == 'section' ?
            <Box sx={{ overflow: 'auto', flex: 1 }}>
              {!showSchema ?
                <Box sx={{ height: '100%' }}>
                  <TreeView
                    className={classes.root}
                    draggable={true}
                    //defaultExpanded={['14']}
                    //selected={['15']}
                    defaultCollapseIcon={<ArrowDropDownIcon />}
                    defaultExpandIcon={<ArrowRightIcon />}
                    defaultEndIcon={<div style={{ width: 24 }} />}
                  // onNodeSelect={(event, nodeIds)=>{
                  //   alert(nodeIds);
                  // }}
                  >
                    <Typography class={classes.header_title} className={classes.divider}>HEADER</Typography>
                    {/* <StyledTreeItem nodeId="1" labelText="Announcement bar" labelIcon={HeaderIcon} /> */}
                    <StyledTreeItem nodeId="11" labelText="Header" labelIcon={HeaderIcon}
                      labelInfo={<IconButton aria-label="delete" size="small"
                        onClick={() => {

                          console.log(headerData?.sections['header']);
                          setSchema({
                            cat: "header_section",
                            data: headerData?.sections['header'],
                            sectionId: 'header',
                            sectionType: 'header',
                            title: sectionStyle["header"]?.title
                          });
                          setShowSchema(true);
                          postMessage('header');
                        }}>

                        {headerData.sections &&
                          (headerData?.sections['header']?.settings?.isVisible ?
                            <Visibility fontSize="inherit" /> :
                            <VisibilityOff fontSize="inherit" />
                          )
                        }
                      </IconButton>}
                      onClick={() => {
                        postMessage('header');
                      }}
                      disabled={headerData.sections && (!headerData?.sections['header'].settings?.isVisible)}
                    />
                    {/* <StyledTreeItem nodeId="134" labelText="Add section" add labelIcon={AddIcon} /> */}
                    <Divider variant="middle" sx={{ marginTop: '16px' }} />
                    <Typography class={classes.header_title} className={classes.divider}>TEMPLATE</Typography>
                    {editorData?.order && editorData?.order?.map((sectionId) => {
                      return (
                        <StyledTreeItem
                          labelInfo={<IconButton aria-label="delete" size="small" title={`${editorData?.sections[sectionId].settings?.isVisible ? 'Visible' : 'Hide'}`}
                            onClick={() => {
                              //console.log('set schema', editorData?.sections[sectionId]);
                              setSchema({
                                cat: "editor_section",
                                data: editorData?.sections[sectionId],
                                sectionId: sectionId,
                                sectionType: editorData?.sections[sectionId].type,
                                title: sectionStyle[editorData?.sections[sectionId].type]?.title
                              });
                              setShowSchema(true);
                            }}>
                            {editorData?.sections[sectionId].settings?.isVisible ?
                              <Visibility fontSize="inherit" /> :
                              <VisibilityOff fontSize="inherit" />
                            }
                          </IconButton>}
                          onClick={() => {
                            postMessage(sectionId);
                          }}
                          disabled={!editorData?.sections[sectionId].settings?.isVisible}
                          nodeId={sectionId}
                          labelText={sectionStyle[editorData?.sections[sectionId].type]?.title}
                          labelIcon={sectionStyle[editorData?.sections[sectionId].type]?.icon} >
                          {/* Child */}
                          {
                            (editorData?.sections[sectionId]?.block_order && editorData?.sections[sectionId]?.block_order?.length > 0)
                            && editorData?.sections[sectionId]?.block_order.map((blockId) => {
                              return (<StyledTreeItem nodeId={blockId} labelText={sectionStyle[editorData?.sections[sectionId]?.blocks[blockId]?.type]?.title} labelIcon={sectionStyle[editorData?.sections[sectionId]?.blocks[blockId]?.type]?.icon} onClick={() => {
                                //console.log(data?.sections[section]?.blocks[block]);
                                setSchema({
                                  cat: "editor_block",
                                  data: editorData?.sections[sectionId]?.blocks[blockId],
                                  sectionId: sectionId,
                                  blockId: blockId,
                                  sectionType: editorData?.sections[sectionId].type,
                                  title: sectionStyle[editorData?.sections[sectionId]?.blocks[blockId]?.type]?.title
                                });
                                setShowSchema(true);
                                postMessage(blockId);
                              }} />)
                            })
                          }
                          {
                            (editorSchemaData[editorData?.sections[sectionId].type]?.max_blocks && (editorData?.sections[sectionId]?.block_order?.length < editorSchemaData[editorData?.sections[sectionId].type]?.max_blocks))
                            && <StyledTreeItem nodeId={sectionId + "_add"} labelText="Add block" add labelIcon={AddIcon}
                              onClick={(event) => {
                                setAnchorEl(event.currentTarget);

                                const menus = [];
                                editorSchemaData[editorData?.sections[sectionId].type]?.blocks?.map((itm) => {
                                  menus.push(<MenuItem onClick={() => blockMenuClick(sectionId, itm, editorSchemaData[editorData?.sections[sectionId].type])}>{itm?.name}</MenuItem>);
                                })
                                setAnchorMenu(menus);
                              }}
                              aria-controls={open ? 'basic-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? 'true' : undefined}
                            />
                          }
                        </StyledTreeItem>
                      )
                    })}

                    <StyledTreeItem nodeId="135" labelText="Add section" add labelIcon={AddIcon}
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);

                        const menus = [];
                        const sectionKeys = Object.keys(editorSchemaData);
                        sectionKeys?.map((itm) => {
                          menus.push(<MenuItem onClick={() => sectionMenuClick(editorSchemaData[itm])}>{editorSchemaData[itm]?.name}</MenuItem>);
                        })
                        setAnchorMenu(menus);
                      }}
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                    />
                    <Divider variant="middle" sx={{ marginTop: '16px' }} />
                    <Typography class={classes.header_title} className={classes.divider}>FOOTER</Typography>
                    {/* <StyledTreeItem nodeId="121" labelText="E-Mail Signip" labelIcon={MailIcon} /> */}
                    <StyledTreeItem nodeId="132" labelText="Footer" labelIcon={FooterIcon}
                      labelInfo={<IconButton aria-label="delete" size="small"
                        onClick={() => {
                          console.log(editorData?.sections['footer']);
                          setSchema({
                            cat: "footer_section",
                            data: footerData?.sections['footer'],
                            sectionId: 'footer',
                            sectionType: 'footer',
                            title: sectionStyle['footer']?.title
                          });
                          setShowSchema(true);
                          postMessage('footer');
                        }}>
                        {footerData.sections &&
                          (footerData?.sections['footer']?.settings?.isVisible ?
                            <Visibility fontSize="inherit" /> :
                            <VisibilityOff fontSize="inherit" />
                          )
                        }
                      </IconButton>}
                      onClick={() => {
                        postMessage('footer');
                      }}
                      disabled={footerData.sections && (!footerData?.sections['footer'].settings?.isVisible)}
                    />
                    {/* <StyledTreeItem nodeId="133" labelText="Add section" add labelIcon={AddIcon} /> */}
                  </TreeView>
                  <Divider variant="middle" sx={{ marginTop: '16px' }} />
                  <Stack fullWidth style={{ padding: 10 }}>
                    <LoadingButton
                      sx={{ pl: 2, pr: 2 }}
                      variant="contained"
                      loading={isLoading}
                      onClick={() => handlePublish()}>
                      Publish
                    </LoadingButton>
                  </Stack>
                </Box>
                :
                <>{renderContent}</>
              }
              {renderMenu}
            </Box>
            :
            <>{renderAction}</>
          }
        </Box>
      </Drawer>
    </RootStyle >
  );
}
