import React from "react";
import PropTypes from "prop-types";
import { Typography, Divider } from "@material-ui/core";

CancelFulfillTimeline.propTypes = {
  canFul: PropTypes.object,
};

export default function CancelFulfillTimeline(props) {
  const { canFul } = props;
  return (
    <>
      <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
        Items
      </Typography>
      {canFul?.items?.map((value) => {
        return (
          <>
            <Typography variant="body2">
              {value?.qty} x{" "}
              {value?.variant?.title
                ? value?.product?.title +
                  " " +
                  "/" +
                  " " +
                  value?.variant?.title
                : value?.product?.title}
            </Typography>
            <Divider sx={{ my: 1 }} />
          </>
        );
      })}
      <Typography
        style={{ fontWeight: "bold", fontSize: "12px", marginTop: 5 }}
      >
        Fulfillment location
      </Typography>
      <Typography variant="body2">
        {canFul?.loc?.address},{canFul?.loc?.city},{canFul?.loc?.province},
        {canFul?.loc?.country}
      </Typography>
    </>
  );
}
