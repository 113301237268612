import React, { useState } from "react";
import PropTypes from "prop-types";
import { Stack } from "@material-ui/core";
import {
  Timeline,
  TimelineContent,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  timelineItemClasses,
} from "@material-ui/lab";
import { ArrowDropDown, FiberManualRecord } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { fDate, fTime } from "../../../../utils/formatTime";
import TimeLineComment from "../TimeLineComment";

GiftCardTimeline.propTypes = {
  giftCard: PropTypes.object,
  check: PropTypes.bool,
};

export default function GiftCardTimeline(props) {
  const { giftCard, check } = props;
  const store = useSelector((state) => state.auth.profilePayload);

  const [accordion, setAccordion] = useState(false);
  return (
    <>
      {giftCard?.msg ? (
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          <TimelineItem>
            <TimelineSeparator>
              <FiberManualRecord style={{ color: "green" }} />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <>
                <Stack direction={"row"} justifyContent="space-between">
                  <Stack
                    direction={"row"}
                    style={{ cursor: "pointer" }}
                    onClick={() => setAccordion(!accordion)}
                  >
                    <p style={{ marginTop: -5, fontSize: "13px" }}>
                      {giftCard.msg}{" "}
                      {giftCard?.type =="create with expiration" &&(
                        <>
                        {giftCard?.exp_date == "No Expiry" ? giftCard?.exp_date : fDate(giftCard?.exp_date)}
                        </>
                      )}
                      {giftCard?.type =="update exp_date" &&(
                        <>
                        {giftCard?.old_exp == "No Expiry" ? giftCard?.old_exp : fDate(giftCard?.old_exp)} to {giftCard?.exp_date == "No Expiry" ? giftCard?.exp_date: fDate(giftCard?.exp_date)}
                        </>
                      )}
                    </p>{" "}
                    {giftCard?.tlData && (
                      <ArrowDropDown
                        style={{
                          cursor: "pointer",
                          marginTop: "-6px",
                          marginRight: "15px",
                        }}
                      />
                    )}
                  </Stack>

                  <p
                    style={{
                      fontSize: "14px",
                      color: "grey",
                      marginTop: -5
                    }}
                  >
                    {" "}
                    {fTime(giftCard?.cdate)}
                  </p>
                </Stack>
              </>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      ) : (
        <>{check && <TimeLineComment comment={giftCard} />}</>
      )}
    </>
  );
}
