import { Alert, Button } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { PATH_DASHBOARD } from "src/routes/paths";

import moment from "moment";
import {Link as RouterLink } from "react-router-dom";

export default function PlanAlert() {
  const store = useSelector((state) => state.auth.profilePayload);
  const newDate = moment().format("MM/DD/YYYY");
  const expiry = moment(store?.plan?.exp?.end).format("MM/DD/YYYY");
  return (
    <>
      {newDate > expiry && (
        <Alert
          sx={{ mb: 3 }}
          severity="error"
          action={
            <Button
              color="inherit"
              size="small"
              component={RouterLink}
              to={PATH_DASHBOARD.setting.upgradePlan}
            >
              Go to Plan
            </Button>
          }
        >
          Your Plan is Expired!
        </Alert>
      )}
    </>
  );
}
