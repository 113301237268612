import { experimentalStyled as styled } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

const ToolbarStyle = styled('div')(({ theme }) => {

  return {
    '& .ql-picker, .ql-picker-options, .ql-picker-item, .ql-picker-label, button': {
      '&:focus': { outline: 'none' }
    },
    '& .ql-toolbar.ql-snow': {
      border: 'none',
      borderBottom: `solid 1px ${theme.palette.grey[500_32]}`,
      '& .ql-formats': {
        '&:not(:last-child)': {
          marginRight: theme.spacing(1)
        }
      },
      // Color & Background
      '&.ql-color, &.ql-background': {
        '& .ql-picker-options': { padding: 8 },
        '& .ql-picker-item': {
          margin: 3,
          borderRadius: 4,
          '&.ql-selected': { border: 'solid 1px black' }
        }
      },
      // Font, Size, Header
      '&.ql-font, &.ql-size, &.ql-header': {
        '& .ql-picker-options': {
          padding: theme.spacing(1, 0)
        },
        '& .ql-picker-item': {
          padding: theme.spacing(0.5, 1.5)
        }
      }
    }
  };
});

export default ToolbarStyle;
