const initialState = { addressPayload: []}

export default function (state = initialState, action) {
   switch (action.type) {
    case 'GET_ADDRESSES':
         {
            return {
               ...state,
               addressPayload: action.payload
            };
         }

         case 'GET_COUNTRY':
         {
            return {
               ...state,
               countryPayload: action.payload
            };
         }

         case 'GET_SINGLE_ADDRESS':
            {
               return {
                  ...state,
                  singleAddressPayload: action.payload
               };
            }

            case 'INSERT_ADDRESS':
               {
                  return {
                     ...state,
                     insertAddressPayload: action.payload
                  };
               }

            case 'UPDATE_ADDRESS':
            {
               return {
                  ...state,
                  updateAddressPayload: action.payload
               };
            }

            case 'SET_DEFAULT_ADDRESS':
            {
               return {
                  ...state,
                  defaultAddressPayload: action.payload
               };
            }

            case 'UPDATE_CUSTOMER_NOTE':
            {
               return {
                  ...state,
                  updateCustomerNotePayload: action.payload
               };
            }

         default:
         return state;
   }}