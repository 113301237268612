import axios from "axios";
import { RES_BASE_URL } from '../Provider/api';
import { toast } from 'react-toastify';
var FormData = require('form-data');

// Upload Image
export const uploadImage = (imageData, images, setFieldValue) => {
    return async function (dispatch) {

        dispatch({
            type: 'IS_IMAGE_LOADING',
            payload: true
        })

        console.log(imageData);

        const authToken = await localStorage.getItem('accessToken');
        const formData = new FormData()
        formData.append("file", imageData[0]);

        axios({
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + authToken
            },
            url: RES_BASE_URL + '/upload/media',
            data: formData
        })
            .then(function (response) {
                // console.log('Image Upload Action=================', response.data);
                dispatch({
                    type: 'IS_IMAGE_LOADING',
                    payload: false
                })
                if (response.data.status === true) {
                    setFieldValue('images', [...images, response.data.result])
                    toast.success(response.data.msg)
                    // dispatch({
                    //     type: 'IMAGE_ADD',
                    //     payload: response.data.result
                    // })
                } else {
                    toast.warn(response.data.msg)
                }
            })
            .catch(function (error) {
                dispatch({
                    type: 'IS_IMAGE_LOADING',
                    payload: false
                })
                toast.error(error?.response?.data?.msg)
                // console.log(error.response);
            });
    }
}

// Upload Image
export const uploadImageDirect = (imageData, fileName, successCallback, errorCallback) => {
    return async function (dispatch) {

        // dispatch({
        //     type: 'IS_IMAGE_LOADING',
        //     payload: true
        // })

        const authToken = await localStorage.getItem('accessToken');
        const formData = new FormData()
        formData.append("file", imageData);
        formData.append("title", fileName);

        axios({
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + authToken,
                "Content-Type": 'multipart/form-data',
            },
            url: RES_BASE_URL + '/upload/media',
            data: formData,
            formData
        })
            .then(function (response) {
                // console.log('Image Upload Action=================', response.data);
                dispatch({
                    type: 'IS_IMAGE_LOADING',
                    payload: false
                })
                if (response.data.status === true) {
                    //setFieldValue('images', [...images, response.data.result])
                    toast.success(response.data.msg);
                    successCallback(response.data.result);
                    // dispatch({
                    //     type: 'IMAGE_ADD',
                    //     payload: response.data.result
                    // })
                } else {
                    toast.warn(response.data.msg);
                    errorCallback(response.data.msg);
                }
            })
            .catch(function (error) {
                // dispatch({
                //     type: 'IS_IMAGE_LOADING',
                //     payload: false
                // })
                toast.error(error?.response?.data?.msg)
                errorCallback(error?.response?.data?.msg);
                // console.log(error.response);
            });
    }
}

// Delete Image
export function deleteImage(_id, successCallback = () => { }, errorCallback = () => { }) {
    return async function (dispatch) {
        dispatch({
            type: 'IS_IMAGE_LOADING',
            payload: true
        })
        const authToken = await localStorage.getItem('accessToken');

        axios({
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + authToken
            },
            url: RES_BASE_URL + '/delete/resource',
            data: { id: _id }
        })
            .then(function (response) {
                // console.log('Image Detete Action=================', response.data);
                dispatch({
                    type: 'IS_IMAGE_LOADING',
                    payload: false
                })
                if (response.data.status === true) {

                    // let clone = JSON.parse(JSON.stringify(images));
                    // const index = clone.findIndex((obj) => obj._id === _id);
                    // if (index !== -1) clone.splice(index, 1);
                    // setFieldValue('images', clone)
                    successCallback(response.data?.data);
                    toast.success(response.data.msg)
                } else {
                    errorCallback();
                    toast.warn(response.data.msg)
                }
            })
            .catch(function (error) {
                errorCallback();
                dispatch({
                    type: 'IS_IMAGE_LOADING',
                    payload: false
                })
                console.log(error);
                toast.error(error?.response?.data?.msg)
            });
    }
}
