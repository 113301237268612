// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DOCS = '/docs';
const ROOTS_EDITOR = '/editor';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_PRODUCT = '/product';
const ROOTS_PAGES = '/custompage';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
  pages: '/pages'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    viewReports: path(ROOTS_DASHBOARD, '/viewReports'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
  },
  customers: {
    root: path(ROOTS_DASHBOARD),   
    list: path(ROOTS_DASHBOARD, '/customer/list'),
    new: path(ROOTS_DASHBOARD, '/customer/new'),
    edit: path(ROOTS_DASHBOARD, '/customer/edit'),
    customerOrder: path(ROOTS_DASHBOARD, '/customer/order'),
  },
  report: {  
    reports: path(ROOTS_DASHBOARD, '/reports'),
    sales_over_time: path(ROOTS_DASHBOARD, '/report/sales_over_time'),
    average_order_value: path(ROOTS_DASHBOARD, '/report/average_order_value'),
    sales_by_product: path(ROOTS_DASHBOARD, '/report/sales_by_product'),
    sales_by_product_vendor: path(ROOTS_DASHBOARD, '/report/sales_by_product_vendor'),
    sales_by_product_variant_sku: path(ROOTS_DASHBOARD, '/report/sales_by_product_variant_sku'),
    orders_over_time: path(ROOTS_DASHBOARD, '/report/orders_over_time'),
  },
  products: {
    list: path(ROOTS_DASHBOARD, '/product/list'),
    newProduct: path(ROOTS_DASHBOARD, '/product/new'),
    edit: path(ROOTS_DASHBOARD, '/product/edit'),
    inventory: path(ROOTS_DASHBOARD, '/product/inventory'),
    transfers: path(ROOTS_DASHBOARD, '/product/transfers'),
    createTransfer: path(ROOTS_DASHBOARD, '/product/createTransfer'),
    editTransfer: path(ROOTS_DASHBOARD, '/product/editTransfer'),
    recvInventory: path(ROOTS_DASHBOARD, '/product/editTransfer'),
    collections: path(ROOTS_DASHBOARD, '/collection/list'),
    newCollection: path(ROOTS_DASHBOARD, '/collection/new'),
    editCollection: path(ROOTS_DASHBOARD, '/collection/edit/:_id'),
    giftcard: path(ROOTS_DASHBOARD, '/giftcard/list'),
    giftCardList: path(ROOTS_DASHBOARD, '/product/giftcard'),
    giftcardSave: path(ROOTS_DASHBOARD, '/giftcard/new'),
    giftcardEdit: path(ROOTS_DASHBOARD, '/giftcard/new/giftcardEdit'),
    issue_gift_card: path(ROOTS_DASHBOARD, '/giftcard/issue_gift_card'),
    issuecard: path(ROOTS_DASHBOARD, '/giftcard/issuecard'),
    view: path(ROOTS_DASHBOARD, '/giftcard/view'),
  },
  onlinestore:{
    theme: path(ROOTS_DASHBOARD, '/onlinestore/theme'),
    blogpage: path(ROOTS_DASHBOARD, '/onlinestore/blogpage'),
    storepage: path(ROOTS_DASHBOARD, '/onlinestore/storepage'),
    manageblog: path(ROOTS_DASHBOARD, '/onlinestore/manageblog'),
    preference: path(ROOTS_DASHBOARD, '/onlinestore/preference'),
    addblog: path(ROOTS_DASHBOARD, '/onlinestore/blogpage/addblog'),
    newblog: path(ROOTS_DASHBOARD, '/onlinestore/manageblog/newblog'),
    editblog:path(ROOTS_DASHBOARD, '/onlinestore/blogpage/editblog'),
    editpage:path(ROOTS_DASHBOARD, '/onlinestore/storepage/editpage'),
    editManageBlog:path(ROOTS_DASHBOARD, '/onlinestore/manageblog/edit'),
    addpage: path(ROOTS_DASHBOARD, '/onlinestore/storepage/addpage'),
    navigation: path(ROOTS_DASHBOARD, '/onlinestore/navigation'),
    new: path(ROOTS_DASHBOARD, '/onlinestore/navigation/new'),
    edit: path(ROOTS_DASHBOARD, '/onlinestore/navigation/edit'),
  },
  
  location: {
    list: path(ROOTS_DASHBOARD, '/location'),
  },
  
  discount: {
    root: path(ROOTS_DASHBOARD, '/discount/'),
    create: path(ROOTS_DASHBOARD, '/discount/create'),
    view: path(ROOTS_DASHBOARD, '/discount/viewDiscount'),
  },
  setting: {
    root: path(ROOTS_DASHBOARD, '/setting'),
    user: path(ROOTS_DASHBOARD, '/setting/user'),
    details: path(ROOTS_DASHBOARD, '/setting/user/:id'),
    legal:path(ROOTS_DASHBOARD, '/setting/legal'),
    plan:path(ROOTS_DASHBOARD, '/setting/plan'),
    upgradePlan:path(ROOTS_DASHBOARD, '/setting/plan/upgradePlan'),
    checkout:path(ROOTS_DASHBOARD,'/setting/checkout'),
    storedetails:path(ROOTS_DASHBOARD,'/setting/storedetails'),
    billing:path(ROOTS_DASHBOARD,'/setting/billing'),
    currentbilling:path(ROOTS_DASHBOARD,'/setting/billing/currentbilling'),
    billinghistory:path(ROOTS_DASHBOARD,'/setting/billing/billinghistory'),
    location:path(ROOTS_DASHBOARD,'/setting/location'),
    addlocation:path(ROOTS_DASHBOARD,'/setting/location/new'),
    editlocation:path(ROOTS_DASHBOARD,'/setting/location/edit'),
    notification:path(ROOTS_DASHBOARD,'/setting/notification'),
    notificationTemplate:path(ROOTS_DASHBOARD,'/setting/notification'),
    editNotification:path(ROOTS_DASHBOARD,'/setting/notification'),
    orderconfirmation:path(ROOTS_DASHBOARD,'/setting/notification/orderconfirmation'),
    taxes:path(ROOTS_DASHBOARD,'/setting/taxes'),
    taxDetails:path(ROOTS_DASHBOARD,'/setting/taxes'),
    editvarient:path(ROOTS_DASHBOARD,'/setting/taxes/editvarient'),
    taxcollection:path(ROOTS_DASHBOARD,'/setting/taxes/taxcollection'),
    vendor:path(ROOTS_DASHBOARD,'/setting/vendor'),
    addVendor:path(ROOTS_DASHBOARD,'/setting/addVendor'),
    view:path(ROOTS_DASHBOARD,'/setting/vendor/view'),
    domains:path(ROOTS_DASHBOARD,'/setting/domains'),
    connect :path(ROOTS_DASHBOARD,'/setting/connect'),
    edit_domain :path(ROOTS_DASHBOARD,'/setting/edit_domain'),
    verify_domain :path(ROOTS_DASHBOARD,'/setting/verify_domain'),
    branding :path(ROOTS_DASHBOARD,'/setting/branding'),
    gift_cards :path(ROOTS_DASHBOARD,'/setting/gift_cards'),
    shipping :path(ROOTS_DASHBOARD,'/setting/shipping'),
    shippingProfile :path(ROOTS_DASHBOARD,'/setting/shipping/profiles/create'),
    editshippingProfile :path(ROOTS_DASHBOARD,'/setting/shipping/profiles'),
    packing_slip_template :path(ROOTS_DASHBOARD,'/setting/shipping/packing_slip_template'),
    local_delivery :path(ROOTS_DASHBOARD,'/setting/shipping/local_delivery'),
    manage_shipping :path(ROOTS_DASHBOARD,'/setting/shipping/profiles'),
    payment :path(ROOTS_DASHBOARD,'/setting/payments'),
    themePreference :path(ROOTS_DASHBOARD,'/onlinestore/theme/preference'),
  },
  newfile:{
    root: path(ROOTS_DASHBOARD, '/newfile'),
    list: path(ROOTS_DASHBOARD, '/newfile/list'),
  },
  order: {
    root: path(ROOTS_DASHBOARD),
    create: path(ROOTS_DASHBOARD, '/order/create'),
    draft: path(ROOTS_DASHBOARD, '/order/draft'),
    checkouts: path(ROOTS_DASHBOARD, '/order/checkouts'),
    checkout_view: path(ROOTS_DASHBOARD, '/order/checkout_view'),
    list: path(ROOTS_DASHBOARD, '/order/list'),
    view: path(ROOTS_DASHBOARD, '/order/view'),
    refund: path(ROOTS_DASHBOARD, '/order/view'),
    return: path(ROOTS_DASHBOARD, '/order/view'),
    returnToredund: path(ROOTS_DASHBOARD, '/order/view'),
    fulfill: path(ROOTS_DASHBOARD, '/order/view'),
    update: path(ROOTS_DASHBOARD, '/order/view'),
  },
  editor: {
    root: path(ROOTS_EDITOR),
    landing: path(ROOTS_EDITOR, '/landing')
  }
};

export const PATH_DOCS = {
  root: ROOTS_DOCS,
  introduction: path(ROOTS_DOCS, '/introduction'),
  quickstart: path(ROOTS_DOCS, '/quick-start'),
  package: path(ROOTS_DOCS, '/package'),

  // Theme UI
  color: path(ROOTS_DOCS, '/color'),
  typography: path(ROOTS_DOCS, '/typography'),
  icon: path(ROOTS_DOCS, '/icon'),
  shadows: path(ROOTS_DOCS, '/shadows'),
  components: path(ROOTS_DOCS, '/components'),
  settings: path(ROOTS_DOCS, '/settings'),
  tips: path(ROOTS_DOCS, '/tips'),

  // Development
  routing: path(ROOTS_DOCS, '/routing'),
  environmentVariables: path(ROOTS_DOCS, '/environment-variables'),
  stateManagement: path(ROOTS_DOCS, '/state-management'),
  apiCalls: path(ROOTS_DOCS, '/api-calls'),
  analytics: path(ROOTS_DOCS, '/analytics'),
  authentication: path(ROOTS_DOCS, '/authentication'),
  multiLanguage: path(ROOTS_DOCS, '/multi-language'),
  lazyload: path(ROOTS_DOCS, '/lazyload-image'),
  formHelper: path(ROOTS_DOCS, '/form-helper'),

  // Changelog
  support: path(ROOTS_DOCS, '/support'),
  changelog: path(ROOTS_DOCS, '/changelog')
};

export const PATH_EDITOR = {
  root: ROOTS_EDITOR,
  landing: path(ROOTS_EDITOR, '/landing'),
};

export const PATH_PRODUCT = {
  root: ROOTS_PRODUCT,
  newProduct: path(ROOTS_PRODUCT, '/new')
};

export const PATH_PAGES = {
  root: ROOTS_PAGES,
  newPage: path(ROOTS_PAGES, '/new'),
  all: path(ROOTS_PAGES, '/all')
};
