const initialState = {
  timelinePayload: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "GET_TIMELINE": {
      return {
        ...state,
        timelinePayload: action.payload,
      };
    }

    // case "INSERT_COMMENT": {
    //   const data = action.payload;
    //   console.log("action.payload", action.payload);
    //   console.log("timelinePayload", state.timelinePayload);
    //   const findTimeLIne = state.timelinePayload.map((o) =>{
        
    //     return{
    //       ...o,
    //      item:  o.items [
    //         ...o.items,
    //           action.payload,
    //       ]
    //       }
    //     });
    //   console.log("findTimeLIne", findTimeLIne);
    //   return {
    //     ...state,
    //     timelinePayload: [
    //       ...timelinePayload,
    //       findTimeLIne,
    //     ]
    //     }
    //   };
    

    // case "UPDATE_COMMENT": {
    //   console.log("action.payload",action.payload);
    //   return {
    //     ...state,
    //     timelinePayload: {
    //       ...state.timelinePayload,
    //       comment: action.payload.comment,
    //     },
    //   };
    // }
    default:
      return state;
  }
}
