

const initialState = {
  OverridesPayload: [],
  TaxesPayload: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "GET_ALL_TAXES": {
      return {
        ...state,
        TaxesPayload: action.payload,
      };
    }

    case "CREATE_TAX": {
      const data = {
        country: action.payload,
        regions: 0
      };
      return {
        ...state,
        TaxesPayload: [...state.TaxesPayload, data],
      };
    }

    case "GETALL_TAX_OVERRIDE": {
      return {
        ...state,
        OverridesPayload: action.payload,
      };
    }

    case "GET_TAX": {
      return {
        ...state,
        taxPayload: action.payload,
      };
    }


    case "INSERT_TAX_OVERRIDE": {
      return {
        ...state,
        OverridesPayload: [...state.OverridesPayload, action.payload],
      };
    }

    case "UPDATE_TAX_OVERRIDE": {
         
      const map = state.OverridesPayload.map((val) => {
        if (val?._id == action.payload.id) {
          return {
            ...val,
            type: action.payload.type,
            collection: action.payload.collection,
          };
        } else {
          return val;
        }
      });

      return {
        ...state,
        OverridesPayload: map             
      };
    }

    case "DELETE_TAX_OVERRIDE": {
      return {
        ...state,
        OverridesPayload: [
          ...state.OverridesPayload?.filter((x) => x._id != action.payload),
        ],
      };
    }

    case "INSERT_TAX_OVERRIDE_REGION": {
      const setObj = {
        locId: action.payload.locId,
        tax: action.payload.tax,
        centralTax: action.payload.centralTax,
        state: action.payload.state,
      };

      const map = state.OverridesPayload.map((val) => {
        if (val?._id == action.payload.id) {
          return {
            ...val,
            overrides: [...val.overrides, setObj],
          };
        } else {
          return val;
        }
      });
      return {
        ...state,
        OverridesPayload: map,
      };
      
    }

    case "DELETE_TAX_OVERRIDE_REGION": {
      const map = state.OverridesPayload.map((val) => {
        if (val?._id == action.payload.id) {
          const filter = val.overrides.filter((op) => op.locId !== action.payload.locId)
          return {
            ...val,
            overrides : filter
          }
        } else {
          return val;
        }
      });
      return {
        ...state,
        OverridesPayload: map,
      };
      
    }

    default:
      return state;
  }
}
