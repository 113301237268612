const initialState = {
  allDiscountPayload: [],
  exportDiscountPayload: []
};

export default function (state = initialState, action) {
  switch (action.type) {

    case "GET_ALL_DISCOUNT": {
      return {
        ...state,
        allDiscountPayload: action.payload,
      };
    }

    case "GET_SINGLE_DISCOUNT": {
      return {
        ...state,
        discountPayload: action.payload,
      };
    }
    
    case "DEACTIVATE_DISCOUNT": {
      return {
        ...state,
        discountPayload: {
          ...state.discountPayload,
          status: "Expire",
        },
      };
    }

    case "ACTIVATE_DISCOUNT": {
      return {
        ...state,
        discountPayload: {
          ...state.discountPayload,
          status: "Active",
        },
      };
    }

    case "EXPORT_DISCOUNT": {
      return {
        ...state,
        exportDiscountPayload: action.payload,
      };
    }
    default:
      return state;
  }
}
