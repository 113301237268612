import { toast } from "react-toastify";
import ApiExt from "../Provider/ApiExt";

// Get Product
export function getAllDomains(values) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    ApiExt.post("/api/settings/getall-domains", values)
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GETALL_DOMAIN",
            payload: response?.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

export function getDomain(id) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    ApiExt.post("/api/settings/get-domain", { id: id })
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status == true) {
          dispatch({
            type: "GET_DOMAIN",
            payload: response?.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

export function createDomain(values, callback, setLoading) {
  return async function (dispatch) {
    setLoading(true);

    ApiExt.post("/api/settings/create-domain", values)
      .then(function (response) {
        setLoading(false);
        if (response.data.status) {
          // dispatch({
          //   type: "UPDATE_PLAN",
          //   payload: planId,
          // });
          toast.success(response?.data?.msg);
          callback(response.data.id);
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

export function updateDomain(id, values, callback, setLoading) {
  return async function (dispatch) {
    setLoading(true);

    ApiExt.post("/api/settings/update-domain", {
      id: id,
      domain: values.domain,
    })
      .then(function (response) {
        setLoading(false);
        if (response.data.status) {
          callback();
          toast.success(response?.data?.msg);
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

export function VerifyDomain(values, callback, setLoading) {
  return async function (dispatch) {
    setLoading(true);
    ApiExt.post("/api/settings/verify-domain", values)
      .then(function (response) {
        setLoading(false);
        if (response.data.status) {
          toast.success(response?.data?.msg);
          callback(response.data.data);
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error(error);
      });
  };
}

export function activationDomain(id, setLoading) {
  return async function (dispatch) {
    setLoading(true);
    ApiExt.post("/api/settings/domain-activation", { id: id })
      .then(function (response) {
        setLoading(false);
        if (response.data.status) {
          toast.success(response?.data?.msg);
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error(error);
      });
  };
}

export function domainDelete(id, callback, setLoading) {
  return async function (dispatch) {
    setLoading(true);
    ApiExt.post("/api/settings/domain-delete", { id: id })
      .then(function (response) {
        setLoading(false);
        callback(response.data);
        if (response.data.status) {
          dispatch({
            type: "DELETE_DOMAIN",
            payload: id,
          });
          toast.success(response?.data.msg);
        } else {
          callback(response.data);
          toast.warn(response?.data?.msg.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error(error);
      });
  };
}
export function domainConnect(value, setConnectDomain) {
  return async function (dispatch) {
    ApiExt.post("/api/settings/domain-connect", value)
      .then(function (response) {
        if (response.data.status) {
          dispatch({
            type: "CONNECTED_DOMAIN",
            payload: value.id,
          });
          dispatch({
            type: "CHNAGE_STORE_DOMAIN",
            payload: value.domain,
          });
          toast.success(response?.data.msg);
          setConnectDomain(false);
        } else {
          toast.warn(response?.data?.msg.msg);
        }
      })
      .catch(function (error) {
        toast.error(error);
      });
  };
}
