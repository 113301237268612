// routes
import { PATH_DASHBOARD, PATH_PAGES, PATH_PRODUCT } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  //https://iconsvg.xyz/
  // blog: getIcon('ic_blog'),
  // calendar: getIcon('ic_calendar'),
  dashboard: getIcon('ic_dashboard'),
  order:getIcon('ic_calendar'),
  product: getIcon('ic_cart'),
  customer: getIcon('ic_kanban'),
  analytics: getIcon('ic_analytics'),
  marketting: getIcon('ic_ecommerce'),
  discounts: getIcon('ic_mail'),
  setting: getIcon('ic_user'),
  onlinestore: getIcon('ic_chat'),
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'Home',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },
      
      {
        title: 'Orders',
        icon: ICONS.order,
        children: [
          { title: 'All Orders', path: PATH_DASHBOARD.order.list },
          { title: 'Draft', path: PATH_DASHBOARD.order.draft },
          { title: 'Abandoned Checkouts', path: PATH_DASHBOARD.order.checkouts },
        ]
      },
      {
        title: 'Products',
        icon: ICONS.product,
        children: [
          { title: 'All Product', path: PATH_DASHBOARD.products.list },
          { title: 'Inventory', path: PATH_DASHBOARD.products.inventory },
          { title: 'Transfers', path: PATH_DASHBOARD.products.transfers },
          { title: 'Collections', path: PATH_DASHBOARD.products.collections },
          { title: 'Gift Cards', path: PATH_DASHBOARD.products.giftcard }
        ]
      },

      

      { title: 'Customers', path: PATH_DASHBOARD.customers.list, icon: ICONS.customer },
      { title: 'Locations', path: PATH_DASHBOARD.location.list, icon: ICONS.onlinestore },
      {
        title: 'Analytics',
        icon: ICONS.analytics,
        children: [
          { title: 'Analytics', path: PATH_DASHBOARD.general.analytics },
          { title: 'Reports', path: PATH_DASHBOARD.report.reports }
        ]
      },
      
      // { title: 'Marketting', path: PATH_DASHBOARD.general.analytics, icon: ICONS.marketting },
      { title: 'Discounts', path: PATH_DASHBOARD.discount.root, icon: ICONS.discounts },

      // { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      // { title: 'live analytics', path: PATH_DASHBOARD.general.liveanalytics, icon: ICONS.analytics },
      // { title: 'report analytics', path: PATH_DASHBOARD.general.reportanalytics, icon: ICONS.analytics },
      // { title: 'domains', path: PATH_DASHBOARD.domains.list, icon: ICONS.dashboard }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
      // MANAGEMENT : Products
     

      // // MANAGEMENT : USER
      // {
      //   title: 'user',
      //   path: PATH_DASHBOARD.user.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //     { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //     { title: 'list', path: PATH_DASHBOARD.user.list },
      //     { title: 'create', path: PATH_DASHBOARD.user.newUser },
      //     { title: 'edit', path: PATH_DASHBOARD.user.editById },
      //     { title: 'account', path: PATH_DASHBOARD.user.account }
      //   ]
      // },
      // // MANAGEMENT : CUSTOMER
      // {
      //   title: 'customer',
      //   path: PATH_DASHBOARD.customer.new,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'create', path: PATH_DASHBOARD.customer.new }
      //   ]
      // },

      // // MANAGEMENT : E-COMMERCE
      // {
      //   title: 'e-commerce',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
      //     { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
      //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
      //     { title: 'create', path: PATH_DASHBOARD.root + PATH_PRODUCT.newProduct },
      //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
      //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //     { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice }
      //   ]
      // },

      // // MANAGEMENT : BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'post', path: PATH_DASHBOARD.blog.postById },
      //     { title: 'new post', path: PATH_DASHBOARD.blog.newPost }
      //   ]
      // },

      // // MANAGEMENT : BLOG
      // {
      //   title: 'Page',
      //   path: PATH_PAGES.newPage,
      //   icon: ICONS.ecommerce,
      //   children: [
      //     { title: 'new page', path: PATH_DASHBOARD.root + PATH_PAGES.newPage },
      //     { title: 'all pages', path: PATH_DASHBOARD.root + PATH_PAGES.all }
      //   ]
      // },
      // // MANAGEMENT : GIFTCARD
      // {
      //   title: 'Gift Card',
      //   path: PATH_DASHBOARD.giftcard.root,
      //   icon: ICONS.ecommerce,
      //   children: [
      //     { title: 'gift cards', path: PATH_DASHBOARD.giftcard.root },
      //     { title: 'issue gift card', path: PATH_DASHBOARD.giftcard.issuecard }
      //   ]
      // },
      // // MANAGEMENT : Discount
      // {
      //   title: 'Discount',
      //   path: PATH_DASHBOARD.discount.root,
      //   icon: ICONS.ecommerce,
      //   children: [
      //     { title: 'Discount', path: PATH_DASHBOARD.discount.root },
      //     { title: 'Create Discount', path: PATH_DASHBOARD.discount.create }
      //   ]
      // },
      // // Setting
      // {
      //   title: 'Manage Setting',
      //   path: PATH_DASHBOARD.setting.root,
      //   icon: ICONS.ecommerce,
      //   children: [
      //     { title: 'Setting', path: PATH_DASHBOARD.setting.root }
      //   ]
      // },
      // // Inventory
      // {
      //   title: 'Inventory',
      //   path: PATH_DASHBOARD.inventory.root,
      //   icon: ICONS.ecommerce,
      //   children: [
      //     { title: 'Inventory', path: PATH_DASHBOARD.inventory.root }
      //   ]
      // },
      // // Inventory
      // {
      //   title: 'Order',
      //   path: PATH_DASHBOARD.order.root,
      //   icon: ICONS.ecommerce,
      //   children: [
      //     { title: 'Create Order', path: PATH_DASHBOARD.order.create }
      //   ]
      // }
  //   ]
  // },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      {
        title: "Online Store",
        // path: PATH_DASHBOARD.onlinestore.root,
        icon: ICONS.onlinestore,
        children: [
          { title: "Themes", path: PATH_DASHBOARD.onlinestore.theme},
          { title: "Blog", path: PATH_DASHBOARD.onlinestore.blogpage },
          { title: "Pages", path: PATH_DASHBOARD.onlinestore.storepage },
          { title: "Preferences", path: PATH_DASHBOARD.onlinestore.preference },
          { title: "Navigation", path: PATH_DASHBOARD.onlinestore.navigation },
        ],
      },
      { title: 'Setting', path:  PATH_DASHBOARD.setting.root , icon: ICONS.setting },
    ]
  }
];

export default sidebarConfig;
