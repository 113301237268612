import React, { useState } from "react";
import PropTypes from "prop-types";
import { Stack } from "@material-ui/core";
import {
  Timeline,
  TimelineContent,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  timelineItemClasses,
} from "@material-ui/lab";
import { ArrowDropDown, FiberManualRecord } from "@material-ui/icons";
import { fTime } from "../../../../utils/formatTime";
import TimeLineComment from "../TimeLineComment";
import TransferItemsTimeline from "./TransferItemsTimeline";
import RcvInventoryTimeline from "./RcvInventoryTimeline";

TransferTimeline.propTypes = {
  transfer: PropTypes.object,
  check: PropTypes.bool,
  singleTransferData: PropTypes.object,
};

export default function TransferTimeline(props) {
  const { transfer, check, singleTransferData } = props;

  const [accordion, setAccordion] = useState(false);

  return (
    <>
      {transfer.msg ? (
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          <TimelineItem>
            <TimelineSeparator>
              <FiberManualRecord style={{ color: "green" }} />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <>
                <Stack direction={"row"} justifyContent="space-between">
                  <Stack
                    direction={"row"}
                    style={{ cursor: "pointer" }}
                    onClick={() => setAccordion(!accordion)}
                  >
                    <p style={{ marginTop: -5, fontSize: "13px" }}>
                      {transfer.msg}
                      {transfer?.type == "deducted Inventory" && (
                        <>
                          {singleTransferData?.origin?.address?.address}{" "}
                          {singleTransferData?.origin?.address?.apertment},
                          {singleTransferData?.origin?.address?.city},
                          {singleTransferData?.origin?.address?.province},
                          {singleTransferData?.origin?.address?.country},
                          {singleTransferData?.origin?.address?.pincode}
                        </>
                      )}
                    </p>{" "}
                    {(transfer?.type == "deducted Inventory" ||
                      transfer?.tlData) && (
                      <ArrowDropDown
                        style={{
                          cursor: "pointer",
                          marginTop: "-6px",
                          marginRight: "15px",
                        }}
                      />
                    )}
                  </Stack>

                  <p
                    style={{
                      fontSize: "14px",
                      color: "grey",
                      marginTop: -5,
                    }}
                  >
                    {" "}
                    {fTime(transfer?.cdate)}
                  </p>
                </Stack>
                {accordion && (
                  <>
                    {transfer?.type == "deducted Inventory" && (
                      <TransferItemsTimeline
                        items={singleTransferData?.items}
                      />
                    )}
                    {transfer?.type == "received_inventory" && (
                      <RcvInventoryTimeline product={transfer?.tlData} />
                    )}
                  </>
                )}
              </>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      ) : (
        <>{check && <TimeLineComment comment={transfer} />}</>
      )}
    </>
  );
}
