const initialState = { countryPayload: [] }

export default function (state = initialState, action) {
    switch (action.type) {
 
       case 'GET_COUNTRY':
          {
             return {
                ...state,
                countryPayload: action.payload
             };
          }
       default:
          return state;
    }
 }