import React, { useState, useEffect, useRef } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import {
  Grid,
  Container,
  Button,
  Typography,
  Stack,
  Card,
  Link,
  Divider,
  TextField,
  LinearProgress,
  linearProgressClasses,
  Avatar,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import Page from "../../../components/Page";
import { PATH_DASHBOARD } from "src/routes/paths";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { Inbox } from "@material-ui/icons";
import {
  useTheme,
  experimentalStyled as styled,
} from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { fDateTime, formattedNumber } from "src/utils/formatTime";
import { getCheckout, updateAbendoned } from "src/redux/actions/CheckoutAction";
import ShowTax from "src/components/_dashboard/order/ShowTax";
import Label from "src/components/Label";

export default function CheckoutView() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // UseState
  const [note, setNote] = useState();
  const [loading, setLoading] = useState(false);
  const [showTax, setShowtax] = useState(false);
  // UseSelector
  const store = useSelector((state) => state.auth.profilePayload);
  const checkout = useSelector((state) => state.checkout.singleCheckoutPayload);
  const isLoading = useSelector((state) => state.loader.loadingPayload);
  // UseEffect
  useEffect(() => {
    id && dispatch(getCheckout(id));
  }, []);

  // Functions
  const copyEmail = async () => {
    await navigator.clipboard.writeText(checkout?.customer?.overview?.email);
    toast.success("Email Copied");
  };

  return (
    <Page title="View Order">
      <Container>
        {isLoading && <LinearProgress style={{ width: "100%" }} />}
        {!isLoading && (
          <>
            {/* back button */}
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                style={{
                  marginBottom: "16px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  minHeight: "42px",
                  minWidth: "10px",
                }}
                component={RouterLink}
                to={PATH_DASHBOARD.order.checkouts}
              >
                <img
                  width="17px"
                  src="https://th.bing.com/th/id/R.9562e3998adf90b7ea409f041aab337d?rik=ERFU3YLhocBkdA&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2fKin%2feBp%2fKineBpprT.png&ehk=t39BBIm39HbdTD3%2bBxa%2bIfTm948yrYikFKGQivAW9UU%3d&risl=&pid=ImgRaw&r=0"
                />
              </Button>

              <Stack>
                <Stack direction="row" spacing={0.3}>
                  <Typography
                    variant="h5"
                    style={{ marginTop: 5, marginRight: "10px" }}
                  >
                    #{checkout?._id}
                  </Typography>
                </Stack>
                <Typography
                  style={{ fontSize: "12px", marginBottom: 10, color: "grey" }}
                >
                  {fDateTime(checkout?.cdate, store.format.timeZone)}
                </Typography>
              </Stack>
            </Stack>

            <Grid container spacing={3} sx={{ mb: 3 }}>
              {/* Right Side Bar */}
              <Grid item xs={12} md={8}>
                {/* Checkout details */}
                <Card sx={{ p: 3 }}>
                  <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" spacing={1}>
                      <h4>Checkout details</h4>
                    </Stack>
                  </Stack>
                  <Stack sx={{ mb: 4 }}>
                    {checkout?.items?.map((value, i) => {
                      if (value.disVal) {
                        const disVal =
                          checkout.discount.disType == "per"
                            ? value.qty *
                              value.rate *
                              (checkout.discount.disVal / 100)
                            : value.qty *
                              value.rate *
                              (checkout.discount.disVal / checkout.subTotal);
                        const finalDiscountValue =
                          Math.round(disVal * 100) / 100;
                        var disAmt =
                          value.qty * value.rate - finalDiscountValue;
                      }
                      return (
                        <>
                          <Grid container spacing={3} sx={{ mb: 3 }}>
                            <Grid item xs={12} md={6} marginTop={2}>
                              <Stack direction="row" spacing={2}>
                                <Avatar
                                  variant="square"
                                  width="17px"
                                  alt={
                                    value?.variant?.title ||
                                    value?.product?.title
                                  }
                                  src={
                                    value?.variant
                                      ? value?.variant?.images?.length > 0
                                        ? value?.variant?.images[0]?.url
                                        : "public/favicon/product.png"
                                      : value?.product?.images?.length > 0
                                      ? value?.product?.images[0]?.url
                                      : "public/favicon/product.png"
                                  }
                                  style={{ width: "40px", marginTop: 10 }}
                                />
                                <Link
                                  style={{
                                    textDecoration: "none",
                                    marginTop: 10,
                                    maxWidth: "200px",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    fontSize: "13px",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                  }}
                                  component={RouterLink}
                                  to={`${PATH_DASHBOARD.products.edit}/${value?.product?._id}`}
                                >
                                  {value?.product?.title}
                                  <br />
                                  {value?.variant?.title}
                                </Link>
                              </Stack>
                            </Grid>

                            <Grid item xs={12} md={2} marginTop={2}>
                                <Stack>
                                {value?.variant ? (
                                  value?.variant?.charge_tax &&
                                  <Label
                                    size="small"
                                    color={"success"}
                                    sx={{
                                      mt:2
                                    }}
                                  >
                                    {"Taxable"}
                                  </Label>
                                ) : (
                                  value?.product?.charge_tax &&
                                  <Label
                                    size="small"
                                    color={"success"}
                                    sx={{
                                      mt:2
                                    }}
                                  >
                                    {"Taxable"}
                                  </Label>
                                )}
                                </Stack>
                              </Grid>

                            <Grid item xs={12} md={2} marginTop={2}>
                              <Stack>
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    marginTop: "20px",
                                    textAlign: "right",
                                  }}
                                >
                                  {store?.currency?.symbol} {value?.rate}*{" "}
                                  {value?.qty}
                                </Typography>
                              </Stack>
                            </Grid>

                            <Grid item xs={12} md={2} marginTop={2}>
                              <Stack
                                style={{
                                  textAlign: "right",
                                }}
                              >
                                {value?.disVal > 0 ? (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: "11px",
                                        marginTop: "20px",
                                        textDecorationLine: "line-through",
                                        textDecorationStyle: "solid",
                                      }}
                                    >
                                      {store?.currency?.symbol}{" "}
                                      {value?.rate * value?.qty}
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {store?.currency?.symbol} {disAmt}
                                    </Typography>
                                  </>
                                ) : (
                                  <>
                                    <Typography
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        marginTop: "20px",
                                      }}
                                    >
                                      {store?.currency?.symbol}{" "}
                                      {value?.rate * value?.qty}
                                    </Typography>
                                  </>
                                )}
                              </Stack>
                            </Grid>
                          </Grid>

                          <Stack
                            direction="row"
                            justifyContent="space-between"
                          ></Stack>
                        </>
                      );
                    })}
                  </Stack>
                </Card>

                <Card sx={{ mt: 2, p: 3 }}>
                  {/* subTotal */}
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    style={{ marginTop: "5px" }}
                  >
                    <Stack direction="row" spacing={20}>
                      <Typography
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        Subtotal
                      </Typography>
                      <Typography
                        style={{ color: "GrayText", fontSize: "13px" }}
                      >
                        {checkout?.items?.length} item
                      </Typography>
                    </Stack>
                    <Typography style={{ color: "GrayText", fontSize: "13px" }}>
                      {store?.currency?.symbol} {checkout?.subTotal}
                    </Typography>
                  </Stack>

                  {/* Discount */}
                  {checkout?.discount && (
                    <>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        style={{ marginTop: "5px" }}
                      >
                        <Stack direction="row" spacing={20}>
                          <Typography
                            style={{
                              fontSize: "13px",
                            }}
                          >
                            Discount
                          </Typography>
                          <Typography
                            style={{ color: "GrayText", fontSize: "13px" }}
                          >
                            {checkout?.discount?.disReason}
                          </Typography>
                        </Stack>

                        <Typography
                          style={{ color: "GrayText", fontSize: "13px" }}
                        >
                          {store?.currency?.symbol}{" "}
                          {checkout?.discount?.disAmt || 0.0}
                        </Typography>
                      </Stack>
                    </>
                  )}

                  {/* Shipping */}
                  {checkout?.shipping && (
                    <>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        style={{ marginTop: "5px" }}
                      >
                        <Stack direction="row" spacing={20}>
                          <Typography
                            style={{
                              fontSize: "13px",
                            }}
                          >
                            Shipping
                          </Typography>
                          <Typography
                            style={{ color: "GrayText", fontSize: "13px" }}
                          >
                            {checkout?.shipping?.shipReason}
                          </Typography>
                        </Stack>
                        {checkout?.shipping?.value ? (
                          <Typography
                            style={{ color: "GrayText", fontSize: "13px" }}
                          >
                            {store?.currency?.symbol}{" "}
                            {checkout?.shipping?.value}
                          </Typography>
                        ) : (
                          <Typography
                            style={{ color: "GrayText", fontSize: "13px" }}
                          >
                            {store?.currency?.symbol} 0.00
                          </Typography>
                        )}
                      </Stack>
                    </>
                  )}

                  {/* tax */}
                  {checkout?.tax && (
                    <>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        style={{ marginTop: "5px" }}
                      >
                        <Stack direction="row" spacing={20}>
                          <Typography
                            style={{
                              fontSize: "13px",
                            }}
                          >
                            Tax{" "}
                            {checkout?.tax?.taxAmt > 0 &&
                              "(" + checkout?.tax?.taxType + ")"}
                          </Typography>

                          {checkout?.tax?.taxAmt > 0 ? (
                            checkout?.tax?.desc?.length > 1 ? (
                              <>
                                <Link
                                  style={{
                                    textDecoration: "none",
                                    cursor: "pointer",
                                    fontSize: "13px",
                                  }}
                                  onClick={() => setShowtax(true)}
                                >
                                  Show tax rates
                                </Link>

                                {showTax && (
                                  <ShowTax
                                    tax={checkout?.tax}
                                    setShowtax={setShowtax}
                                    symbol={store?.currency?.symbol}
                                  />
                                )}
                              </>
                            ) : (
                              <Typography style={{ fontSize: "13px" }}>
                                {checkout?.tax?.desc[0]?.name}(
                                {checkout?.tax?.desc[0]?.per}%)
                              </Typography>
                            )
                          ) : (
                            <Typography style={{ color: "GrayText" }}>
                              {" "}
                              ----
                            </Typography>
                          )}
                          {/* </Typography> */}
                        </Stack>
                        <Typography
                          style={{
                            color: "GrayText",
                            fontSize: "13px",
                            textAlign: "right",
                          }}
                        >
                          {store?.currency?.symbol}{" "}
                          {Math.round(checkout?.tax?.taxAmt * 100) / 100 || 0.0}
                          <Stack direction="row" spacing={2}>
                            <p
                              style={{ fontSize: "15px", fontWeight: "bold" }}
                            ></p>
                          </Stack>
                        </Typography>
                      </Stack>
                    </>
                  )}

                  <Divider
                    style={{
                      marginTop: 20,
                      marginLeft: "-24px",
                      marginRight: "-24px",
                    }}
                  />
                  {/* total */}
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    style={{
                      fontSize: "13px",
                      fontWeight: "bold",
                      marginTop: "10px",
                    }}
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      Total
                    </Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {store?.currency?.symbol} {checkout?.total}
                    </Typography>
                  </Stack>
                </Card>

                {/* Note */}
                <Card sx={{ mt: 2, p: 3 }}>
                  <h4 style={{ marginBottom: 20 }}>Additional details</h4>
                  <label>Note</label>
                  <TextField
                    fullWidth
                    value={note ? note : checkout?.note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </Card>
              </Grid>

              {/* Left Side Bar */}
              <Grid item xs={12} md={4}>
                <Card sx={{ p: 3 }}>
                  {/* Customer and Contact Info*/}

                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    style={{ marginBottom: 10 }}
                  >
                    <h4>Customer</h4>
                    <Link
                      component={RouterLink}
                      to={`${PATH_DASHBOARD.order.list}/${checkout?.customer?._id}`}
                    >
                      {checkout?.order} Order
                    </Link>
                  </Stack>

                  <Link
                    style={{ fontSize: "15px", cursor: "pointer" }}
                    onClick={() =>
                      navigate(
                        `${PATH_DASHBOARD.customers.edit}/${checkout?.customer?._id}`
                      )
                    }
                  >
                    {checkout?.customer?.overview?.fName}{" "}
                    {checkout?.customer?.overview?.lName}
                  </Link>

                  <Divider
                    sx={{
                      mx: "-24px",
                      my: 2,
                    }}
                  />

                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    style={{ marginBottom: 10 }}
                  >
                    <h4>Contact Information</h4>
                  </Stack>
                  <Stack
                    direction="row"
                    style={{
                      justifyContent: "space-between",
                      marginTop: 15,
                    }}
                  >
                    <Link
                      style={{
                        fontSize: "14px",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                      onClick={copyEmail}
                    >
                      {checkout?.customer?.overview?.email}
                    </Link>

                    <Inbox
                      style={{ marginRight: "12px", cursor: "pointer" }}
                      onClick={copyEmail}
                      titleAccess={"Copy Email"}
                    />
                  </Stack>
                  <Typography
                    style={{
                      color: "grey",
                      marginTop: 10,
                      fontSize: "15px",
                    }}
                  >
                    {checkout?.customer?.overview?.phone}
                  </Typography>

                  <Divider
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      marginLeft: "-24px",
                      marginRight: "-24px",
                    }}
                  />

                  {/* Shipping Address */}
                  <>
                    <Stack
                      direction={"row"}
                      justifyContent="space-between"
                      style={{ marginBottom: 10 }}
                    >
                      <h4>Shipping Address</h4>
                    </Stack>
                    {checkout?.shipAdd?.fName ? (
                      <Typography
                        style={{
                          marginTop: 15,
                          fontSize: "12px",
                        }}
                      >
                        {checkout?.shipAdd?.fName} {checkout?.shipAdd?.lName}
                        <br />
                        {checkout?.shipAdd?.address}
                        <br />
                        {checkout?.shipAdd?.city}
                        <br />
                        {checkout?.shipAdd?.province}
                        <br />
                        {checkout?.shipAdd?.country}
                        <br />
                        {checkout?.shipAdd?.ext} {checkout?.shipAdd?.phone}
                      </Typography>
                    ) : (
                      <Typography
                        style={{
                          color: "grey",
                          marginTop: 10,
                          fontSize: "13px",
                        }}
                      >
                        No shipping address.
                      </Typography>
                    )}

                    <Divider
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                        marginLeft: "-24px",
                        marginRight: "-24px",
                      }}
                    />
                  </>

                  {/* Billing Address */}
                  <>
                    <h4>Billing Address</h4>
                    {!checkout?.billAdd?.fName ? (
                      <Typography
                        style={{
                          color: "grey",
                          marginTop: 10,
                          fontSize: "13px",
                        }}
                      >
                        No billing address.
                      </Typography>
                    ) : (
                      <Typography style={{ marginTop: 10, fontSize: "12px" }}>
                        {checkout?.billAdd?.fName} {checkout?.billAdd?.lName}
                        <br />
                        {checkout?.billAdd?.address}
                        <br />
                        {checkout?.billAdd?.city}
                        <br />
                        {checkout?.billAdd?.province}
                        <br />
                        {checkout?.billAdd?.country}
                        <br />
                        {checkout?.billAdd?.ext} {checkout?.billAdd?.phone}
                      </Typography>
                    )}
                  </>
                </Card>
              </Grid>

              <Grid item xs={12} md={12}>
                <Divider />

                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="small"
                  loading={loading}
                  disabled={note ? false : true}
                  style={{ marginTop: 20, float: "right" }}
                  onClick={() =>
                    dispatch(updateAbendoned(id, note, setLoading))
                  }
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </Page>
  );
}
