const initialState = { checkoutPayload: []}

export default function (state = initialState, action) {
   switch (action.type) {
    case 'GET_ALL_CHECKOUTS':
         {
            return {
               ...state,
               checkoutPayload: action.payload
            };
         }

         case 'GET_SINGLE_CHECKOUT':
            {
               return {
                  ...state,
                  singleCheckoutPayload: action.payload
               };
            }

            case 'GET_SETTING_CHECKOUT':
               {
                  return {
                     ...state,
                     settingCheckoutPayload: action.payload
                  };
               }

         default:
         return state;
   }}