import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";

OrderNoteTimeline.propTypes = {
  note: PropTypes.object,
};

export default function OrderNoteTimeline(props) {
  const { note } = props;
  return (
    <>
      <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
        Note
      </Typography>
      <Typography variant="body2">{note.note}</Typography>
    </>
  );
}
