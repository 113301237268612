const initialState = { navigationsPayload: []}

export default function (state = initialState, action) {
   switch (action.type) {
    case 'GET_NAVIGATIONS':
         {
            return {
               ...state,
               navigationsPayload: action.payload
            };
         }

         case 'GET_SINGLE_NAVIGATION':
         {
            return {
               ...state,
               singlenavigationPayload: action.payload
            };
         }

         default:
         return state;
   }}