import React from "react";
import PropTypes from "prop-types";
import { Divider, Link, Stack, Typography } from "@material-ui/core";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useNavigate } from "react-router";

TransferItemsTimeline.propTypes = {
  items: PropTypes.array,
};

export default function TransferItemsTimeline(props) {
  const { items } = props;
  const navigate = useNavigate();

  return (
    <>
      <Stack direction={"row"} justifyContent="space-between" marginTop={1}>
        <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
          Items
        </Typography>
        <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
          Adjustment
        </Typography>
      </Stack>
      <Divider sx={{ my: 1 }} />
      {items?.map((value) => {
        return (
          <>
            <Stack direction={"row"} justifyContent="space-between">
              <Link
                onClick={() =>
                  navigate(`${PATH_DASHBOARD.products.edit}/${value?.pid}`)
                }
                style={{ cursor: "pointer", fontSize: "13px" }}
              >
                {value?.variant?.title
                  ? value?.product?.title +
                    " " +
                    "/" +
                    " " +
                    value?.variant?.title
                  : value?.product?.title}{" "}
              </Link>
              <Typography variant="body2">-{value?.qty}</Typography>
            </Stack>
            <Divider sx={{ my: 1 }} />
          </>
        );
      })}
    </>
  );
}
