const initialState = {
  allPaymentPayload: [],
  allPaymentMethodPayload: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "GET_ALL_PAYMENTS": {
      return {
        ...state,
        allPaymentPayload: action.payload,
      };
    }

    case "GET_ALL_PAYMENT_METHOD": {
      return {
        ...state,
        allPaymentMethodPayload: action.payload,
      };
    }

    case "GET_SINGLE_PAYMENT": {
      return {
        ...state,
        singlePaymentPayload: action.payload,
      };
    }

    case "CREATE_PAYMENT_METHOD": {

      const data = {
        title: action.payload.title,
        customName: action.payload.customName,
        addDetails: action.payload.payId,
        payInstruct: action.payload.payId,
        payId: action.payload.payId,
        tranFees: action.payload.tranFees,
        status: "Active",
        type: action.payload.type, 
      };
    
      return {
        ...state,
        allPaymentPayload: [...state.allPaymentPayload, data],
      };
    }

    case "UPDATE_PAYMENT_METHOD": {
      const updatePayment = state.allPaymentPayload.map((item) => {
        if (item._id == action.payload.id) {
          item.title = action.payload.data.title;
          item.customName = action.payload.data.customName;
          item.addDetails = action.payload.data.addDetails;
          item.payInstruct = action.payload.data.payInstruct;
        }
        return item;
      });
      return {
        ...state,
        allPaymentPayload: updatePayment,
      };
    }

    case "REACTIVATE_PAYMENT": {
      const reactivate = state.allPaymentPayload.map((item) => {
        if (item._id == action.payload) {
          item.status = "Active";
        }
        return item;
      });
      return {
        ...state,
        allPaymentPayload: reactivate,
      };
    }

    case "DEACTIVATE_PAYMENT": {
      const Inactivate = state.allPaymentPayload.map((item) => {
        if (item._id == action.payload) {
          item.status = "Inactive";
        }
        return item;
      });
      return {
        ...state,
        allPaymentPayload: Inactivate,
      };
    }

    default:
      return state;
  }
}
