import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/authentication/Login';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children }) {
  const { isAuthenticated } = useAuth();
  const { pathname, search, searchParams } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  const par = new URLSearchParams(useLocation().search);
  if (par.has('token')) {
    const token = par.get('token');
    window.localStorage.setItem('accessToken', token);
    return <Navigate to={pathname} />;
  } 

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    window.location = 'https://accounts.planetshopping.com/auth/signin?callback_type=store&callback='.concat(window.location);
    // return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
