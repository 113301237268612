const initialState = { orderPaymentPayload: {}, allProcessPayload:{}};

export default function (state = initialState, action) {
  switch (action.type) {
    case "GET_ORDER_PAYMENT": {
      return {
        ...state,
        orderPaymentPayload: action.payload,
      };
    }

    case "GET_ALL_PROCESS": {
      return {
        ...state,
        allProcessPayload: action.payload,
      };
    }

    case "GET_PROCESS": {
      return {
        ...state,
        processPayload: action.payload,
      };
    }

    case "UPDATE_PROCESS": {
      return {
        ...state,
        processPayload: {
          ...state.processPayload,
          [`${action.payload.type}`]: {
            ...state.processPayload[`${action.payload.type}`],
            [`${action.payload.name}`]: true,
          },
        },
      };
    }

    default:
      return state;
  }
}
