import moment from 'moment/moment';
import React from 'react';
import PropTypes from "prop-types";
import XLSX from "sheetjs-style";
import { saveAs } from "file-saver";

export default function DefaultWorkBook(docs, fields, name) {

  const excelColumnNames = [
    "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N",
    "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "AB",
    "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN",
    "AO", "AP", "AQ", "AR", "AS", "AT", "AU", "AV", "AW", "AX", "AY", "AZ",
    "BA", "BB", "BC", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BK", "BL",
    "BM", "BN", "BO", "BP", "BQ", "BR", "BS", "BT", "BU", "BV", "BW", "BX",
    "BY", "BZ", "CA", "CB", "CC", "CD", "CE", "CF", "CG", "CH", "CI", "CJ",
    "CK", "CL", "CM", "CN", "CO", "CP", "CQ", "CR", "CS", "CT", "CU", "CV",
    "CW", "CX", "CY", "CZ", "DA", "DB", "DC", "DD", "DE", "DF", "DG", "DH", "DI", "DJ",
    "DK", "DL", "DM", "DN", "DO", "DP", "DQ", "DR", "DS", "DT", "DU", "DV",
    "DW", "DX", "DY", "DZ",
  ];
  const headerColorStyle = {rgb: "FFD3D3D3"};
const blackColorStyle = {rgb: "FF000000"};
const errorColorStyle = {rgb: "FFF55B5B"};
const borderLineStyle = {style: "thin", color: blackColorStyle};
const borderStyle = {top: borderLineStyle, right: borderLineStyle, bottom: borderLineStyle, left: borderLineStyle};
const headerStyle = {alignment: {wrapText: 1, horizontal: "center"}, fill: {fgColor: headerColorStyle}, font: {sz: 10}, border: borderStyle};
const defaultStyle = {alignment: {horizontal: "left"}, font: {sz: 10}, border: borderStyle};
const defaultStyleRight = {alignment: {wrapText: 1, horizontal: "right"}, font: {sz: 10}, border: borderStyle};
// const errorStyle = {alignment: {horizontal: "left"}, fill: {fgColor: errorColorStyle}, font: {sz: 10}, border: borderStyle};
// const errorStyleRight = {alignment: {wrapText: 1, horizontal: "right"}, fill: {fgColor: errorColorStyle}, font: {sz: 10}, border: borderStyle};
const dateStyleWithBorder = {alignment: {wrapText: true, horizontal: "left"}, font: {sz: 10}, border: borderStyle, numFmt: "dd/mm/yyyy"};
  const displayString = function(input) {
    if (!input) {
      return {v: "", t: "s", s: defaultStyle};
    }
    if (typeof input === "object" && input !== null) {
      input = input.toString();
    } else if (Array.isArray(input)) {
      input = input.toString();
    } else {
      input = "" + input;
    }
    return {v: input, t: "s", s: defaultStyle};
  };
  const displayInt = function(input, hideZero) {
    if (input == null) {
      return {v: "", t: "s", s: defaultStyle};
    }
    if ((input == 0) && hideZero) {
      return {v: "", t: "s", s: defaultStyle};
    }
    input = Number(input);
    if (isNaN(input)) {
      return {v: "", t: "s", s: defaultStyle};
    }
    return {v: input, t: "n", s: defaultStyleRight};
  };
  const displayDate = function(input) {
    if (!input) {
      return {v: "", t: "s", s: defaultStyle};
    }
    if (input instanceof Date && !isNaN(input)) {
      input = new Date(input.getTime() + 5.5 * 60 * 60 * 1000);
      return {v: moment(input).format("DD/MM/YYYY"), t: "s", s: defaultStyle};
    } else if (!isNaN(input)) {
      var dt = new Date(Number(input));
      dt.setTime(dt.getTime() + 5.5 * 60 * 60 * 1000);
      return {v: moment(dt).format("DD/MM/YYYY"), t: "s", s: defaultStyle};
    } else {
      return {v: "", t: "s", s: defaultStyle};
    }
  };
  const displayBoolean = function(input) {
    if ((input == null) || (input == undefined)) {
      return {v: "", t: "s", s: defaultStyle};
    } else if (input != true) {
      return {v: "False", t: "s", s: defaultStyle};
    }
    return {v: "True", t: "s", s: defaultStyle};
  };

  const s2ab = function(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  }

  let rowCount = 1;
		const workbook = {SheetNames: [], Sheets: {}};
		const main = {};
		const availableColumnCount = fields.length;
		for (let loop = 0, length = fields.length; loop < length; loop++) {
			main[excelColumnNames[loop] + rowCount] = {v: fields[loop].display, s: headerStyle, t: "s"};
		}
		for (let loop = 0, length = docs.length; loop < length; loop++) {
			rowCount++;
			const rowError = docs[loop]?.error || false;
      const hasError = docs[loop].hasError ? true: false;
			for (let inner = 0, innerLen = fields.length; inner < innerLen; inner++) {
				let fVal = docs[loop][fields[inner].key];
				if ((fVal==null) || (fVal==undefined)) {
					fVal = {val: fVal};
				}
				// eslint-disable-next-line no-prototype-builtins
				if (!fVal.hasOwnProperty("val")) {
					fVal = {val: fVal};
				}
				let val = null;
				if (fields[inner].type == "s") {
					val = displayString(fVal.val);
				} else if (fields[inner].type == "n") {
					val = displayInt(fVal.val);
				} else if (fields[inner].type == "b") {
					val = displayBoolean(fVal.val);
				} else if (fields[inner].type == "d") {
					val = displayDate(fVal.val);
				}
				// if (hasError) {
				// 	val.s= JSON.parse(JSON.stringify(val.s));
				// 	val.s.fill = {fgColor: errorColorStyle};
				// 	val.s.font.color = {rgb: "FFFFE3E3"};
				// }
				if (fVal.error || rowError) {
					val.s= JSON.parse(JSON.stringify(val.s));
					val.s.fill = {fgColor: errorColorStyle};
					val.s.font.color = {rgb: "FFFFE3E3"};
				}
				main[excelColumnNames[inner] + rowCount] = val;
			}
		}
		main["!ref"] = `A1:${excelColumnNames[availableColumnCount]}` + (rowCount + 1);
		workbook.SheetNames.push("main");
		workbook.Sheets["main"] = main;
      var wbout = XLSX.write(workbook, {
          bookType: "csv",
          bookSST: true,
          type: "binary",
      });
      saveAs(new Blob([s2ab(wbout)], {
          type: "application/octet-stream",
      }), name +".csv");
  
 
}
