export default function (state = {}, action) {
  switch (action.type) {
    case "ACCESS_TOKEN": {
      return {
        ...state,
        tokenPayload: action.payload,
      };
    }
    case "GET_PROFILE": {
      return {
        ...state,
        profilePayload: action.payload,
      };
    }
    case "CHNAGE_STORE_DOMAIN": {
      return {
        ...state,
        profilePayload: {
          ...state.profilePayload,
          domain: action.payload,
        },
      };
    }
    case "GET_SETUP": {
      return {
        ...state,
        setupPayload: action.payload,
      };
    }
    
    case "IS_FIRST_LOADING": {
      return {
        ...state,
        firstLoadingPayload: action.payload,
      };
    }

    case "IS_FIRST_LOADING": {
      return {
        ...state,
        firstLoadingPayload: action.payload,
      };
    }
    case "UPDATE_PLAN": {
      return {
        ...state,
        profilePayload: {
          ...state.profilePayload,
          plan: { ...state.profilePayload.plan, id: action.payload },
        },
      };;
    }
    
    default:
      return state;
  }
}
