import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import {
  Typography,
  FormControl,
  FormControlLabel,
  Divider,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Checkbox,
} from "@material-ui/core";
import { Close, CloudUpload } from "@material-ui/icons";
import { LoadingButton } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import { DefaultWorkBook } from "src/assets";
import { ExportTotalOrder, ExportTotalSale } from "src/redux/actions/AnalyticsAction";
import moment from "moment";

TotalOrderExport.propTypes = {
  sethandleExport: PropTypes.func,
  data: PropTypes.object,
};

export default function TotalOrderExport(props) {
  const { sethandleExport, data } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const submitExport = () => {
    dispatch(
      ExportTotalOrder(
        data.today,
        data.compare, 
        setLoading,
        (response) => {
          ExportOrder(response);
        }
      )
    );
    sethandleExport(false);
    setOpen(false);
  };

  const ExportOrder = (orders) => {
    const todayArray = [];
    const compareArray = [];

    if (orders) {
      const todayloop = orders?.today?.map((op) => {
        var Difference_In_Time =
          data.today[0].endDate.getTime() - data.today[0].startDate.getTime();
        var Days_Calcualtion = Difference_In_Time / (1000 * 3600 * 24);
        if (Days_Calcualtion == 0.999999988425926 && Days_Calcualtion == 0) {
          var convertDate = moment(op._id)
            .format("MMM D YYYY hh:mm A")
            .toLocaleString();
        } else if (Days_Calcualtion > 0 && Days_Calcualtion <= 90) {
          var convertDate = moment(op._id).format("MMM D YYYY");
        } else if (Days_Calcualtion > 90) {
          var convertDate = moment(op._id).format("MMM YYYY");
        }
        return {
          ...op,
          day: convertDate,
        };
      });

      const todayLabel = orders?.todayLabel?.map((label) => {
        const findToday = todayloop.find((val) => val.day == label);
        if (findToday) {
          if (findToday.day == label) {
            todayArray.push({
              day: label,
              ordreCount: findToday.ordreCount,
              avgorderValue: findToday.avgorderValue,
              avg: findToday.avg,
            });
          }
        } else {
          todayArray.push({
            day: label,
            ordreCount: 0.0,
            avgorderValue: 0.0,
            avg: 0.0,
          });
        }
      });

      const compareloop = orders?.comapre?.map((com) => {
        var Difference_In_Time =
        data.compare[0].endDate.getTime() - data.compare[0].startDate.getTime();
        var Days_Calcualtion = Difference_In_Time / (1000 * 3600 * 24);
        if (Days_Calcualtion == 0.999999988425926 && Days_Calcualtion == 0) {
          var convertDate = moment(com._id).format("MMM D YYYY hh:mm A");
        } else if (Days_Calcualtion > 0 && Days_Calcualtion <= 90) {
          var convertDate = moment(com._id).format("MMM D YYYY");
        } else if (Days_Calcualtion > 90) {
          var convertDate = moment(com._id).format("MMM YYYY");
        }
        return {
          ...com,
          day: convertDate,
        };
      });

      const compareLabel = orders?.compareLabel?.map((comlabel) => {
        const findCompare = compareloop.find((item) => item.day == comlabel);
        if (findCompare) {
          if (findCompare.day == comlabel) {
            compareArray.push({
              day: comlabel,
              ordreCount: findCompare.ordreCount,
              avgorderValue: findCompare.avgorderValue,
              avg: findCompare.avg,
            });
          }
        } else {
          compareArray.push({
            day: comlabel,
            ordreCount: 0.0,
            avgorderValue: 0.0,
            avg: 0.0,
          });
        }
      });
    }

    if (todayArray?.length > 0) {
      const fields = [];
      fields.push({
        display: "Day",
        key: "day",
        excelKey: "Day",
        type: "s",
      });
      fields.push({
        display: "Orders",
        key: "ordreCount",
        excelKey: "Orders",
        type: "n",
      });
      fields.push({
        display: "Average units ordered",
        key: "avgorderValue",
        excelKey: "Average units ordered",
        type: "n",
      });
      fields.push({
        display: "Average order value",
        key: "avg",
        excelKey: "Average order value",
        type: "n",
      });
      const wbResult = DefaultWorkBook(
        todayArray,
        fields,
        "TotalOrderReport_"
      );
    } else {
      toast.warn("No data selected for export");
    }

    if (value) {
      if (compareArray?.length > 0) {
        const fields = [];
        fields.push({
          display: "Day",
          key: "day",
          excelKey: "Day",
          type: "s",
        });
        fields.push({
          display: "Orders",
          key: "ordreCount",
          excelKey: "Orders",
          type: "n",
        });
        fields.push({
          display: "Average units ordered",
          key: "avgorderValue",
          excelKey: "Average units ordered",
          type: "n",
        });
        fields.push({
          display: "Average order value",
          key: "avg",
          excelKey: "Average order value",
          type: "n",
        });
        const wbResult = DefaultWorkBook(
          compareArray,
          fields,
          "TotalOrderCompareReport_"
        );
      } else {
        toast.warn("No data selected for export");
      }
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => (setOpen(false), sethandleExport(false), setValue(""))}
        maxWidth="sm"
      >
        <DialogTitle id="responsive-dialog-title">
          Export your report
          <IconButton
            aria-label="close"
            onClick={() => (setOpen(false), sethandleExport(false))}
            sx={{
              position: "absolute",
              right: 8,
              top: 10,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider style={{ marginTop: 10 }} />
        <DialogContent>
          <DialogContentText>
            <Typography style={{ fontSize: "14px" }}>
              Report will be exported as a CSV (comma separated values) table.
            </Typography>

            <FormControl component="fieldset">
              <Typography style={{ fontSize: "13px" }}>Export</Typography>
              <FormControlLabel
                value={value}
                onChange={(e) => setValue(e.target.checked)}
                control={<Checkbox />}
                label="Include comparison data (separate csv file)"
              />
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => (
              setOpen(false), sethandleExport(false), setValue("")
            )}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            onClick={() => submitExport()}
            loading={loading}
          >
            Export Total Order
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
