const initialState = { locationPayload: [], countryPayload: [] }

export default function (state = initialState, action) {
    switch (action.type) {
 
       case 'GET_LOCATION':
          {
             return {
                ...state,
                locationPayload: action.payload
             };
          }
          case 'GET_SINGLE_LOCATION':
          {
             return {
                ...state,
                singleLocationPayload: action.payload
             };
          }
       case 'GET_COUNTRY':
          {
             return {
                ...state,
                countryPayload: action.payload
             };
          }
       default:
          return state;
    }
 }
 