import {
  Card,
  CardHeader,
  Avatar,
  IconButton,
  Stack,
  Typography,
  CardContent,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  Menu,
  MenuItem,
  Fade,
  TextField,
} from "@material-ui/core";
import { Close, Delete, Edit, MoreVert } from "@material-ui/icons";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { fTime } from "../../../utils/formatTime";
import {
  deleteComment,
  updateComment,
} from "../../../redux/actions/TimelineAction";
import { LoadingButton } from "@material-ui/lab";

TimeLineComment.propTypes = {
  comment: PropTypes.object,
};

export default function TimeLineComment(props) {
  const { comment } = props;

  const dispatch = useDispatch();

  const store = useSelector((state) => state.auth.profilePayload);

  const [cancel, setCancel] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editComment, setEditComment] = useState();

  return (
    <>
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} md={12}>
          <Card style={{ borderRadius: "10px", marginTop: 5 }}>
            <CardHeader
              avatar={<Avatar>{store?.user?.init}</Avatar>}
              title={
                <Stack direction="row" spacing={1}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {store?.user?.firstName} {store?.user?.lastName}
                  </Typography>
                  <Typography variant="caption" style={{ marginTop: 3 }}>
                    {fTime(comment?.cdate)}
                  </Typography>
                </Stack>
              }
              action={
                <IconButton aria-label="settings">
                  <MoreVert
                    onClick={(event) =>
                      setAnchorEl(anchorEl ? null : event.currentTarget)
                    }
                  />
                </IconButton>
              }
            // subheader={!edit &&  comment?.comment}
            />
            <CardContent>
              <Typography variant="caption" style={{ marginTop: 3 }}>
                {!edit && comment?.comment}
              </Typography>

              {edit && (
                <>
                  <TextField
                    fullWidth
                    size="small"
                    value={editComment ? editComment : comment?.comment}
                    onChange={(e) => setEditComment(e.target.value)}
                  />
                  <Stack direction={"row-reverse"} marginTop={1} spacing={2}>
                    <LoadingButton
                      disabled={editComment ? false : true}
                      loading={loading}
                      variant="contained"
                      onClick={() =>
                      (dispatch(
                        updateComment(
                          comment?._id,
                          comment?.iId,
                          comment?.tlType,
                          editComment,
                          setLoading
                        )
                      ),
                        setEdit(false)
                      )
                      }
                    >
                      Save
                    </LoadingButton>
                    <Button variant="outlined" onClick={() => setEdit(false)}>
                      Cancel
                    </Button>
                  </Stack>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={() => (setEdit(true), setAnchorEl(null))}>
          <Edit style={{ color: "green" }} />
          &nbsp;Edit
        </MenuItem>
        <MenuItem onClick={() => (setCancel(true), setAnchorEl(null))}>
          <Delete style={{ color: "red" }} />
          &nbsp;Delete
        </MenuItem>
      </Menu>
      <Dialog
        open={cancel}
        onClose={() => setCancel(false)}
        aria-labelledby="alert-dialog-title"
        fullWidth
      >
        <DialogTitle id="responsive-dialog-title">
          {"Delete comment"}
          <IconButton
            aria-label="close"
            onClick={() => setCancel(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 10,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider style={{ marginTop: 10 }} />
        <DialogContent>
          <DialogContentText>
            Deleting this comment can’t be reversed. It will no longer be shown
            in the timeline.
            <br />"{comment?.comment}"
          </DialogContentText>
        </DialogContent>{" "}
        <Divider />
        <DialogActions>
          <Button variant="outlined" onClick={() => setCancel(false)}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="error"
            loading={loading}
            onClick={() => (
              dispatch(deleteComment(comment?._id, comment?.iId, comment?.tlType, setLoading)),
              setCancel(false)
            )}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
