import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";

CustomerNoteTimeline.propTypes = {
  note: PropTypes.object,
};

export default function CustomerNoteTimeline(props) {
  const { note } = props;
  return (
    <>
      <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
       New Note :
      </Typography>
      <Typography variant="body2">{note?.newNote}</Typography>
      {note?.originalNote != "" && 
      <>
      <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
      Original Note :
     </Typography>
     <Typography variant="body2">{note?.originalNote}</Typography>
     </>
    }
    </>
  );
}
