const initialState = { imageListPayload: [] }
export default function (state = initialState, action) {
   switch (action.type) {
      case 'IMAGE_ADD': {
         // let addedData = action.payload;
         // let clone = JSON.parse(JSON.stringify(state.imageListPayload));
         // clone.unshift(addedData);
         // return { ...state, imageListPayload: clone };
         // state.push(action.payload)
         // return state

         return {
            ...state,
            imageListPayload: [...state.imageListPayload, action.payload]
         };

      }
      case 'IMAGE_DELETE': {
         let _id = action.payload;
         let clone = JSON.parse(JSON.stringify(state.imageListPayload));
         const index = clone.findIndex((obj) => obj._id === _id);
         // console.log("Red", index);
         if (index !== -1) clone.splice(index, 1);
         return { ...state, imageListPayload: clone };
      }
      default:
         return state;
   }
}
