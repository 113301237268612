import { toast } from "react-toastify";
import ApiExt from "../Provider/ApiExt";

//Get Return
export function getReturn(id) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });
    ApiExt.post("/api/order/get-return", { oId: id })
      .then(function (response) {
        // console.log("Get Return Action=================", response.data);

        if (response.data.status === true) {
          dispatch({
            type: "SET_RETURN",
            payload: response.data.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });

        toast.error("Something went wrong. Please try again.");
      });
  };
}

//Submit Return
export function submitReturn(
  id,
  values,
  check,
  tracking,
  media,
  callback,
  setLoading
) {
  return async function (dispatch) {
    setLoading(true);
    const value = {
      oId: id,
      data: {
        items: values?.items?.map((item) => {
          if (item?.fulfill?.items?.disVal) {
            return {
              qty: +item?.fulfill?.items?.myqty,
              pid: item?.fulfill?.items?.pid,
              vid: item?.fulfill?.items?.vid || "",
              fid: item?.fulfill?._id,
              reason: item?.fulfill?.items?.reason,
              price: +item?.fulfill?.items?.price,
              total: Math.round(item?.fulfill?.items?.total * 100) / 100,
              disVal: Math.round(item?.fulfill?.items?.disVal * 100) / 100,
              disAmt: Math.round(item?.fulfill?.items?.disAmt * 100) / 100,
              tax: item?.fulfill?.items?.fulfillTax,
            };
          } else {
            return {
              qty: +item?.fulfill?.items?.myqty,
              pid: item?.fulfill?.items?.pid,
              vid: item?.fulfill?.items?.vid || "",
              fid: item?.fulfill?._id,
              reason: item?.fulfill?.items?.reason,
              price: +item?.fulfill?.items?.price,
              tax: item?.fulfill?.items?.fulfillTax,
              total: Math.round(item?.fulfill?.items?.total * 100) / 100,
            };
          }
        }),
        shipping: check,
        trackingNo: tracking?.trackingNo,
        carrier: tracking?.Carrier?._id,
        url:
          tracking?.Carrier?.tUrl == ""
            ? tracking?.tUrl
            : tracking?.Carrier?.tUrl,
        image: media?._id || "",
      },
    };

    ApiExt.post("/api/order/return", value)
      .then(function (response) {
        // console.log("submitReturn Action=================", response.data);

        setLoading(false);
        if (response.data.status === true) {
          toast.success(response?.data?.msg);
          // dispatch({
          //   type: "SUBMIT_RETURN",
          //   payload: value,
          // });
          callback();
        } else {
          toast.warn("Please try again");
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

//Get Return to Refund
export function getReturntoRefund(oId, returnId) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });
    ApiExt.post("/api/order/get-returnTorefund", {
      oId: oId,
      returnId: returnId,
    })
      .then(function (response) {
        // console.log(
        //   "Get ReturntoRefund Action=================",
        //   response.data
        // );

        if (response.data.status === true) {
          dispatch({
            type: "GET_RETURNTOREFUND",
            payload: response.data.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });

        toast.error("Something went wrong. Please try again.");
      });
  };
}

//Submit Return to Refund
export function submitReturntoRefund(
  oId,
  returnId,
  values,
  callback,
  setLoading
) {
  return async function (dispatch) {
    // setLoading(true);
    const value = {
      oId: oId,
      returnId: returnId,
      data: {
        items: values?.items?.map((item) => {
          return {
            qty: item?.return?.items?.myqty,
            pid: item?.return?.items?.pid,
            fid: item?.return?.items?.fid,
            vid: item?.return?.items?.vid || "",
            price: item?.return?.items?.price,
            reStock: item?.return?.items?.reStock,
            total: item?.return?.items?.total,
            disVal: item?.return?.items?.disVal
              ? (Math.round(item?.return?.items?.disVal) * 100) / 100
              : 0,
            disAmt: item?.return?.items?.disVal
              ? Math.round(item?.return?.items?.disAmt * 100) / 100
              : 0,
            tax: item?.return?.items?.refundTax,
          };
        }),
        amt: +(Math.round(values.amt) * 100) / 100,
        total: +values.total,
        subTotal: values.subTotal,
        discount: values?.discount,
        shipping: +values?.shipping,
      },
    };
console.log(value);
    ApiExt.post("/api/order/returnTorefund", value)
      .then(function (response) {
        // console.log("submitReturntoRefund Action=================", response.data);

        setLoading(false);
        if (response.data.status) {
          toast.success(response?.data?.msg);
          // dispatch({
          //   type: "SUBMIT_RETURN_TO_REFUND",
          //   payload: value,
          // });
          callback();
        } else {
          toast.warn("Please try again");
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}
