const initialState = {
  storePayload: {},
  theme: [],
  themeLibrary: [],
  websiteScreenShots: {},
  isLoadingWSS: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "GET_STORE": {
      return {
        ...state,
        storePayload: action.payload,
      };
    }
    case "GET_THEME": {
      return {
        ...state,
        theme: action.payload,
      };
    }
    case "GET_THEME_LIBRARY": {
      return {
        ...state,
        themeLibrary: action.payload,
      };
    }

    case "GET_STATE": {
      return {
        ...state,
        statePayload: action.payload,
      };
    }

    case "GET_CURRENCY": {
      return {
        ...state,
        currencyPayload: action.payload,
      };
    }

    case "GET_TIMEZONE": {
      return {
        ...state,
        timeZonePayload: action.payload,
      };
    }

    case "GET_UNITSYSTEM": {
      return {
        ...state,
        unitSystemPayload: action.payload,
      };
    }

    case "GET_INDUSTRY": {
      return {
        ...state,
        industryPayload: action.payload,
      };
    }

    case "GET_WEBSITE_SCREENSHOTS": {
      return {
        ...state,
        websiteScreenShots: action.payload,
      };
    }

    case "IS_LOADING_WEBSITE_SCREENSHOTS": {
      return {
        ...state,
        isLoadingWSS: action.payload,
      };
    }
    case "UPDATE_TAX": {
      return {
        ...state,
        storePayload: {
          ...state.storePayload,
          tax: {
            priceTax: action.payload.tax.priceTax,
            shipTax: action.payload.tax.shipTax,
          },
        },
      };
    }
    case "UPDATE_GIFTCARD": {
      return {
        ...state,
        storePayload: {
          ...state.storePayload,
          giftCard: {
            // ...state.storePayload.giftCard,
            exp_type: action.payload.giftCard.exp_type,
            type: action.payload.giftCard.type,
            value: action.payload.giftCard.value,
          },
          // giftCard: action.payload,
        },
      };
    }

    case "UPDATE_CURRENCY": {
      return {
        ...state,
        storePayload: {
          ...state.storePayload,
          currency: action.payload,
        },
      };
    }

    default:
      return state;
  }
}
