import moment from "moment";

const initialState = {
  issueGiftCardPayload: [],
  GiftCardExportPayload :[]
  //  productTypePayload: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "GIFTCARDS_EXPORT": {
      return {
        ...state,
        GiftCardExportPayload: action.payload,
      };
    }
    // GetAll
    case "GET_ISSUE_GIFT_CARD": {
      return {
        ...state,
        issueGiftCardPayload: action.payload,
      };
    }
    // Single 
    case "GET_SINGLE_ISSUE_GIFT": {
      return {
        ...state,
        singleIssueGiftPayload: action.payload,
      };
    }

    case "UPDATE_CUSTOMER_OVERVIEW": {
      return {
        ...state,
        singleIssueGiftPayload: {
          ...state.singleIssueGiftPayload,
          cid: action.payload.data.cid
          //  customer: { ...state.singleIssueGiftPayload.customer, overview: action.payload.data },
        },
      };
    }

    // Note
    case "UPDATE_ISSUEGIFT": {
      return {
        ...state,
        singleIssueGiftPayload: {
          ...state.singleIssueGiftPayload,
          // note: { ...state.singleIssueGiftPayload.note, value: action.payload },
        },
      };
    }
    // Exp
    case "UPDATE_ISSUEGIFT_EXP": {
      
      return {
        ...state,
        singleIssueGiftPayload: {
          ...state.singleIssueGiftPayload,
          exp_date: 
          action.payload.exp_date == "" ? "No Expiry":moment(action.payload.exp_date, "DD/MM/YYYY").toDate(),
        },
      };
    }
    default:
      return state;
  }
}
