const initialState = {
    RefundPayload: [],
    //  productTypePayload: []
  };
  export default function (state = initialState, action) {
    switch (action.type) {
        case "SET_REFUND": {
          return {
            ...state,
            RefundPayload: action.payload,
          };
        }

        case "SUBMIT_REFUND": {
          return {
            ...state,
            submitrefundPayload: action.payload,
          };
        }
        default:
      return state;
    }
  }