import React, { useState, useEffect } from "react";
import _ from "lodash";
import { sentenceCase } from "change-case";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  LinearProgress,
  useTheme,
  Link,
} from "@material-ui/core";
// redux
import { useDispatch, useSelector } from "react-redux";
import { paramCase } from "change-case";
// routes
import { PATH_DASHBOARD } from "src/routes/paths";
// components
import Page from "src/components/Page";
import Label from "src/components/Label";
import Scrollbar from "src/components/Scrollbar";
import SearchNotFound from "src/components/SearchNotFound";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import { getCollections } from "src/redux/actions";
import ListHead from "src/components/_dashboard/ListTable/ListHead";
import ListToolbar from "src/components/_dashboard/collections/ListToolbar";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

export default function CollectionList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("title");
  const [selectedTab, setSelectedTab] = useState("");
  const [text, setText] = useState("");

  const collections = useSelector(
    (state) => state.collections.collectionPayload
  );
  const isLoading = useSelector((state) => state.loader.loadingPayload);

  useEffect(() => {
    handleCollectionData({
      next: "",
      prev: "",
      limit: 10,
      search: {
        name: "",
        status: "",
      },
    });
  }, []);

  const handleCollectionData = (props) => {
    dispatch(
      getCollections({
        next: props.next,
        prev: props.prev,
        limit: props.limit,
        search: props.search,
      })
    );
  };

  const lastCollection = collections && collections[collections?.length - 1];
  const firstCollection = collections && collections[0];
  const [isPrevious, setIsPrevious] = useState(false);

  const handleChangePage = (Pagination) => {
    if (Pagination == "next") {
      handleCollectionData({
        next: lastCollection?._id,
        prev: "",
        limit: 10,
        search: {},
      });
      setIsPrevious(true);
    } else {
      handleCollectionData({
        next: "",
        prev: firstCollection?._id,
        limit: 10,
        search: {},
      });
      setIsPrevious(false);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const TABLE_HEAD = [
    { id: "title", label: "Collection Name", alignRight: false },
    { id: "status", label: "Status", alignRight: false },
    { id: "type", label: "Collection Type", alignRight: false },
  ];

  return (
    <Page title="Collection List">
      <Container>
        {!selectedTab && collections?.length == 0 && !text ? (
          <>
          <Typography variant="h4" style={{ margin: "10px 0px 30px 10px" }}>Collections</Typography>
            <Card sx={{ p: 10 }}>
              <center>
                <img
                  src="https://img.freepik.com/free-vector/girl-choosing-eco-beauty-products-cosmetics-shop-standing-case-with-creams-lotions-taking-shampoo-bottle_74855-10674.jpg?w=740&t=st=1679989038~exp=1679989638~hmac=0f02b5969e4716cf262b6acced18dfc9c5364a49b04b8dbaef10837497d0ef6b"
                  style={{
                    maxWidth: "500px",
                  }}
                  alt="Italian Trulli"
                ></img>
                <Typography variant="h6">Create New Collection</Typography>
                
                <Typography style={{ marginTop: "12px" }}>
                  <Button
                    variant="contained"
                    component={RouterLink}
                    to={PATH_DASHBOARD.products.newCollection}
                  >
                    Add new collection
                  </Button>
                </Typography>
              </center>
            </Card>
          </>
        ) : (
          <>
            <HeaderBreadcrumbs
              heading="Collections"
              links={[
                { name: "Dashboard", href: PATH_DASHBOARD.root },
                { name: "List" },
              ]}
              action={
                <Button
                  variant="contained"
                  component={RouterLink}
                  to={PATH_DASHBOARD.products.newCollection}
                  startIcon={<Icon icon={plusFill} />}
                >
                  New Collection
                </Button>
              }
            />

            <Card>
              <ListToolbar setSelectedTab={setSelectedTab} setText={setText}/>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  {isLoading && <LinearProgress style={{ width: "100%" }} />}
                  <Table>
                    <ListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={collections?.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {collections?.map((item, index) => {
                        return (
                          <TableRow
                            hover
                            key={index}
                            tabIndex={-1}
                            onClick={() =>
                              navigate(
                                `/dashboard/collection/edit/${paramCase(
                                  item?._id
                                )}`
                              )
                            }
                            style={{
                              cursor: "pointer",
                              borderBottom: "1px solid #d5d5d5",
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Avatar
                                  variant="square"
                                  alt={item?.title}
                                  src={
                                    item?.images?.length > 0
                                      ? item?.images?.[0]?.url
                                      : "public/favicon/product.png"
                                  }
                                />
                                <Typography variant="subtitle2" noWrap>
                                  <Link style={{ textDecoration: "none" }}>
                                    {item?.title}
                                  </Link>
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              <Label
                                variant={
                                  theme.palette.mode === "light"
                                    ? "ghost"
                                    : "filled"
                                }
                                color={
                                  item?.status == "archive"
                                    ? "error"
                                    : item?.status == "draft"
                                    ? "default"
                                    : "success"
                                }
                              >
                                {sentenceCase(item?.status)}
                              </Label>
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ textTransform: "capitalize" }}
                            >
                              {item?.type == "auto" ? "Automated" : "Manual"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    {collections?.length === 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={text} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              {!isLoading && (
                <>
                  <Stack
                    direction="row"
                    spacing={2}
                    style={{
                      marginTop: 30,
                      marginBottom: 20,
                      justifyContent: "center",
                    }}
                  >
                    {isPrevious && (
                      <Button
                        variant="outlined"
                        onClick={() => handleChangePage("prev")}
                      >
                        <KeyboardArrowLeft />
                      </Button>
                    )}
                    {collections?.length > 9 && (
                      <Button
                        variant="outlined"
                        onClick={() => handleChangePage("next")}
                      >
                        <KeyboardArrowRight />
                      </Button>
                    )}
                  </Stack>
                </>
              )}
            </Card>
          </>
        )}
      </Container>
    </Page>
  );
}
