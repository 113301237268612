import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
    Box,
    Stack,
    Checkbox,
    Typography,
    InputAdornment,
    OutlinedInput,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Avatar,
    LinearProgress,
    IconButton,
    makeStyles,
    Button,
    Alert,
    AlertTitle,
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import _, { truncate } from "lodash";
import { getAllProduct } from "src/redux/actions";
import {
    useTheme,
    experimentalStyled as styled,
} from "@material-ui/core/styles";


const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    // width: 240,
    transition: theme.transitions.create(["box-shadow", "width"], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    // '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    "& fieldset": {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
    },
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));


const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        zIndex: 9999
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        zIndex: 9999
    },
}))(MuiDialogActions);

ProductPicker.propTypes = {
    /**
     * Callback fired when the Done is clicked.
     * 
     * @param {object} selectedItems Can be: `"object"`, `"array"`.
     */
    onSelect: PropTypes.func,
    onClose: PropTypes.func,
    /**
     * If `true`, can be select multiple items.
     *
     * @default false
     */
    multiple: PropTypes.bool,
    /**
     * value, usually the pre selected value.
     */
    value: PropTypes.object,
    /**
     * searchPlaceHolder, usually the text value of search placeholder.
     */
    searchPlaceHolder: PropTypes.string
};

ProductPicker.defaultProps = {
    onSelect: (selectedItems) => { },
    onClose: () => { },
    multiple: false,
    value: [],
    searchPlaceHolder: "Search Product"
};


/**
 * ProductSelector is a overlaid modal paper based components with products list.
 *
 */
export default function ProductPicker(props) {
    const { onSelect, onClose, multiple, value, searchPlaceHolder } = props;
    const dispatch = useDispatch();
    //const [Product, setProduct] = useState(false);
    const [searchProduct, setSearchProduct] = useState("");
    const [Item, setItem] = useState([]);
    const [selectProduct, setSelectProduct] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [isFirstTime, setIsFirstTime] = useState(true);
    const [isLoadMore, setIsLoandMore] = useState(true);
    const [open, setOpen] = useState(false);
    const [images, setImages] = useState([]);
    const [files, setFiles] = useState([]);

    const products = useSelector((state) => state.products.allProductPayload);
    const store = useSelector((state) => state.auth.profilePayload);
    const submittingPayload = useSelector((state) => state.loader.submittingPayload);

    useEffect(() => {
        dispatch(
            getAllProduct({
                search: {
                    name: "",
                },
            })
        );
    }, []);

    useEffect(() => {
        const items = products?.map((item) => {
            item.qty = 1;
            if (item?.variants?.length > 0) {
                const varaiant = item?.variants?.map((variant) => {
                    variant.qty = 1;
                    return variant;
                });
            }
            return item;
        });
        setSelectProduct(items);
        //console.log('setSelectProduct(items)', items);
        if (items) {
            if (multiple) {
                setItem(value.map((o) => {
                    if (o.pid && o.vid && o.pid != o.vid) {
                        o._id = o.vid;
                    }
                    else {
                        o._id = o.pid;
                    }
                    return o;
                }));
            }
            else {
                let val = value;
                if (val.pid && val.vid && val.pid != val.vid) {
                    val._id = val.vid;
                    const _product = items.find(o => o._id.toString() == val.pid);
                    setSelectedProduct([_product.variants.find(o => o._id.toString() == val.vid)]);
                }
                else {
                    val._id = val.pid;
                    const _product = items.find(o => o._id.toString() == val.pid);
                    //console.log('selected_prodict', _product, val)
                    setSelectedProduct([_product]);
                }

                setItem([val]);
            }
        }
    }, [products]);

    const handleProductData = (props) => {
        dispatch(
            getAllProduct({
                search: props.search,
            })
        );
    };

    const handleClose = () => {
        setOpen(false);
        //onSelect();
        onClose();
    };

    const handleSave = () => {
        setOpen(false);
        if (!multiple) {

            if (Item[0].product) {
                onSelect({
                    pid: Item[0].product._id,
                    vid: Item[0]._id
                });
            }
            else {
                onSelect({
                    pid: Item[0]._id
                });
            }
        }
        else {
            let datas = Item.map((i) => {
                if (i.product) {
                    return {
                        pid: i.product._id,
                        vid: i._id
                    };
                }
                else {
                    return {
                        pid: i._id
                    };
                }
            });

            onSelect(datas);
        }
        setSelectedProduct(Item);
        //console.log('product', Item);
    };

    const handleTextSearch = _.debounce((event) => {
        handleProductData({
            search: {
                name: event,
            },
        });
    }, 500);

    const handleChange = (e, value) => {
        let newSelected = [];
        const selectedIndex = Item?.findIndex(o => o._id == value._id);
        // const selectedIndex = Item?.indexOf(value);

        if (multiple) {
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(Item, value);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(Item?.slice(1));
            } else if (selectedIndex === Item?.length - 1) {
                newSelected = newSelected.concat(Item?.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    Item?.slice(0, selectedIndex),
                    Item?.slice(selectedIndex + 1)
                );
            }
        }
        else {
            if (selectedIndex === -1) {
                newSelected.push(value);
            }
        }
        setItem(newSelected);
    };
    return (
        <>
            {/* Selector */}
            <Stack direction="row" spacing={1} style={{ marginTop: "20px" }}>
                <SearchStyle
                    style={{
                        width: "590px",
                        height: "40px",
                        borderRadius: "5px",
                    }}
                    //  fullWidth
                    placeholder={searchPlaceHolder}
                    value=""
                    onClick={() => setOpen(true)}
                    startAdornment={
                        <InputAdornment position="start">
                            <Box
                                component={Icon}
                                icon={searchFill}
                                sx={{ color: "text.disabled" }}
                            />
                        </InputAdornment>
                    }
                />
                <Button
                    variant="outlined"
                    style={{ borderRadius: "5px", borderColor: "lightgray" }}
                    onClick={() => setOpen(true)}>
                    <Typography style={{ fontWeight: "bold", color: "black" }}>
                        Browse
                    </Typography>
                </Button>



            </Stack>

            {/* Display Product */}
            <Stack>
                {selectedProduct?.map((val) => {
                    //const ProductSelected = selectProduct?.filter(o => o._id == val._id).length > 0;
                    if (val) {
                        return (
                            <TableRow
                                style={{
                                    borderBottom: "1px solid rgb(145 158 171 / 45%)"
                                }}
                                onClick={(e) => handleChange(e, val)}
                            >
                                <TableCell>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={2}
                                    // marginLeft="-90px"
                                    >
                                        <Avatar
                                            variant="square"
                                            alt={val?.title?.toUpperCase()}
                                            src={
                                                val?.images?.length > 0
                                                    ? val?.images?.[0].url
                                                    : "public/favicon/product.png"
                                            }
                                        />
                                        <Stack>
                                            {val?.product &&
                                                <Typography
                                                    style={{
                                                        maxWidth: "130px",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        fontSize: "13px",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {val?.product?.title}
                                                </Typography>
                                            }
                                            <Typography
                                                style={{
                                                    maxWidth: "130px",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    fontSize: "13px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {val?.title}
                                            </Typography>

                                        </Stack>
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <Typography style={{ fontWeight: "bold" }}>
                                        {store?.currency?.symbol}&nbsp;{val?.price}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )
                    }
                })
                }
            </Stack>

            {/* Product Selector */}
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {multiple ? "Select Products" : "Select a Product"}
                    <SearchStyle
                        style={{ marginTop: "10px" }}
                        fullWidth
                        placeholder="Search Products"
                        value={searchProduct}
                        onChange={(e) => (
                            setSearchProduct(e.target.value), handleTextSearch(e.target.value)
                        )}
                        startAdornment={
                            <InputAdornment position="start">
                                <Box
                                    component={Icon}
                                    icon={searchFill}
                                    sx={{ color: "text.disabled" }}
                                />
                            </InputAdornment>
                        }
                    />
                    {submittingPayload && <LinearProgress style={{ width: "100%" }} />}
                </DialogTitle>
                <DialogContent dividers>

                    <Table>
                        <TableBody>
                            {selectProduct?.map((value) => {
                                const ProductSelected = Item?.filter(o => o._id == value._id).length > 0;

                                return (
                                    <>
                                        <TableRow
                                            style={{
                                                borderBottom: "1px solid rgb(145 158 171 / 45%)"
                                            }}
                                            onClick={(e) => handleChange(e, value)}
                                        >
                                            <TableCell>
                                                {value?.variants?.length == 0 ? (
                                                    <Checkbox
                                                        // style={{ marginLeft: "-35px" }}
                                                        checked={ProductSelected}
                                                        onClick={(e) => handleChange(e, value)}
                                                    />
                                                ) : (
                                                    <></>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    spacing={2}
                                                // marginLeft="-90px"
                                                >
                                                    <Avatar
                                                        variant="square"
                                                        alt={value?.title.toUpperCase()}
                                                        src={
                                                            value?.images?.length > 0
                                                                ? value?.images?.[0].url
                                                                : "public/favicon/product.png"
                                                        }
                                                    />
                                                    <Stack>
                                                        <Typography
                                                            style={{
                                                                maxWidth: "150px",
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: "nowrap",
                                                                overflow: "hidden",
                                                                fontSize: "13px",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            {value?.title}
                                                        </Typography>
                                                        {/* <Alert style={{ marginTop: 10 }} variant="outlined" severity="warning">
                                      Quantity not tracked
                                      </Alert> */}
                                                    </Stack>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                {value?.variants?.length == 0 ? (
                                                    <Typography style={{ fontWeight: "bold" }}>
                                                        {store?.currency?.symbol} {value?.price}
                                                    </Typography>
                                                ) : (
                                                    <></>
                                                )}
                                            </TableCell>
                                        </TableRow>

                                        {value?.variants?.map((val) => {
                                            const VariantSelected = Item?.filter(o => o._id == val._id).length > 0

                                            return (
                                                <TableRow
                                                    style={{
                                                        borderBottom: "1px solid rgb(145 158 171 / 45%)",
                                                    }}
                                                    onClick={(e) => handleChange(e, val)}
                                                >
                                                    <TableCell >

                                                    </TableCell>
                                                    <TableCell >
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"

                                                            spacing={2}
                                                        // marginLeft="-60px"
                                                        >
                                                            <Checkbox
                                                                // style={{ marginLeft: 30 }}
                                                                checked={VariantSelected}
                                                                onClick={(e) => handleChange(e, val)}
                                                            />
                                                            <Typography
                                                                style={{
                                                                    maxWidth: "150px",
                                                                    textOverflow: "ellipsis",
                                                                    whiteSpace: "nowrap",
                                                                    overflow: "hidden",
                                                                    fontSize: "13px",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                {val?.title}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            style={{
                                                                maxWidth: "200px",
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: "nowrap",
                                                                overflow: "hidden",
                                                                fontWeight: "bold",
                                                                marginRight: "-77px",
                                                            }}
                                                        >
                                                            {store?.currency?.symbol} {val?.price}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </>
                                );
                            })}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Stack direction="row" justifyContent="space-between" style={{ width: "100%" }}>
                        <Typography
                            style={{
                                fontSize: "13px",
                                fontWeight: "bold",
                                marginTop: "10px",
                            }}
                        >
                            {Item.length} Items selcted
                        </Typography>
                        <Stack direction="row" spacing={2}>
                            <Button onClick={handleClose} variant="outlined" color="error">
                                Cancel
                            </Button>
                            <Button onClick={handleSave} variant="outlined" disabled={!Item.length > 0} >
                                Done
                            </Button>
                        </Stack>
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    );
}
