import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";

CreateReturnTimeline.propTypes = {
  Return: PropTypes.object,
};
export default function CreateReturnTimeline(props) {
  const { Return } = props;
  return (
    <>
      <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
        Items and return reason
      </Typography>
      {Return?.items?.map((value) => {
        return (
          <Typography variant="body2">
            {value?.qty} x{" "}
            {value?.variant?.title
              ? value?.product?.title + " " + "/" + " " + value?.variant?.title
              : value?.product?.title}{" "}
            - {value?.reason?.name}
          </Typography>
        );
      })}
    </>
  );
}
