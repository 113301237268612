import React, { useEffect, useState } from "react";
import Page from "src/components/Page";
import {
  Grid,
  Stack,
  Button,
  Typography,
  Divider,
  Card,
  Link,
  CardHeader,
  useTheme,
  Alert,
  AlertTitle,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Container,
  Chip,
} from "@material-ui/core";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
  Cancel,
  Close,
  Done,
  ErrorOutline,
  VerifiedUser,
} from "@material-ui/icons";
import {
  VerifyDomain,
  activationDomain,
  getDomain,
} from "src/redux/actions/DomainAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { PATH_DASHBOARD } from "src/routes/paths";
import { LoadingButton } from "@material-ui/lab";
import Label from "src/components/Label";
export default function DomainDetail() {
  const { _id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [validateARecord, setValidateARecord] = useState(false);
  const [currentIP, setCurrentIP] = useState("");
  const [currentValue, setCurrentValue] = useState("");
  const [validateCname, setValidateCname] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const domain = useSelector((state) => state.domain.getdomainPayload);
  const verifyData = {
    aName: "@",
    IPaddress: "5.161.51.94",
    CNAME: "www",
    required_value: "shops.myshopify.com",
  };
  useEffect(() => {
    _id && dispatch(getDomain(_id));
  }, []);
  const verifyConnection = (domain) => {
    dispatch(
      VerifyDomain(
        {
          id: _id,
          IPaddress: verifyData?.IPaddress,
          value: verifyData?.required_value,
          domain: domain,
        },
        (data) => {
          setData(data);
        },
        setLoading
      )
    );
  };
  useEffect(() => {
    if (data) {
      const aRecord = data.aRecord.find((op) => {
        if (op == verifyData.IPaddress) {
          setValidateARecord(true);
          setCurrentIP(op);
        }
      });
      const cname = data.cname.find((op) => {
        if (op == verifyData.required_value) {
          setValidateCname(true);
          setCurrentValue(op);
        }
      });

      !aRecord && setCurrentIP(data.aRecord[0]);
      !cname && setCurrentValue(data.cname[0]);
    }
  }, [data, verifyData]);
  const handleCopy = async (value) => {
    await navigator.clipboard.writeText(value);
    toast.success("Copied");
  };
  return (
    <>
      <Page title="Domain: Verification | Planet Shopping">
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} mb={5}>
              <Stack direction="row" spacing={2} sx={{ marginBottom: "-50px" }}>
                <Button
                  variant="outlined"
                  style={{
                    marginBottom: "16px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    minHeight: "42px",
                    marginTop: "0px",
                    minWidth: "10px",
                  }}
                  component={RouterLink}
                  to={PATH_DASHBOARD.setting.domains}
                >
                  <img
                    width="17px"
                    src="https://th.bing.com/th/id/R.9562e3998adf90b7ea409f041aab337d?rik=ERFU3YLhocBkdA&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2fKin%2feBp%2fKineBpprT.png&ehk=t39BBIm39HbdTD3%2bBxa%2bIfTm948yrYikFKGQivAW9UU%3d&risl=&pid=ImgRaw&r=0"
                  />
                </Button>
                <Typography variant="h4">Verify Domain</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12}>
              <Card sx={{ p: 3 }}>
                <Stack mb={2}>
                  <Grid xs={12}>
                    <Typography
                      style={{ marginBottom: "-31px", marginTop: "10px" }}
                    >
                      {domain?.domain?.title}
                    </Typography>

                    <LoadingButton
                      variant="outlined"
                      loading={loading}
                      style={{
                        float: "right",
                        marginLeft: "20px",
                      }}
                      onClick={() => verifyConnection(domain?.domain?.title)}
                    >
                      Verify Connection
                    </LoadingButton>

                    <Label
                      style={{
                        float: "right",
                        marginTop: "10px",
                        marginLeft: "20px",
                      }}
                      color={domain?.status == "active" ? "success" : "warning"}
                    >
                      {domain?.status}
                    </Label>
                  </Grid>
                </Stack>
              </Card>
              <Card sx={{ p: 3, mt: 3 }}>
                <CardHeader
                  style={{ padding: "5px" }}
                  title="Connecting your domain"
                />
                <Typography>
                  <b>A and CNAME entered incorrectly</b>
                  <br></br>
                  Check that you've entered the required values, and verify your
                  connection again.
                </Typography>

                <Stack sx={{ mt: 3 }}>
                  <Typography>
                    <b>
                      A record <ErrorOutline color={"warning"} />
                    </b>
                  </Typography>

                  <Typography>
                    Name : @{" "}
                    <Link
                      style={{
                        fontSize: "14px",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => handleCopy("@")}
                    >
                      Copy
                    </Link>
                  </Typography>

                  <Typography>
                    Current IP address :{" "}
                    {domain?.status == "active" ? (
                      <>
                        <span>
                          {domain?.domain?.status == "active"
                            ? verifyData?.IPaddress
                            : "[Epmty]"}
                        </span>
                        <Label
                          style={{
                            float: "right",
                            padding: "17px",
                            marginLeft: "20px",
                          }}
                          color={
                            domain?.domain?.status == "active"
                              ? "success"
                              : "error"
                          }
                        >
                          {domain?.domain?.status == "active" ? (
                            <>
                              <VerifiedUser /> Verified
                            </>
                          ) : (
                            <>
                              <Cancel /> Unverified
                            </>
                          )}
                        </Label>
                      </>
                    ) : currentIP ? (
                      <>
                        <span style={{ color: !validateARecord && "red" }}>
                          {currentIP}
                        </span>
                        <Label
                          style={{
                            float: "right",
                            padding: "17px",
                            marginLeft: "20px",
                          }}
                          color={validateARecord ? "success" : "error"}
                        >
                          {validateARecord ? (
                            <>
                              <VerifiedUser /> Verified
                            </>
                          ) : (
                            <>
                              <Cancel /> Unverified
                            </>
                          )}
                        </Label>
                      </>
                    ) : (
                      "[Empty]"
                    )}
                  </Typography>

                  <Typography>
                    Requried value : {verifyData?.IPaddress}{" "}
                    <Link
                      style={{
                        fontSize: "14px",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => handleCopy(verifyData?.IPaddress)}
                    >
                      Copy
                    </Link>
                  </Typography>
                </Stack>

                <Stack sx={{ mt: 3 }}>
                  <Typography>
                    <b>
                      CNAME <ErrorOutline color={"warning"} />
                    </b>
                  </Typography>
                  <Typography>
                    Name : www{" "}
                    <Link
                      style={{
                        fontSize: "14px",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => handleCopy("www")}
                    >
                      Copy
                    </Link>
                  </Typography>
                  <Typography>
                    Current value :{" "}
                    {domain?.status == "active" ? (
                      <>
                        <span>
                          {domain?.wwwDomain?.status == "active"
                            ? verifyData?.required_value
                            : "[Epmty]"}
                        </span>
                        <Label
                          style={{
                            float: "right",
                            padding: "17px",
                            marginLeft: "20px",
                          }}
                          color={
                            domain?.wwwDomain?.status == "active"
                              ? "success"
                              : "error"
                          }
                        >
                          {domain?.wwwDomain?.status == "active" ? (
                            <>
                              <VerifiedUser /> Verified
                            </>
                          ) : (
                            <>
                              <Cancel /> Unverified
                            </>
                          )}
                        </Label>
                      </>
                    ) : currentValue ? (
                      <>
                        <span style={{ color: !validateCname && "red" }}>
                          {currentValue}
                        </span>
                        <Label
                          style={{
                            float: "right",
                            padding: "17px",
                            marginLeft: "20px",
                          }}
                          color={validateCname ? "success" : "error"}
                        >
                          {validateCname ? (
                            <>
                              <VerifiedUser /> Verified
                            </>
                          ) : (
                            <>
                              <Cancel /> Unverified
                            </>
                          )}
                        </Label>
                      </>
                    ) : (
                      "[Empty]"
                    )}
                  </Typography>

                  <Typography>
                    Requried value : {verifyData?.required_value}{" "}
                    <Link
                      style={{
                        fontSize: "14px",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => handleCopy(verifyData?.required_value)}
                    >
                      Copy
                    </Link>
                  </Typography>
                </Stack>

                <Alert severity="warning" variant="outlined" sx={{ mt: 3 }}>
                  <AlertTitle>Domain connection incomplete</AlertTitle>
                  Some providers take a few minutes to update setings. You can
                  check leter for connection updates, or you can try again.{" "}
                  <br />
                  <Button
                    variant="outlined"
                    style={{ float: "left", marginTop: "18px" }}
                    onClick={() =>
                      navigate(`${PATH_DASHBOARD.setting.edit_domain}/${domain._id}`)
                    }
                  >
                    Change Domain
                  </Button>
                </Alert>

                <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />

                <Typography>
                  Follow the{" "}
                  <Link
                    onClick={() => setOpen(true)}
                    style={{ cursor: "pointer" }}
                  >
                    step-by-step instructions
                  </Link>{" "}
                  and verify your connection again.
                </Typography>
                {validateARecord && (
                  <LoadingButton
                    variant="outlined"
                    loading={loading}
                    style={{
                      float: "right",
                      marginLeft: "20px",
                    }}
                    onClick={() => (
                      dispatch(activationDomain(_id, setLoading)),
                      navigate(`${PATH_DASHBOARD.setting.domains}`)
                    )}
                  >
                    Continue
                  </LoadingButton>
                )}
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Page>

      <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          fullWidth
        >
          <DialogTitle>
            {"Instruction"}
            <IconButton
              aria-label="close"
              onClick={() => setOpen(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 10,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <Divider style={{ marginTop: 10 }} />
          <DialogContent>
            <Stack>
              <h3>
                Change your DNS records in your third-party domain provider
                account
              </h3>
            </Stack>
            <Stack
              style={{
                fontSize: "13px",
                marginLeft: "20px",
                marginTop: "10px",
                color: "grey",
              }}
            >
              <ol>
                <li>
                  On your domain provider's website, log in to your account.
                </li>
                <li>Find the DNS settings for domain management area.</li>
                <li>Change the following records:</li>
                <ol style={{ marginLeft: "20px" }}>
                  <li>
                    Point the <b>A</b> record to the Shopify IP address{" "}
                    <b>'23.227.38.65'</b>
                  </li>
                  <li>
                    If necessary, change the <b>Host</b> name to the <b>'@'</b>{" "}
                    symbol.
                  </li>
                  <li>
                    Delete any other a record to the domain if there are any
                    present.
                  </li>
                  <li>
                    Point the <b>CNAME</b> record with the name <b>www</b> to{" "}
                    <b>'shops.shopify.com'</b>. Be sure to include the period at
                    the end of the domain.
                  </li>
                </ol>
                <li>Save your changes.</li>
              </ol>
            </Stack>
            <Alert variant="outlined" severity="info" sx={{ mt: 2 }}>
              <AlertTitle>Note</AlertTitle>
              you don't need to chnage the <b>TTL</b> number in your DNS
              settings. Use the default value.
            </Alert>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button variant="outlined" onClick={() => setOpen(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
    </>
  );
}
