import moment from "moment";
import slugify from "react-slugify";
import { toast } from "react-toastify";
import ApiExt from "../Provider/ApiExt";

// Get all gift cards
export function geAlltGiftCards(values) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });
    ApiExt.post("/api/gift-card/get-all", values)
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_ALL_GIFTCARD",
            payload: response.data.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Get single gift card
export function getSingleGiftCard(id) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });
    ApiExt.post("/api/gift-card/get", { giftcardId: id })
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_GIFTCARD_SINGLE",
            payload: response.data.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Create GiftCard
export function saveGiftCard(values,seo, media, variant, option, vendor,
  proType, setLoading,callback) {
  return async function (dispatch) {
    setLoading(true);

    const data = {
      title: values.title,
      desc: values.desc,
      category: "Gift Card",
      ptype: {
        id: proType._id || "",
        name: proType.title
      },
      vendor: {
        id: vendor._id || "",
        name: vendor.name
      },
      collections: values.collections?.map((item) => item._id),
      variants: variant?.map((item) => {
        return {
          isdefault: item?.isdefault || false,
          option: item?.option || [],
          title: item?.title || values.title,
          desc: item?.desc || values?.desc,
          images: values?.images?.map((img) => img._id) || [],
          price: +item?.title || 0 ,
          comPrice: item?.comPrice || 0 ,
          cost: item?.vcost || 0 ,
          margin: ((item?.price - item?.cost) * 100) / item?.price || 0,
          profit: item?.price - item?.cost || 0,
          sku: item?.sku || "",
          barcode: item?.barcode || "",
          isTrackQty: item?.isTrackQty || false,
          sellAtNill:item?.sellAtNill || false,
          physical: item?.physical || false,
          weight: {
            value: item?.weight?.value || "",
            unit: item?.weight?.unit || "",
          },
          taxid: item?.taxid || "",
          origin: item?.origin || "",
          status: item?.status || values.status,
        };

      }),
      option: option?.map((item) => {
        return {
          opName: item.opName,
          opVal: item.opVal,
        };
      }),
      images: media?.map((item) => item._id) || [],

      seo: {
        title: seo?.title || values?.title,
        desc: seo?.desc || values?.desc,
        slug: (slugify(seo?.slug || values?.title)),
      },
      tags: values.tags,
      status: values.status,
    };
console.log("data",data);

    ApiExt.post("/api/gift-card/insert", data)
      .then(function (response) {
        // console.log('Create GiftCard Action=================', response.data);
        setLoading(false);
        if (response.data.status === true) {
          toast.success(response?.data?.msg);
          callback(response.data?.id)
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Update GiftCard
export function updateGiftCard(_id, values, action, navigate) {
  return async function (dispatch) {
    action.setSubmitting(true);

    const data = {
      collId: _id,
      data: {
        pid: "6352a7310ad3194b68696ced",
        data: {
          title: "gift card123",
          desc: "King",
          category: "Gift Card",
          ptype: "6114c3fd1aac54792e7f2fb2",
          vendor: "6114c3fd1aac54792e7f2fb2",
          collections: [],
          variants: [
            {
              isdefault: true,
              option: [
                {
                  op: "Size",
                  val: "XL",
                },
                {
                  op: "Color",
                  val: "Red",
                },
                {
                  op: "Brand",
                  val: "Oxi",
                },
              ],
              title: "XL/Red/Oxi",
              desc: "There are many variations of passages of Lorem Ipsum available, but the majority have be suffered alteration in some form",
              images: [""],
              price: 1500,
              comPrice: 2000,
              cost: "",
              margin: "",
              profit: "",
              sku: "",
              barcode: "",
              isTrackQty: false,
              sellAtNill: false,
              physical: false,
              weight: {
                value: 0.0,
                unit: "kg",
              },
              taxid: "6114c3fd1aac54792e7f2fb2",
              origin: "",
              status: "archive",
            },
            {
              isdefault: false,
              option: [
                {
                  op: "Color",
                  val: "Red",
                },
              ],
              title: "Red",
              desc: "There are many variations of passages of Lorem Ipsum available, but the majority have be suffered alteration in some form",
              images: [""],
              price: 1500,
              comPrice: 2000,
              cost: "",
              margin: "",
              profit: "",
              sku: "",
              barcode: "",
              isTrackQty: false,
              sellAtNill: false,
              physical: false,
              weight: {
                value: 0.0,
                unit: "kg",
              },
              taxid: "6114c3fd1aac54792e7f2fb2",
              origin: "",
              status: "archive",
            },
          ],
          images: [],

          seo: {
            title: "Smriti",
            desc: "She is a girl",
            slug: "Maharashtra",
          },
          status: "active",
        },
      },
    };


    ApiExt.post("/api/gift-card/update", data)
      .then(function (response) {
        // console.log('Create GiftCard Action=================', response.data);
        action.setSubmitting(false);
        if (response.data.status === true) {
          action.resetForm();
          toast.success(response?.data?.msg);
          navigate("/dashboard/giftcard/list");
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        action.setSubmitting(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// // Delete GiftCard
// export const deleteGiftCard = (_id, lastId) => {
//   return async function (dispatch) {
//     ApiExt.post("api/giftcard/delete", { collId: _id })
//       .then(function (response) {
//         // console.log('Delete GiftCard Action=================', response.data);

//         if (response.data.status === true) {
//         //   dispatch(
//         //     getGiftCards({
//         //       next: "",
//         //       limit: 10,
//         //     })
//         //   );
//           toast.success(response.data.msg);
//         } else {
//           toast.warn(response.data.msg);
//         }
//       })
//       .catch((error) => {
//         console.log(error.response);
//         toast.error("Something went wrong. Please try again.");
//       });
//   };
// };
