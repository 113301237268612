import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import Legal from "./reducers/SettingLegalReducer";
import mailReducer from "./slices/mail";
import chatReducer from "./slices/chat";
import blogReducer from "./slices/blog";
import userReducer from "./slices/user";
import productReducer from "./slices/product";
import calendarReducer from "./slices/calendar";
import kanbanReducer from "./slices/kanban";
import AuthReducer from "./reducers/AuthReducer";
import ProductReducer from "./reducers/ProductReducer";
import VendorReducer from "./reducers/VendorReducer";
import LocationReducer from "./reducers/LocationReducer";
import CountryReducer from "./reducers/CountryReducer";
import CollectionReducer from "./reducers/CollectionReducer";
import ImageReducer from "./reducers/ImageReducer";
import MessageReducer from "./reducers/MessageReducer";
import LoadingReducer from "./reducers/LoadingReducer";
import InventoryReducer from "./reducers/InventoryReducer";
import UserAndPermission from "./reducers/UserAndPermissonReducer";
import LegalLoad from "./reducers/legalReducer";
import ImageUploadLoadingReducer from "./reducers/ImageUploadLoadingReducer";
import FileUploadReducer from "./reducers/FileUploadReducer";
import StoreReducer from "./reducers/StoreReducer";
import DomainReducer from "./reducers/DomainReducer";
import CustomerReducer from "./reducers/CustomerReducer";
import OrderReducer from "./reducers/OrderReducer";
import AddressReducer from "./reducers/AddressReducer";
import FulfillReducer from "./reducers/FulfillReducer";
import ReturnReducer from "./reducers/ReturnReducer";
import RefundReducer from "./reducers/RefundReducer";
import StoreBlogReducer from "./reducers/StoreBlogReducer";
import StorePageReducer from "./reducers/StorePageReducer";
import CheckoutReducer from "./reducers/CheckoutReducer";
import IssueGiftCardReducer from "./reducers/IssueGiftCardReducer";
import GiftCardReducer from "./reducers/GiftCardReducer";
import TransferReducer from "./reducers/TransferReducer";
import BillingReducer from "./reducers/BillingReducer";
import PreferenceReducer from "./reducers/PreferenceReducer";
import DiscountReducer from "./reducers/DiscountReducer";
import NavigationReducer from "./reducers/NavigationReducer";
import NotificationReducer from "./reducers/NotificationReducer";
import SettingPaymentReducer from "./reducers/SettingPaymentReducer";
import Dashboard from "./reducers/Dashboard";
import TimelineReducer from "./reducers/TimelineReducer";
import ShippingReducer from "./reducers/ShippingReducer";
import TaxReducer from "./reducers/TaxReducer";
import AnalyticsReducer from "./reducers/AnalyticsReducer";
// import ExportReducer from "./reducers/ExportReducer";




// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const productPersistConfig = {
  key: "product",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "checkout"],
};

const appReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: userReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  //Custom reducers
  loadinglegaldata: LegalLoad,
  legal: Legal,
  loader: LoadingReducer,
  messages: MessageReducer,
  auth: AuthReducer,
  products: ProductReducer,
  customers: CustomerReducer,
  addresses: AddressReducer,
  vendors: VendorReducer,
  locations: LocationReducer,
  countries: CountryReducer,
  collections: CollectionReducer,
  images: ImageReducer,
  inventories: InventoryReducer,
  userandpermission: UserAndPermission,
  imageLoader: ImageUploadLoadingReducer,
  image: FileUploadReducer,
  orders: OrderReducer,
  store: StoreReducer,
  domain: DomainReducer,
  Fulfill: FulfillReducer,
  Return: ReturnReducer,
  Refund: RefundReducer,
  storeblog: StoreBlogReducer,
  storepage: StorePageReducer,
  checkout: CheckoutReducer,
  giftcard: GiftCardReducer,
  issueGift: IssueGiftCardReducer,
  transfer: TransferReducer,
  billing: BillingReducer,
  preference: PreferenceReducer,
  discount: DiscountReducer,
  notifications: NotificationReducer,
  navigation: NavigationReducer,
  payments: SettingPaymentReducer,
  dashboard: Dashboard,
  timeLine: TimelineReducer,
  shipping: ShippingReducer,
  tax: TaxReducer,
  // exportxl : ExportReducer
  analytics: AnalyticsReducer

});

const rootReducer = (state, action) => {
  if (action.type === "RESET_STATE") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export { rootPersistConfig, rootReducer };
