const initialState = { vendorPayload: [] };

export default function (state = initialState, action) {
  switch (action.type) {
    case "GET_VENDOR": {
      return {
        ...state,
        vendorPayload: action.payload,
      };
    }

    case "GET_SINGLE_VENDOR": {
      return {
        ...state,
        singleVendorPayload: action.payload,
      };
    }

     case "DELETE_VENDOR": {
       return {
         ...state,
         singleVendorPayload: {
          ...state.singleVendorPayload.filter((x) => x._id != action.payload)?.map((value) => {
             return value
           }),
         },
       };
     }

    case "UNARCHIVED_VENDOR": {
      return {
        ...state,
        singleVendorPayload: {
          ...state.singleVendorPayload,
          status: "draft",
        },
      };
    }

    case "ARCHIVED_VENDOR": {
      return {
        ...state,
        singleVendorPayload: {
          ...state.singleVendorPayload,
          status: "archive",
        },
      };
    }
    default:
      return state;
  }
}
