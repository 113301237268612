import {
  Container,
  Stack,
  Button,
  Typography,
  Grid,
  Card,
  InputAdornment,
  Box,
  OutlinedInput,
  TextField,
  Divider,
  Autocomplete,
  Avatar,
  ButtonGroup,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Popper,
  MenuList,
  ListItemText,
  MenuItem,
  linearProgressClasses,
  Chip,
  TableHead,
  Alert,
  AlertTitle,
  Link,
} from "@material-ui/core";
import _ from "lodash";
import Close from "@material-ui/icons/Close";
import React from "react";
import Page from "src/components/Page";
import { PATH_DASHBOARD } from "src/routes/paths";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import { makeStyles } from "@material-ui/core/styles";
import ListHead from "../../../components/_dashboard/ListTable/ListHead";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
  ArrowDropDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@material-ui/icons";
import {
  useTheme,
  experimentalStyled as styled,
} from "@material-ui/core/styles";
import ColorSinglePicker from "src/components/ColorSinglePicker";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getAllProduct, getLocation } from "src/redux/actions";
import { useDispatch } from "react-redux";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import {
  DelTransfer,
  getSingleTransfer,
  insertTransfer,
  updateTransfer,
} from "src/redux/actions/TransferAction";
import { getShippingCarrier } from "src/redux/actions/FulfillAction";
import { LoadingButton } from "@material-ui/lab";
import moment from "moment";
import TimeLine from "src/components/_dashboard/timeLine/TimeLine";
import Label from "src/components/Label";
import { getTimeLine } from "src/redux/actions/TimelineAction";
import ProductSelector from "src/components/_dashboard/products/ProductSelector";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  // width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#c4140e" : "#308fe8",
  },
}));

export default function CreateTransfer() {
  const { _id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //---------------------------------------------------------------------------------------
  // UseSelector
  const isLoading = useSelector((state) => state.loader.loadingPayload);
  const products = useSelector((state) => state.products.allProductPayload);
  const store = useSelector((state) => state.auth.profilePayload);
  const locationData = useSelector((state) => state.locations.locationPayload);
  const singleTransferData = useSelector(
    (state) => state.transfer.singleTransferPayload
  );
  const carrier = useSelector((state) => state.Fulfill.carrierPayload);
  const timeLine = useSelector((state) => state.timeLine.timelinePayload);
  //---------------------------------------------------------------------------------------
  // UseState
  const [product, setProduct] = useState(false);
  const [Item, setItem] = useState([]);
  const [saveItem, setSaveItem] = useState(Item);
  const [selectProduct, setSelectProduct] = useState([]);
  const [searchLocation, setSearchLocation] = useState("");
  const [fixedDate, setFixedDate] = useState();
  const [deleteTransfer, setDeleteTransfer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [addOrigin, setAddOrigin] = useState("");
  const [addDestination, setAddDestination] = useState("");
  const [tracking, setTracking] = useState();
  const [transferProduct, setTransferProduct] = useState([]);

  const [error, setError] = useState(false);
  //---------------------------------------------------------------------------------------
  // UseEffect
  useEffect(() => {
    // dispatch(
    //   getAllProduct({
    //     search: {
    //       name: "",
    //     },
    //   })
    // );
    dispatch(getLocation());
    dispatch(getShippingCarrier());
  }, []);

  useEffect(() => {
    _id && dispatch(getSingleTransfer(_id));
    if (!_id) {
      dispatch({
        type: "GET_SINGLE_TRANSFER",
        payload: {},
      });
    }
    handleTimeline({
      id: _id,
      type: "transfer",
      next: "",
      prev: "",
      limit: 10,
    });
  }, [_id]);

  useEffect(() => {
    if (singleTransferData?._id) {
      const items = singleTransferData?.items?.map((item) => {
        return item;
      });
      setTransferProduct(items);
    } else {
      setTransferProduct([]);
    }

    if (singleTransferData?._id) {
      setAddOrigin(singleTransferData?.origin?.address);
      setAddDestination(singleTransferData?.destination?.address);
    } else {
      setAddOrigin({});
      setAddDestination({});
    }
  }, [singleTransferData]);

  useEffect(() => {
    const items = products?.map((item) => {
      item.qty = 1;
      if (item?.variants?.length > 0) {
        const varaiant = item?.variants?.map((variant) => {
          variant.qty = 1;
          return variant;
        });
      }
      return item;
    });
    setSelectProduct(items);
  }, [products]);

  //---------------------------------------------------------------------------------------
  // Events
  const getId = timeLine?.map((t) => {
    const lastId = t?.items?.[8];
    const firstId = t?.items[0];
    const items = t?.items?.length;
    const index = t?.items?.map((items, index) => {
      if (index == 0) {
        var i = index;
      }
      return i;
    });
    return { lastId, firstId, index, items };
  });
  const [isPrevious, setIsPrevious] = useState(false);
  const handleChangeTimeline = (Pagination) => {
    if (Pagination == "next") {
      handleTimeline({
        id: _id,
        type: "transfer",
        next: getId[0]?.lastId?._id,
        prev: "",
        limit: 10,
      });
      setIsPrevious(true);
    } else {
      handleTimeline({
        id: _id,
        type: "transfer",
        next: getId[0]?.firstId?._id,
        prev: "",
        limit: 10,
      });
    }
  };

  const handleTimeline = (props) => {
    _id &&
      dispatch(
        getTimeLine({
          id: props.id,
          type: props.type,
          next: props.next,
          prev: props.prev,
          limit: props.limit,
        })
      );
  };

  const handleSearchLocation = _.debounce((event) => {
    dispatch(getLocation(event));
  }, 500);

  const matchLocation = (value, data) => {
    if (value == "Origin") {
      if (addDestination?._id) {
        if (data?._id == addDestination?._id) {
          setError(true);
        } else {
          setError(false);
        }
      }
    } else {
      if (addOrigin?._id) {
        if (addOrigin?._id == data?._id) {
          setError(true);
        } else {
          setError(false);
        }
      }
    }
  };

  // const handleChange = (e, value) => {
  //   const selectedIndex = Item?.indexOf(value);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(Item, value);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(Item?.slice(1));
  //   } else if (selectedIndex === Item?.length - 1) {
  //     newSelected = newSelected.concat(Item?.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       Item?.slice(0, selectedIndex),
  //       Item?.slice(selectedIndex + 1)
  //     );
  //   }
  //   setItem(newSelected);
  // };

  const handleSave = (selected) => {
    setProduct(false);
    let filter = [];

    if (transferProduct.length > 0) {
      selected.map((val) => {
        const pId = val?.pid ? val?.pid : val?._id;
        const find = transferProduct.find((item) => {
          return pId == item?.product?._id;
        });
        if (!find) {
          filter.push(val);
        }
      });
    } else {
      filter = selected;
    }

    filter.map((value) => {
      setTransferProduct((oldArray) => [
        ...oldArray,
        {
          product: {
            _id: value.pid ? value?.pid : value?._id,
            title: value.pid ? value?.product?.title : value?.title,
            images: value?.images || [],
          },
          variant: {
            _id: value.pid ? value?._id || value?.vid : "",
            title: value.pid ? value?.title || value?.variant?.title : "",
            images: value?.images || [],
          },
          qty: 1,
        },
      ]);
    });
  };

  const acceptPercentage = (total, value) => {
    return (value / total) * 100;
  };

  const rejectPercentage = (total, acptvalue, rejctvalue) => {
    return (acptvalue / total) * 100 + (rejctvalue / total) * 100;
  };

  const handleRemove = (value) => {
    if (value?.pid) {
      let val = transferProduct.map((item) => {
        if (value?.vid) {
          if (item?.vid == value?.vid && item?.pid == value?.pid) {
            return {
              ...item,
              qty: 0,
            };
          }
          return item;
        } else {
          if (item?.pid == value?.pid) {
            return {
              ...item,
              qty: 0,
            };
          }
          return item;
        }
      });
      setTransferProduct(val);
    } else {
      setTransferProduct(
        transferProduct.filter((item) => {
          if (value?.variant?._id) {
            return value?.variant?._id != item?.variant?._id;
          } else {
            return value?.product?._id != item?.product?._id;
          }
        })
      );
    }

    setItem(
      Item.filter((item) => {
        if (value?.variant?._id) {
          return value?.variant?._id != item?._id;
        } else {
          return value?.product?._id != item?._id;
        }
      })
    );

    // const val = saveItem.filter((item) => item._id !== id);
    // setSaveItem(saveItem.filter((item) => item._id !== id));
    // setItem(Item.filter((item) => item._id !== id));
  };

  const handleDecrement = (item) => {
    // let val = saveItem.map((value) => {
    //   if (value._id == item._id && value.qty > 1) {
    //     return {
    //       ...value,
    //       qty: value.qty - 1,
    //     };
    //   }
    //   return value;
    // });
    // setSaveItem(val);
    let val = transferProduct.map((value) => {
      if (item?.variant?._id != "") {
        if (
          value?.variant?._id == item?.variant?._id &&
          value?.product?._id == item?.product?._id &&
          value?.qty > 1
        ) {
          return {
            ...value,
            qty: value.qty - 1,
          };
        }
        return value;
      } else {
        if (value?.product?._id == item?.product?._id && value?.qty > 1) {
          return {
            ...value,
            qty: value.qty - 1,
          };
        }
        return value;
      }
    });
    setTransferProduct(val);
  };

  const handleIncrement = (item) => {
    let val = transferProduct.map((value) => {
      if (item?.variant?._id != "") {
        if (
          value?.variant?._id == item?.variant?._id &&
          value?.product?._id == item?.product?._id
        ) {
          return {
            ...value,
            qty: value.qty + 1,
          };
        }
        return value;
      } else {
        if (value?.product?._id == item?.product?._id) {
          return {
            ...value,
            qty: value.qty + 1,
          };
        }
        return value;
      }
    });
    setTransferProduct(val);
  };
  //---------------------------------------------------------------------------------------
  // Formik
  const NewUserSchema = Yup.object().shape({
    // delMethod: Yup.string().required("delMethod is require"),
    // dueDate: Yup.string().required("Due Date is require"),
    origin: Yup.object().shape({
      _id: Yup.string().required("Origin is required"),
    }),
    destination: Yup.object().shape({
      _id: Yup.string().required("Destination is required"),
    }),
    trackingNo: Yup.string().max(50),
    ref: Yup.string().max(50, "Reference Number maximum 50 characters"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      origin: addOrigin || {},
      destination: addDestination || {},
      arrival:
        moment(singleTransferData?.arrival).format("YYYY-MM-DD") ||
        moment(new Date()).format("YYYY-MM-DD"),
      trackingNo: singleTransferData?.tracking?.number || "",
      shipCarrier: singleTransferData?.tracking?.carrier?._id || "",
      url: singleTransferData?.tracking?.url || "",
      ref: singleTransferData?.ref || "",
      tags: singleTransferData?.tags || [],
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, actions) => {
      try {
        dispatch(
          _id
            ? updateTransfer(_id, values, transferProduct, setLoading, () => {
                navigate(PATH_DASHBOARD.products.transfers);
              })
            : insertTransfer(
                values,
                transferProduct,

                (id) => {
                  navigate(`${PATH_DASHBOARD.products.editTransfer}/${id}`);
                },
                setLoading
              )
        );
      } catch (error) {
        actions.setSubmitting(false);
        actions.setErrors(error);
      }
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik;
  //---------------------------------------------------------------------------------------

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off">
        <Page>
          <Container>
            {isLoading && <LinearProgress style={{ width: "100%" }} />}
            {!isLoading && (
              <>
                {/* Heading */}
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="outlined"
                    style={{
                      marginBottom: "16px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      minHeight: "42px",
                      minWidth: "10px",
                    }}
                    component={RouterLink}
                    to={PATH_DASHBOARD.products.transfers}
                  >
                    <img
                      width="17px"
                      src="https://th.bing.com/th/id/R.9562e3998adf90b7ea409f041aab337d?rik=ERFU3YLhocBkdA&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2fKin%2feBp%2fKineBpprT.png&ehk=t39BBIm39HbdTD3%2bBxa%2bIfTm948yrYikFKGQivAW9UU%3d&risl=&pid=ImgRaw&r=0"
                    />
                  </Button>
                  {_id ? (
                    <Stack direction="row" spacing={2}>
                      <Typography variant="h5" style={{ marginTop: 5 }}>
                        #{singleTransferData?.transferNo}
                      </Typography>
                      <Label
                        style={{ marginTop: 7, height: "25px" }}
                        color={
                          singleTransferData?.status == "Partially received"
                            ? "info"
                            : singleTransferData?.status == "Pending"
                            ? "error"
                            : "default"
                        }
                      >
                        {singleTransferData?.status}
                      </Label>
                    </Stack>
                  ) : (
                    <Typography variant="h5" style={{ marginTop: 5 }}>
                      Create inventory transfer
                    </Typography>
                  )}
                </Stack>
                {_id && (
                  <Button
                    variant="contained"
                    style={{ float: "right", marginTop: "-57px" }}
                    component={RouterLink}
                    to={`${PATH_DASHBOARD.products.recvInventory}/${
                      singleTransferData?._id
                    }/${"recvInventory"}`}
                  >
                    Receive inventory
                  </Button>
                )}
                <Grid container spacing={3} sx={{ mb: 3 }}>
                  {/* Origin/Destination */}
                  <Grid item xs={12} md={12}>
                    <Card sx={{ p: 3 }}>
                      <Stack direction="row" justifyContent={"space-around"}>
                        <Stack style={{ flex: 1 }}>
                          <Typography
                            variant="h6"
                            style={{
                              fontSize: "12px",
                              textDecoration: "underline",
                              marginBottom: 10,
                            }}
                          >
                            ORIGIN
                          </Typography>
                          {!_id ? (
                            <>
                              <Autocomplete
                                options={locationData}
                                freeSolo
                                getOptionLabel={(option) => option?.name}
                                // fullWidth
                                style={{ marginTop: 10, width: 400 }}
                                onChange={(event, newValue) => {
                                  console.log("origin", newValue);

                                  setFieldValue("origin", newValue);
                                  setAddOrigin(newValue);
                                  matchLocation("Origin", newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Origin"
                                    variant="outlined"
                                    error={
                                      errors.origin &&
                                      touched.origin && (
                                        <p>{errors.origin._id}</p>
                                      )
                                    }
                                    helperText={
                                      errors.origin &&
                                      touched.origin && (
                                        <p>{errors.origin._id}</p>
                                      )
                                    }
                                  />
                                )}
                              />
                            </>
                          ) : (
                            <Typography variant="h6">
                              {addOrigin?.name}
                            </Typography>
                          )}
                          <Typography variant="body2">
                            {addOrigin?.address
                              ? addOrigin?.address
                              : singleTransferData?.origin?.address
                                  ?.address}{" "}
                            {addOrigin?.apertment
                              ? addOrigin?.apertment
                              : singleTransferData?.origin?.address
                                  ?.apertment}{" "}
                            {addOrigin?.city
                              ? addOrigin?.city
                              : singleTransferData?.origin?.address?.city}
                            <br />
                            {addOrigin?.province
                              ? addOrigin?.province
                              : singleTransferData?.origin?.address
                                  ?.province}{" "}
                            {addOrigin?.country
                              ? addOrigin?.country
                              : singleTransferData?.origin?.address
                                  ?.country}{" "}
                            {addOrigin?.pincode
                              ? addOrigin?.pincode
                              : singleTransferData?.origin?.address?.pincode}
                          </Typography>
                        </Stack>
                        <Divider
                          orientation="vertical"
                          flexItem
                          style={{ marginLeft: "5px", marginRight: "5px" }}
                        />
                        <Stack style={{ flex: 1 }}>
                          <Typography
                            variant="h6"
                            style={{
                              fontSize: "12px",
                              textDecoration: "underline",
                              marginBottom: 10,
                            }}
                          >
                            DESTINATION
                          </Typography>
                          {!_id ? (
                            <Autocomplete
                              options={locationData}
                              freeSolo
                              getOptionLabel={(option) => option?.name}
                              // fullWidth
                              style={{ marginTop: 10, width: 400 }}
                              onChange={(event, newValue) => {
                                setFieldValue("destination", newValue);
                                setAddDestination(newValue);
                                matchLocation("Destination", newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Destination"
                                  variant="outlined"
                                  error={
                                    errors.destination &&
                                    touched.destination && (
                                      <p>{errors.destination._id}</p>
                                    )
                                  }
                                  helperText={
                                    errors.destination &&
                                    touched.destination && (
                                      <p>{errors.destination._id}</p>
                                    )
                                  }
                                />
                              )}
                            />
                          ) : (
                            <Typography variant="h6" alignItems={"center"}>
                              {addDestination?.name}
                            </Typography>
                          )}
                          <Typography variant="body2">
                            {addDestination?.address
                              ? addDestination?.address
                              : singleTransferData?.destination?.address
                                  ?.address}{" "}
                            {addDestination?.apertment
                              ? addDestination?.apertment
                              : singleTransferData?.destination?.address
                                  ?.apertment}{" "}
                            {addDestination?.city
                              ? addDestination?.city
                              : singleTransferData?.destination?.address?.city}
                            <br />
                            {addDestination?.province
                              ? addDestination?.province
                              : singleTransferData?.destination?.address
                                  ?.province}{" "}
                            {addDestination?.country
                              ? addDestination?.country
                              : singleTransferData?.destination?.address
                                  ?.country}{" "}
                            {addDestination?.pincode
                              ? addDestination?.pincode
                              : singleTransferData?.destination?.address
                                  ?.pincode}
                          </Typography>
                        </Stack>
                      </Stack>

                      {error && (
                        <>
                          <center>
                            <Typography
                              variant="body2"
                              color="error"
                              marginTop={1}
                            >
                              The origin and destination cannot be same.
                            </Typography>
                          </center>
                        </>
                      )}

                      {_id && (
                        <>
                          <Divider style={{ marginTop: 20 }} />
                          <LinearProgress
                            style={{ marginTop: 20 }}
                            variant="buffer"
                            value={acceptPercentage(
                              singleTransferData?.qty,
                              singleTransferData?.acptQty || 0
                            )}
                            valueBuffer={rejectPercentage(
                              singleTransferData?.qty,
                              singleTransferData?.acptQty || 0,
                              singleTransferData?.rejctQty || 0
                            )}
                          />
                          <Typography
                            style={{
                              float: "right",
                              fontSize: "13px",
                              marginTop: 10,
                            }}
                          >
                            Total received :{singleTransferData?.total} of{" "}
                            {singleTransferData?.qty}
                          </Typography>
                        </>
                      )}
                    </Card>
                  </Grid>

                  {/* Products */}
                  <Grid item xs={12} md={12}>
                    <Card sx={{ p: 3 }}>
                      {!_id || singleTransferData?.status == "Pending" ? (
                        <>
                          <h4>Add Products</h4>
                          <Stack
                            direction="row"
                            spacing={1}
                            style={{ marginTop: "20px" }}
                          >
                            <SearchStyle
                              style={{
                                height: "45px",
                                borderRadius: "5px",
                              }}
                              fullWidth
                              placeholder="Search Products"
                              //value={searchText}
                              onClick={() => setProduct(true)}
                              startAdornment={
                                <InputAdornment position="start">
                                  <Box
                                    component={Icon}
                                    icon={searchFill}
                                    sx={{ color: "text.disabled" }}
                                  />
                                </InputAdornment>
                              }
                            />
                            <Button
                              variant="outlined"
                              style={{
                                height: "45px",
                                borderRadius: "5px",
                              }}
                              onClick={() => setProduct(true)}
                            >
                              Browse
                            </Button>
                            {product && (
                              <ProductSelector
                                onSelect={(selected) => {
                                  if (selected) {
                                    console.log("selected transfer", selected);
                                    handleSave(selected);
                                    setProduct(false);
                                    setTransferProduct(selected)
                                  
                                  }
                                }}
                                onClose={() => setProduct(false)}
                                multiple={true}
                                value={transferProduct}
                              />
                            )}
                          </Stack>
                        </>
                      ) : (
                        <h4>Ordered Products</h4>
                      )}
                      <Table style={{ marginTop: "20px" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Products</TableCell>
                            <TableCell>SKU</TableCell>
                            {!_id || singleTransferData?.status == "Pending" ? (
                              <>
                                <TableCell>Quantity</TableCell>
                                <TableCell></TableCell>
                              </>
                            ) : (
                              <TableCell>Received</TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {transferProduct?.map((item) => {
                            if (item.qty > 0) {
                              return (
                                <>
                                  <TableRow>
                                    <TableCell>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={2}
                                      >
                                        <Avatar
                                          variant="square"
                                          alt={
                                            item?.variant?.title ||
                                            item?.product?.title
                                          }
                                          src={
                                            item?.variant?.images?.length > 0
                                              ? item?.variant?.images?.[0].url
                                              : item?.product?.images?.length >
                                                0
                                              ? item?.product?.images?.[0].url
                                              : "public/favicon/product.png"
                                          }
                                          // alt={item?.title}
                                          // src={
                                          // item?.images?.length > 0
                                          // ? item?.images?.[0].url
                                          // : "public/favicon/product.png"
                                          // }
                                        />
                                        {/* {item?.product ? ( */}
                                        <Stack>
                                          <Typography
                                            style={{
                                              maxWidth: "130px",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              fontSize: "13px",
                                              fontWeight: "bold",
                                              marginTop: 2,
                                            }}
                                          >
                                            {item?.product?.title}
                                          </Typography>
                                          <Typography
                                            style={{
                                              fontSize: "13px",
                                              color: "grey",
                                            }}
                                          >
                                            {item?.variant?.title}
                                          </Typography>
                                        </Stack>
                                        {/* ):(
                                          <Typography
                                          style={{
                                            maxWidth: "130px",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            fontSize: "13px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {item?.title}
                                        </Typography>
                                        )} */}
                                      </Stack>
                                    </TableCell>

                                    <TableCell>
                                      <Stack>
                                        <Typography
                                          style={{
                                            maxWidth: "130px",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            fontSize: "13px",
                                            fontWeight: "bold",
                                            marginTop: 2,
                                          }}
                                        >
                                          {item?.variant?.sku
                                            ? item?.variant?.sku
                                            : item?.product?.sku
                                            ? item?.product?.sku
                                            : "No SKU"}
                                        </Typography>
                                      </Stack>
                                    </TableCell>

                                    {!_id ||
                                    singleTransferData?.status == "Pending" ? (
                                      <>
                                        <TableCell>
                                          <ButtonGroup disableElevation>
                                            <Button
                                              variant="contained"
                                              onClick={() =>
                                                handleDecrement(item)
                                              }
                                            >
                                              -
                                            </Button>
                                            <Button variant="outlined">
                                              {item?.qty}
                                            </Button>
                                            <Button
                                              variant="contained"
                                              onClick={() =>
                                                handleIncrement(item)
                                              }
                                            >
                                              +
                                            </Button>
                                          </ButtonGroup>
                                        </TableCell>

                                        <TableCell>
                                          <Close
                                            style={{
                                              cursor: "pointer",
                                              // marginLeft: "80px",
                                              marginTop: 10,
                                            }}
                                            onClick={() => handleRemove(item)}
                                          />
                                        </TableCell>
                                      </>
                                    ) : (
                                      <>
                                        <TableCell>
                                          <LinearProgress
                                            variant="buffer"
                                            value={acceptPercentage(
                                              item?.qty,
                                              item?.acptQty || 0
                                            )}
                                            valueBuffer={rejectPercentage(
                                              item?.qty,
                                              item?.acptQty || 0,
                                              item?.rejctQty || 0
                                            )}
                                          />
                                          <Typography
                                            style={{
                                              float: "right",
                                              fontSize: "13px",
                                            }}
                                          >
                                            {(item.acptQty || 0) +
                                              (item.rejctQty || 0)}{" "}
                                            of {item?.qty}
                                          </Typography>
                                        </TableCell>
                                      </>
                                    )}
                                  </TableRow>
                                </>
                              );
                            }
                          })}
                        </TableBody>
                      </Table>
                    </Card>
                  </Grid>

                  {/* Shipping Details */}
                  <Grid item xs={12} md={6}>
                    <Card sx={{ p: 3 }}>
                      <h4 style={{ marginBottom: 20 }}>Shipping Details</h4>
                      <Stack spacing={1}>
                        <label>Estimated Arrival</label>
                        <TextField
                          id="date"
                          type="date"
                          size="small"
                          {...getFieldProps("arrival")}
                          defaultValue={values?.arrival}
                          className={classes.textField}
                        />
                      </Stack>
                      <Stack spacing={1} marginTop={2}>
                        <label>Tracking Number</label>
                        <TextField
                          size="small"
                          fullWidth
                          placeholder="Enter tracking number"
                          value={values?.trackingNo}
                          onChange={(e) =>
                            setFieldValue("trackingNo", e.target.value)
                          }
                          error={Boolean(
                            touched.trackingNo && errors.trackingNo
                          )}
                          helperText={touched.trackingNo && errors.trackingNo}
                        />
                      </Stack>
                      <Stack spacing={1} marginTop={2}>
                        <label>Shipping Carrier</label>
                        {carrier?.length > 0 ? (
                          <TextField
                            select
                            size="small"
                            value={values.shipCarrier}
                            onChange={(e) =>
                              setFieldValue("shipCarrier", e.target.value)
                            }
                            // style={{ width: "240px" }}
                            fullWidth
                          >
                            {carrier?.map((option) => (
                              <MenuItem key={option} value={option._id}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        ) : (
                          <>
                            <Alert
                              variant="outlined"
                              style={{ cursor: "pointer" }}
                              severity="error"
                              onClick={() =>
                                navigate(PATH_DASHBOARD.setting.shipping)
                              }
                            >
                              <AlertTitle>
                                No shipping carrier available...
                              </AlertTitle>
                              You can create a new{" "}
                              <Link> Shipping Carrier here..</Link>
                            </Alert>
                          </>
                        )}
                      </Stack>

                      {values?.shipCarrier?.name == "Other" && (
                        <>
                          <Stack spacing={1} marginTop={2}>
                            <label>Tracking Url</label>
                            <TextField
                              fullWidth
                              size="small"
                              value={values?.url}
                              onChange={(e) =>
                                setFieldValue("url", e.target.value)
                              }
                              error={Boolean(touched.url && errors.url)}
                              helperText={touched.url && errors.url}
                            />
                          </Stack>
                        </>
                      )}
                    </Card>
                  </Grid>

                  {/* Additional Details */}
                  <Grid item xs={12} md={6}>
                    <Card sx={{ p: 3 }}>
                      <h4 style={{ marginBottom: 20 }}>Additional Details</h4>
                      <Stack spacing={1}>
                        <label>Reference Number</label>
                        <TextField
                          fullWidth
                          size="small"
                          placeholder="Enter reference number"
                          value={values?.ref}
                          {...getFieldProps("ref")}
                          error={Boolean(touched.ref && errors.ref)}
                          helperText={touched.ref && errors.ref}
                        />
                      </Stack>
                      <Divider
                        style={{
                          marginTop: 20,
                          marginBottom: 20,
                          marginLeft: "-24px",
                          marginRight: "-24px",
                        }}
                      />
                      <h4>Tags</h4>
                      <Autocomplete
                        multiple
                        freeSolo
                        style={{ marginTop: 10 }}
                        options={[]}
                        value={values.tags}
                        inputValue={values.tagInput}
                        onChange={(event, newValue) => {
                          setFieldValue("tags", newValue);
                        }}
                        onInputChange={(event, newInputValue) => {
                          const options = newInputValue.split(",");
                          if (options.length > 1) {
                            setFieldValue(
                              "tags",
                              values.tags
                                .concat(options)
                                .map((x) => x.trim())
                                .filter((x) => x)
                            );
                          } else {
                            setFieldValue("tagInput", newInputValue);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label="Tags"
                            size="small"
                            placeholder="Separate tags with a comma(,)"
                            // onChange={(e) => handleTagChange(e)}
                          />
                        )}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {_id && (
                      <>
                        <TimeLine
                          timeLine={timeLine}
                          id={_id}
                          tlType="transfer"
                          singleTransferData={singleTransferData}
                        />

                        <Stack
                          direction="row"
                          spacing={2}
                          style={{
                            marginTop: 30,
                            marginBottom: 20,
                            justifyContent: "center",
                          }}
                        >
                          {isPrevious && (
                            <Button
                              variant="outlined"
                              onClick={() => handleChangeTimeline("prev")}
                            >
                              <KeyboardArrowLeft />
                            </Button>
                          )}
                          {getId[0]?.items > 8 && (
                            <Button
                              variant="outlined"
                              onClick={() => handleChangeTimeline("next")}
                            >
                              <KeyboardArrowRight />
                            </Button>
                          )}
                        </Stack>
                      </>
                    )}
                  </Grid>

                  {/* Button */}
                  <Grid
                    item
                    xs={12}
                    md={12}
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={3}
                    sx={{
                      backgroundColor: "#FFF",
                      padding: "15px 140px",
                      position: "fixed",
                      bottom: 0,
                      left: 0,
                    }}
                  >
                    <>
                      <Divider
                        style={{
                          marginTop: 20,
                          marginBottom: 20,
                          marginLeft: "-24px",
                          marginRight: "-24px",
                        }}
                      />
                      {_id ? (
                        <LoadingButton
                          variant="contained"
                          color="error"
                        loading={deleteLoading}
                          style={{ float: "left" }}
                          onClick={() => setDeleteTransfer(true)}
                        >
                          Delete
                        </LoadingButton>
                      ) : (
                        <LoadingButton
                          variant="contained"
                          color="error"
                          style={{ float: "left" }}
                          onClick={() =>
                            navigate(PATH_DASHBOARD.products.transfers)
                          }
                        >
                          Cancel
                        </LoadingButton>
                      )}

                      <LoadingButton
                        variant="contained"
                        loading={loading}
                        onClick={handleSubmit}
                        sx={{ ml: 3 }}
                        disabled={error ? true : false}
                      >
                        {_id ? "Update Transfer" : "Create transfer"}
                      </LoadingButton>
                    </>
                  </Grid>

                  {/* Delete Transfer Dialog */}
                  <Dialog
                    open={deleteTransfer}
                    onClose={() => setDeleteTransfer(false)}
                    aria-labelledby="alert-dialog-title"
                    fullWidth
                  >
                    <DialogTitle id="responsive-dialog-title">
                      {"Delete"}
                      <IconButton
                        aria-label="close"
                        onClick={() => setDeleteTransfer(false)}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 10,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <Close />
                      </IconButton>
                    </DialogTitle>
                    <Divider style={{ marginTop: 10 }} />
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        <p>Do you want to Delete....?</p>
                      </DialogContentText>
                    </DialogContent>{" "}
                    <Divider />
                    <DialogActions>
                      <Button
                        variant="outlined"
                        onClick={() => setDeleteTransfer(false)}
                      >
                        Close
                      </Button>
                      <LoadingButton
                        variant="contained"
                        loading={deleteLoading}
                        onClick={() => (
                          dispatch(
                            DelTransfer(_id, setDeleteLoading, () =>
                              navigate(PATH_DASHBOARD.products.transfers)
                            )
                          ),
                          setDeleteTransfer(false)
                        )}
                      >
                        Delete
                      </LoadingButton>
                    </DialogActions>
                  </Dialog>
                </Grid>
              </>
            )}
          </Container>
        </Page>
      </Form>
    </FormikProvider>
  );
}
