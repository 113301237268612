import { toast } from "react-toastify";
import ApiExt from '../Provider/ImageApiExt';
import { BASE_URL } from "../Provider/api";
// Get Product
export function getImages(next, limit, search) {
    return async function (dispatch) {
        // dispatch({
        //     type: 'IS_LOADING',
        //     payload: true
        // })
        const data = {
            "next": next || "",
            "limit": limit || 10,
            "search": search || {}
        }

        ApiExt.post('/api/media/get-all', data)
            .then(function (response) {
                // console.log('Get Product Action=================', response.data);
                // dispatch({
                //     type: 'IS_LOADING',
                //     payload: false
                // })
                if (response.data.status === true) {
                    dispatch({
                        type: 'GET_IMAGES',
                        payload: response.data.result
                    });
                    // callback();
                } else {
                    toast.warn(response?.data?.msg)
                }
            }).catch(function (error) {
                console.log(error);
                // dispatch({
                //     type: 'IS_LOADING',
                //     payload: false
                // })
            });;
    }
}