import {
  Card,
  Container,
  Grid,
  Stack,
  Button,
  Typography,
  Divider,
  TextField,
  Checkbox,
  Table,
  TableRow,
  TableCell,
  TableBody,
  ButtonGroup,
  Avatar,
  LinearProgress,
  FormControl,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  Alert,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import Page from "../../../components/Page";
import { PATH_DASHBOARD } from "src/routes/paths";
import {
  Link,
  Link as RouterLink,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  CheckCircleOutline,
  LocationOn,
  WbSunnyOutlined,
} from "@material-ui/icons";
import {
  getReturntoRefund,
  submitReturntoRefund,
} from "src/redux/actions/ReturnAction";
import { LoadingButton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { getSingleOrder } from "src/redux/actions/OrderAction";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
}));

export default function ReturnToRefund() {
  const { _id, _rId } = useParams();
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [Item, setItem] = useState([]);
  const [refundbutton, setRefundbutton] = useState(false);
  const [refundItems, setRefundItems] = useState([]);
  const [shippingValue, setShippingValue] = useState(0);

  const store = useSelector((state) => state.auth.profilePayload);
  const Refund = useSelector((state) => state.Return.ReturntoRefundPayload);
  const isLoading = useSelector((state) => state.loader.loadingPayload);
  const singleOrder = useSelector((state) => state.orders.singleOrderPayload);

  useEffect(() => {
    dispatch(getReturntoRefund(_id, _rId));
    _id && dispatch(getSingleOrder(_id));
  }, []);

  useEffect(() => {
    setRefundItems(Refund);
  }, [Refund]);
  useEffect(() => {
    let rTotal = 0;
    let discount = 0;
    const filter = refundItems
      ?.filter((x) => x.return?.items?.myqty > 0)
      ?.map((value) => {
        return {
          ...value,
          rTotal: (rTotal +=
            value.return.items.price * value.return.items.myqty),
        };
      });
    setItem(filter);
  }, [refundItems]);
  const handleInput = (value, qty) => {
    if (value?.items?.uQty >= +qty) {
      const newRefundItems = refundItems.map((item) => {
        if (singleOrder?.discount?.type == "per") {
          var disValue =
            +qty *
            item.return.items.price *
            (singleOrder?.discount?.value / 100);
        } else {
          var disValue =
            +qty *
            item.return.items.price *
            (singleOrder?.discount?.value / singleOrder?.subTotal);
        }

        if (value.items.vid) {
          if (
            item.return.items.vid == value.items.vid &&
            item.return.items.pid == value.items.pid &&
            item.return.items.fid == value.items.fid
          ) {
            const taxable = item.return.items.tax / item.return.items.qty;
            item.return.items.myqty = +qty;
            if (singleOrder?.discount?.type) {
              item.return.items.disVal = disValue;
              item.return.items.disAmt = item.return.items.total - disValue;
            }
            item.return.items.refundTax =
              Math.round(taxable * +qty * 100) / 100;
            item.return.items.total =
              item.return.items.price * +qty -
              item.return.items.disAmt +
              item.return.items.refundTax;
            return item;
          } else {
            return item;
          }
        } else {
          if (
            item.return.items.pid == value.items.pid &&
            item.return.items.fid == value.items.fid
          ) {
            const taxable = item.return.items.tax / item.return.items.qty;
            item.return.items.myqty = +qty;
            if (singleOrder?.discount?.type) {
              item.return.items.disVal = disValue;
              item.return.items.disAmt = item.return.items.total - disValue;
            }
            item.return.items.refundTax =
              Math.round(taxable * +qty * 100) / 100;
            item.return.items.total =
              item.return.items.price * +qty -
              item.return.items.disAmt +
              item.return.items.refundTax;
            return item;
          } else {
            return item;
          }
        }
      });

      setRefundItems(newRefundItems);
    }
  };
  const refundSubTotal =
    Item?.reduce(
      (accumulator, current) =>
        accumulator +
        current?.return?.items?.price * current?.return?.items?.myqty,
      0
    ) || 0;

  const refundTax =
    Item?.reduce(
      (accumulator, current) => accumulator + current?.return?.items?.refundTax,
      0
    ) || 0;
  const discount =
    Refund?.[0].discount?.disType == "amt"
      ? Refund?.[0].discount?.disVal / Refund?.[0].subTotal
      : Refund?.[0].discount?.disVal / 100;

  const discountValue = Math.round(refundSubTotal * discount * 100) / 100;

  const tax = singleOrder?.tax?.taxType == "Excl" ? refundTax : 0;
  const refundValue =
    refundSubTotal > 0
      ? Math.round(
          (refundSubTotal - discountValue + +shippingValue + tax) * 100
        ) / 100
      : 0.0;

  const [amt, setAmt] = useState(0);

  const handleRestock = (e, value) => {
    const newReStockItems = refundItems?.map((item) => {
      if (
        item?.return?._id == value?._id &&
        item?.return?.items?.pid == value?.items?.pid
      ) {
        item.return.items.reStock = e;
        return item;
      } else {
        return item;
      }
    });
    setRefundItems(newReStockItems);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      items: Item,
      subTotal: refundSubTotal,
      discount: discountValue,
      total: +Math.round(refundValue * 100) / 100 || 0,
      shipping: +shippingValue,
      amt: amt > 0 ? +amt : +Math.round(refundValue * 100) / 100,
      reStock: true,
    },
    onSubmit: async (values, actions) => {
      try {
        dispatch(
          submitReturntoRefund(
            _id,
            _rId,
            values,
            () => {
              navigate(`${PATH_DASHBOARD.order.view}/${_id}`);
            },
            setLoading
          )
        );
      } catch (error) {
        actions.setSubmitting(false);
        actions.setErrors(error);
      }
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off">
        <Page>
          <Container>
            {isLoading && <LinearProgress style={{ width: "100%" }} />}
            {!isLoading && (
              <>
                {/* heading*/}
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="outlined"
                    style={{
                      marginBottom: "16px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      minHeight: "42px",
                      minWidth: "10px",
                    }}
                    component={RouterLink}
                    to={`${PATH_DASHBOARD.order.view}/${Refund?.[0]?._id}`}
                  >
                    <img
                      width="17px"
                      src="https://th.bing.com/th/id/R.9562e3998adf90b7ea409f041aab337d?rik=ERFU3YLhocBkdA&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2fKin%2feBp%2fKineBpprT.png&ehk=t39BBIm39HbdTD3%2bBxa%2bIfTm948yrYikFKGQivAW9UU%3d&risl=&pid=ImgRaw&r=0"
                    />
                  </Button>
                  <Typography variant="h5" style={{ marginTop: 5 }}>
                    Refund returned items
                  </Typography>
                </Stack>

                <Grid container spacing={3} sx={{ mb: 3 }}>
                  {/* right side bar */}
                  <Grid item xs={12} md={8}>
                    {/* Fulfill status */}
                    <Card sx={{ p: 3 }}>
                      <Stack direction="row" spacing={1} alignItems={"center"}>
                        <WbSunnyOutlined style={{ color: "#00a0ac" }} />
                        <h4>Return In progress</h4>
                      </Stack>
                      <Stack direction="row" spacing={1} alignItems={"center"}>
                        <LocationOn />
                        <Typography style={{ fontSize: 14 }}>
                          {Refund?.[0]?.shipAdd?.address}{" "}
                          {Refund?.[0]?.shipAdd?.city}{" "}
                          {Refund?.[0]?.shipAdd?.province}{" "}
                          {Refund?.[0]?.shipAdd?.country}{" "}
                          {Refund?.[0]?.shipAdd?.pincode}
                        </Typography>
                      </Stack>

                      <Divider
                        sx={{
                          mx: "-24px",
                          my: 1,
                        }}
                      />
                      <Table size="small">
                        <TableBody>
                          {Refund?.map((value) => {
                            if (value?.return?.items.disVal > 0) {
                              const disVal =
                                singleOrder?.discount?.disType == "per"
                                  ? value?.return?.items.uQty *
                                    value?.return?.items.price *
                                    (singleOrder?.discount?.disVal / 100)
                                  : value?.return?.items.uQty *
                                    value?.return?.items.price *
                                    (singleOrder?.discount?.disVal /
                                      singleOrder?.subTotal);
                              const finalDiscountValue =
                                Math.round(disVal * 100) / 100;
                              var disAmt =
                                value?.return?.items.uQty *
                                  value?.return?.items.price -
                                finalDiscountValue;
                            }
                            return (
                              <>
                                <TableRow>
                                  <TableCell>
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      <Avatar
                                        variant="square"
                                        alt={
                                          value?.return?.items?.variant
                                            ?.title ||
                                          value?.return?.items?.product?.title
                                        }
                                        src={
                                          value?.return?.items?.variant
                                            ? value?.return?.items?.variant
                                                ?.images?.length > 0
                                              ? value?.return?.items?.variant
                                                  ?.images[0]?.url
                                              : "public/favicon/product.png"
                                            : value?.return?.items?.product
                                                ?.images?.length > 0
                                            ? value?.return?.items?.product
                                                ?.images[0]?.url
                                            : "public/favicon/product.png"
                                        }
                                      />
                                      <Stack>
                                        <Link
                                          style={{
                                            textDecoration: "none",
                                          }}
                                          component={RouterLink}
                                          to={`${PATH_DASHBOARD.products.edit}/${value?.return?.items?.product?._id}`}
                                        >
                                          <Typography
                                            title={
                                              value?.return?.items?.product
                                                .title
                                            }
                                            style={{
                                              maxWidth: "200px",
                                              overflow: "hidden",
                                              fontSize: "13px",
                                              fontWeight: "bold",
                                              display: "-webkit-box",
                                              WebkitBoxOrient: "vertical",
                                              WebkitLineClamp: 2,
                                            }}
                                          >
                                            {
                                              value?.return?.items?.product
                                                .title
                                            }
                                          </Typography>
                                          {value?.return?.items?.variant
                                            ?.title && (
                                            <Typography
                                              title={
                                                value?.return?.items?.variant
                                                  ?.title
                                              }
                                              style={{
                                                maxWidth: "200px",
                                                overflow: "hidden",
                                                fontSize: "13px",
                                                fontWeight: "bold",
                                                display: "-webkit-box",
                                                WebkitBoxOrient: "vertical",
                                                WebkitLineClamp: 2,
                                                color: "grey",
                                              }}
                                            >
                                              {
                                                value?.return?.items?.variant
                                                  ?.title
                                              }
                                            </Typography>
                                          )}
                                        </Link>
                                        <Typography
                                          style={{
                                            fontSize: "13px",
                                            marginLeft: "15px",
                                          }}
                                        >
                                          <ul>
                                            <li>
                                              Return reason:{" "}
                                              {
                                                value?.return?.items?.reason
                                                  ?.name
                                              }
                                            </li>
                                          </ul>
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  </TableCell>
                                  <TableCell>
                                    {value?.return?.items?.disVal > 0 ? (
                                      <>
                                        <Typography
                                          style={{
                                            fontSize: "11px",
                                            textDecorationLine: "line-through",
                                            textDecorationStyle: "solid",
                                          }}
                                        >
                                          {store?.currency?.symbol}
                                          {value?.return?.items?.price *
                                            value?.return?.items?.uQty}
                                        </Typography>

                                        <Typography
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "14px",
                                          }}
                                        >
                                          {store?.currency?.symbol}
                                          {disAmt}
                                        </Typography>
                                      </>
                                    ) : (
                                      <>
                                        <Typography
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "14px",
                                          }}
                                        >
                                          {store?.currency?.symbol}{" "}
                                          {value?.return?.items?.price *
                                            value?.return?.items?.uQty}
                                        </Typography>
                                      </>
                                    )}
                                  </TableCell>
                                  <TableCell align="right">
                                    <FormControl
                                      sx={{ width: "100px" }}
                                      variant="outlined"
                                    >
                                      <OutlinedInput
                                        size="small"
                                        type="number"
                                        onChange={(e) => {
                                          if (
                                            e.target.value >
                                            value?.return?.items?.uQty
                                          ) {
                                            e.target.value = 0;
                                            handleInput(value.return, 0);
                                          } else {
                                            handleInput(
                                              value.return,
                                              e.target.value
                                            );
                                          }
                                        }}
                                        endAdornment={
                                          <InputAdornment position="end">
                                            /of {value?.return?.items?.uQty}
                                          </InputAdornment>
                                        }
                                        aria-describedby="outlined-weight-helper-text"
                                        inputProps={{
                                          "aria-label": "weight",
                                        }}
                                      />
                                    </FormControl>
                                  </TableCell>
                                </TableRow>

                                <TableCell colSpan={3}>
                                  <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    // style={{ marginLeft: "11px" }}
                                  >
                                    <Checkbox
                                      defaultChecked={values.reStock}
                                      onClick={(e) => {
                                        handleChange("reStock");
                                        handleRestock(
                                          e.target.checked,
                                          value?.return
                                        );
                                      }}
                                      {...getFieldProps("reStock")}
                                    />
                                    <Typography
                                      style={{
                                        fontSize: "15px",
                                      }}
                                    >
                                      Restock Item
                                    </Typography>
                                  </Stack>

                                  <Divider
                                    sx={{
                                      mx: "-24px",
                                      my: 1,
                                    }}
                                  />
                                </TableCell>
                              </>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Card>

                    {/* {(Refund?.[0]?.shipping?.refShip || 0) ==
                      Refund?.[0]?.shipping?.value &&
                      Refund?.[0]?.shipping?.value > 0 && ( */}
                    {Refund?.[0]?.shipping?.value -
                      (Refund?.[0]?.shipping?.refShip || 0) >
                      0 && (
                      <Card sx={{ p: 3, mt: 2 }}>
                        <h4>Refund Shipping</h4>

                        <Typography style={{ fontSize: "13px", marginTop: 10 }}>
                          Shipping Rate :{" "}
                          <strong>
                            ({store?.currency?.symbol}
                            {Refund?.[0]?.shipping?.value -
                              (Refund?.[0]?.shipping?.refShip || 0)}
                            )
                          </strong>
                        </Typography>
                        <Typography
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          Refund Amount
                        </Typography>

                        <FormControl fullWidth style={{ marginTop: 10 }}>
                          <OutlinedInput
                            size="small"
                            onChange={(e) => setShippingValue(e.target.value)}
                            type="number"
                            startAdornment={
                              <InputAdornment position="start">
                                {store?.currency?.symbol}
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        {shippingValue >
                          Refund?.[0]?.shipping?.value -
                            Refund?.[0]?.shipping?.refShip && (
                          <Typography
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginTop: 5,
                            }}
                          >
                            Can't refund more than available
                          </Typography>
                        )}
                      </Card>
                    )}
                    {/* )} */}
                  </Grid>

                  {/* left side bar */}
                  <Grid item xs={12} md={4}>
                    <Card sx={{ p: 3 }}>
                      {/* Summary */}
                      <Stack>
                        <h4>Summary</h4>
                        {refundItems?.filter((x) => x.return?.items?.myqty > 0)
                          .length > 0 ? (
                          <>
                            {/* Subtotal */}
                            <Stack
                              direction={"row"}
                              justifyContent="space-between"
                              marginTop={2}
                            >
                              <Typography style={{ fontSize: "15px" }}>
                                Items subtotal
                              </Typography>
                              <Typography style={{ fontSize: "15px" }}>
                                {store?.currency?.symbol}
                                {refundSubTotal}
                              </Typography>
                            </Stack>

                            {/* tax */}
                            {refundTax > 0 && (
                              <Stack
                                direction={"row"}
                                justifyContent="space-between"
                                marginTop={2}
                              >
                                <Typography style={{ fontSize: "15px" }}>
                                  Tax
                                </Typography>
                                <Typography style={{ fontSize: "15px" }}>
                                  {store?.currency?.symbol}
                                  {refundTax}
                                </Typography>
                              </Stack>
                            )}

                            {/* Discount */}
                            {Refund?.[0]?.discount?.disVal ? (
                              <Stack
                                direction={"row"}
                                justifyContent="space-between"
                                marginTop={2}
                              >
                                <Typography style={{ fontSize: "15px" }}>
                                  Discount
                                </Typography>
                                <Typography style={{ fontSize: "15px" }}>
                                  {store?.currency?.symbol}
                                  {Math.round(discountValue * 100) / 100}
                                </Typography>
                              </Stack>
                            ) : (
                              <></>
                            )}
                            {/* Refund?.[0]?.shipping?.refShip ==
                              Refund?.[0]?.shipping?.value || */}
                            {/* Shipping */}
                            {Refund?.[0]?.shipping?.value > 0 &&
                              Refund?.[0]?.shipping?.refShip ==
                                Refund?.[0]?.shipping?.value && (
                                <Stack
                                  direction={"row"}
                                  justifyContent="space-between"
                                  marginTop={2}
                                >
                                  <Typography style={{ fontSize: "15px" }}>
                                    Shipping
                                  </Typography>
                                  {shippingValue ? (
                                    <Typography style={{ fontSize: "15px" }}>
                                      {store?.currency?.symbol}
                                      {shippingValue}
                                    </Typography>
                                  ) : (
                                    <Typography style={{ fontSize: "15px" }}>
                                      {store?.currency?.symbol}
                                      0.00
                                    </Typography>
                                  )}
                                </Stack>
                              )}

                            {/* Total */}
                            <Stack
                              direction={"row"}
                              justifyContent="space-between"
                              marginTop={2}
                            >
                              <Typography
                                style={{ fontSize: "15px", fontWeight: "bold" }}
                              >
                                Refund Total
                              </Typography>
                              <Typography
                                style={{ fontSize: "15px", fontWeight: "bold" }}
                              >
                                {store?.currency?.symbol}
                                {Math.round(refundValue * 100) / 100}
                              </Typography>
                            </Stack>
                          </>
                        ) : (
                          <Typography
                            style={{
                              marginTop: 10,
                              fontSize: "13px",
                            }}
                          >
                            No items selected
                          </Typography>
                        )}
                        <Divider
                          sx={{
                            mt: 1,
                            mx: "-24px",
                          }}
                        />
                      </Stack>

                      {/* Refund Amount */}
                      <Typography style={{ marginTop: 10, fontSize: "13px" }}>
                        Refund Amount
                      </Typography>

                      {/* Manual */}
                      <Stack>
                        <Typography
                          style={{ fontSize: "15px", marginTop: "10px" }}
                        >
                          Manual
                        </Typography>

                        <FormControl fullWidth style={{ marginTop: "10px" }}>
                          <OutlinedInput
                            size="small"
                            value={amt > 0 ? amt : values?.total}
                            onChange={(e) => setAmt(e.target.value)}
                            startAdornment={
                              <InputAdornment position="start">
                                {store?.currency?.symbol}
                              </InputAdornment>
                            }
                          />
                        </FormControl>

                        {singleOrder?.paid - singleOrder?.refAmt == 0 ||
                          (amt > singleOrder?.paid - singleOrder?.refAmt && (
                            <Typography
                              style={{
                                fontSize: "12px",
                                color: "red",
                                marginTop: 5,
                              }}
                            >
                              Can't refund more than available amount.
                            </Typography>
                          ))}

                        <Typography
                          style={{
                            fontSize: "12px",
                            color: "grey",
                            marginTop: "10px",
                          }}
                        >
                          {store?.currency?.symbol}
                          {Math.round(
                            (singleOrder?.paid - singleOrder?.refAmt) * 100
                          ) / 100}{" "}
                          Available for refund
                        </Typography>
                      </Stack>

                      <Divider
                        style={{
                          marginTop: 10,
                          marginBottom: 10,
                          marginLeft: "-24px",
                          marginRight: "-24px",
                        }}
                      />
                      {refundItems?.filter((x) => x.return?.items?.myqty > 0)
                        .length > 0 &&
                        refundValue != refundValue && (
                          <Alert
                            variant="outlined"
                            severity="info"
                            style={{ borderRadius: 5 }}
                          >
                            The amount you are refunding is different from
                            suggested total
                          </Alert>
                        )}

                      {/* button */}

                      <LoadingButton
                        disabled={
                          (singleOrder?.paid - singleOrder?.refAmt == 0) &
                            (amt == 0) ||
                          singleOrder?.paid - singleOrder?.refAmt < amt ||
                          (refundItems?.length > 0 &&
                            (refundItems?.filter(
                              (x) => x.return?.items?.myqty > 0
                            ).length > 0
                              ? false
                              : true))
                        }
                        fullWidth
                        onClick={handleSubmit}
                        loading={loading}
                        variant="contained"
                        style={{ marginTop: 10 }}
                      >
                        Refund {store?.currency?.symbol}
                        {amt > 0 ? amt : Math.round(refundValue * 100) / 100}
                      </LoadingButton>
                    </Card>
                  </Grid>
                </Grid>
              </>
            )}
          </Container>
        </Page>
      </Form>
    </FormikProvider>
  );
}
