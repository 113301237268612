const initialState = {
  userAndPermission: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "GET_USERANDPERMISSON": {
      return {
        ...state,
        userAndPermission: action.payload,
      };
    }
    case "INSERT_STAFF": {
      const data = {
        status: "active",
        createdDate: new Date(),
        isowner: false,
        email: action.payload.email,
        _id: action.payload._id,
        status: "invited",
      };
      return {
        ...state,
        userAndPermission: [...state.userAndPermission, data],
      };
    }

    case "REMOVE_STAFF":
      var remove = state.userAndPermission.filter(
        (op) => op._id != action.payload
      );
      {
        return {
          ...state,
          userAndPermission: remove,
        };
      }

    default:
      return state;
  }
}
