import React, { useState } from "react";
import {
  Button,
  Typography,
  Divider,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Stack,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import PropTypes from "prop-types";
ShowTax.propTypes = {
  tax: PropTypes.object,
  setShowtax: PropTypes.func,
  symbol: PropTypes.string,
};
export default function ShowTax(props) {
  const { tax, setShowtax, symbol } = props;
  const [open, setOpen] = useState(true);
  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={() => (setOpen(false), setShowtax(false))}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Tax rates"}

          <IconButton
            aria-label="close"
            onClick={() => (setOpen(false), setShowtax(false))}
            sx={{
              position: "absolute",
              right: 8,
              top: 10,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider style={{ marginTop: 10 }} />
        <DialogContent>
          <DialogContentText>
            <>
              {tax?.desc.map((tax) => {
                return (
                  <>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      justifyContent={"space-between"}
                    >
                      <Typography style={{ fontSize: 14 , marginBottom: 10}}>
                        {tax?.name} ({tax?.per}%)
                      </Typography>
                      <Typography style={{ fontSize: 14 , marginBottom: 10}}>
                        {symbol} {tax?.value}
                      </Typography>
                    </Stack>
                  </>
                );
              })}

              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
              >
                <Typography style={{ fontSize: 14, color: "black"}}>
                  <b>Total tax</b>
                </Typography>
                <Typography style={{ fontSize: 14 , color: "black"}}>
                  <b>{symbol} {Math.round(tax?.taxAmt * 100) /
                        100 || 0.0}</b>
                </Typography>
              </Stack>
            </>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => (setOpen(false), setShowtax(false))}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
