const initialState = {exportPayload: [], customerPayload: [], allCustomerPayload: [] };

export default function (state = initialState, action) {
  switch (action.type) {
    case "CUSTOMERS_EXPORT": {
      return {
        ...state,
        exportPayload: action.payload,
      };
    }

    case "GET_CUSTOMER": {
      return {
        ...state,
        customerPayload: action.payload,
      };
    }

    case "GET_ALL_CUSTOMERS": {
      return {
        ...state,
        allCustomerPayload: action.payload,
      };
    }

    case "GET_SINGLE_CUSTOMER": {
      return {
        ...state,
        singlecustomerPayload: action.payload,
      };
    }

    case "UPDATE_CUSTOMER_OVERVIEW": {
      return {
        ...state,
        singlecustomerPayload: {
          ...state.singlecustomerPayload,
          overview: action.payload.data,
        },
      };
    }

    case "UPDATE_CUSTOMER_NOTE": {
      return {
        ...state,
        singlecustomerPayload: {
          ...state.singlecustomerPayload,
          note: { ...state.singlecustomerPayload.note, value: action.payload },
        },
      };
    }

    case "UPDATE_DEFAULT_ADDRESS": {
      const addresses = state.singlecustomerPayload.addresses.map((item) => {
        if (item._id == action.payload) {
          item.default = true;
          return item;
        } else {
          item.default = false;
          return item;
        }
      });

      return {
        ...state,
        singlecustomerPayload: {
          ...state.singlecustomerPayload,
          addresses: addresses,
        },
      };
    }

    

    case "UPDATE_SINGLE_ADDRESS": {
      const addresses = state.singlecustomerPayload.addresses.map((item) => {
        if (item._id == action.payload.addressId) {
          if (item.default) {
            action.payload.data.default = true;
          }
          return {
            _id: action.payload.addressId,
            fName: action.payload.data.fName,
            lName: action.payload.data.lName,
            com: action.payload.data.com,
            address: action.payload.data.address,
            apertment: action.payload.data.apertment,
            city: action.payload.data.city,
            country: action.payload.data.country,
            province: action.payload.data.province,
            pincode: action.payload.data.pincode,
            phone: action.payload.data.phone,
            ext: action.payload.data.ext,
            default: action.payload.data.default,
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        singlecustomerPayload: {
          ...state.singlecustomerPayload,
          addresses: addresses,
        },
      };
    }

    case "ADD_SINGLE_ADDRESS": {
      const cust = state.singlecustomerPayload.addresses.length == 0 ? action.payload.default = true : action.payload.default = false;
      return {
        ...state,
        singlecustomerPayload: {
          ...state.singlecustomerPayload,
          addresses: [
            ...state.singlecustomerPayload.addresses,
            action.payload,
          ],
        },
      };
    }

    case "UPDATE_CUSTOMER_TAGS": {
      return {
        ...state,
        singlecustomerPayload: {
          ...state.singlecustomerPayload,
          tags: action.payload,
        },
      };
    }

    case "UPDATE_CUSTOMER_TAX": {
      return {
        ...state,
        singlecustomerPayload: {
          ...state.singlecustomerPayload,
          isTax: action.payload,
        },
      };
    }

    case "UPDATE_CUSTOMER_MARKET_STATUS": {
      return {
        ...state,
        singlecustomerPayload: {
          ...state.singlecustomerPayload,
          overview : {
            emailSub: action.payload.isEmail,
          smsSub: action.payload.isSms
          },
        },
      };
    }

    case "GET_CUSTOMER_ORDER": {
      return {
        ...state,
        customerOrderPayload: action.payload,
      };
    }

    // case "DELETE_CUSTOMER": {
    //   console.log("a",action.payload);
    //   console.log("c",state.customerPayload);
    //   const customers = state.customerPayload.map((item) => {

    //     if ((item._id == action.payload)) {
    //       item.status = "archive";
    //       console.log("item", item);

    //       return item;

    //     }
    //   });

    //   console.log("customer", customers);

    //   return {
    //     ...state,
    //     customerPayload: {
    //       ...state.customerPayload,
    //       customerPayload: customers,
    //     },
    //   };
    // }

    default:
      return state;
  }
}
