const initialState = {
  SalesbyproductPayload: [],
  SalesbyproductVariantPayload: [],
  SalesbyproductVendorPayload: [],
  avgOrderValuePayload: [],
  TotalSalesPayload: [],
  TotalOrderPayload: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "SALES_BY_PRODUCTS": {
      return {
        ...state,
        SalesbyproductPayload: action.payload,
      };
    }

    case "SALES_BY_PRODUCTS_VARIANT": {
      return {
        ...state,
        SalesbyproductVariantPayload: action.payload,
      };
    }

    case "SALES_BY_PRODUCTS_VENDOR": {
      return {
        ...state,
        SalesbyproductVendorPayload: action.payload,
      };
    }

    case "AVG_ORDER_VALUE": {
        return {
          ...state,
          avgOrderValuePayload: action.payload,
        };
      }

      case "TOTAL_SALES": {
        return {
          ...state,
          TotalSalesPayload: action.payload,
        };
      }

      case "TOTAL_ORDER": {
        return {
          ...state,
          TotalOrderPayload: action.payload,
        };
      }

    default:
      return state;
  }
}
