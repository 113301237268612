import React, { useEffect, useState } from "react";
import Router from "./routes";
import ThemeConfig from "./theme";
import useAuth from "./hooks/useAuth";
import Settings from "./components/settings";
import RtlLayout from "./components/RtlLayout";
import ScrollToTop from "./components/ScrollToTop";
import LoadingScreen from "./components/LoadingScreen";
import GoogleAnalytics from "./components/GoogleAnalytics";
import NotistackProvider from "./components/NotistackProvider";
import ThemePrimaryColor from "./components/ThemePrimaryColor";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "./redux/actions";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { useTabContext } from "@material-ui/lab";
import SetupStore from "./pages/dashboard/setup/SetupStore";
import { FormatItalic } from "@material-ui/icons";

import { Box } from "@material-ui/core";
import NoInternet from "./components/NoInternet";

// ----------------------------------------------------------------------

export default function App() {
  const user = useSelector((state) => state.auth.profilePayload);
  const firstLoading = useSelector((state) => state.loader.firstLoadingPayload);
  const { isInitialized } = useAuth();
  const authToken = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  useEffect(() => {
    authToken && dispatch(getProfile(authToken));
  }, [dispatch, authToken]);

  const ValidateUser = () => {
    if (user?.status == "setup") {
      return <SetupStore />;
    } else {
      return <Router />;
    }
  };
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  function onlineHandler() {
    setIsOnline(true);
  }

  function offlineHandler() {
    setIsOnline(false);
  }

  useEffect(() => {
    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);
  }, []);


  return (
    <>
      {isOnline ? (
        <ThemeConfig>
          <ThemePrimaryColor>
            <RtlLayout>
              <NotistackProvider>
                <Settings />
                <ScrollToTop />
                <GoogleAnalytics />
                {isInitialized && !firstLoading ? (
                  <ValidateUser />
                ) : (
                  <LoadingScreen />
                )}
              </NotistackProvider>
            </RtlLayout>
          </ThemePrimaryColor>
        </ThemeConfig>
      ) : <NoInternet />
      }
    </>
  );
}
