import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
    Select,
    MenuItem
} from "@material-ui/core";
import { getCollections } from "src/redux/actions/CollectionAction";

CollectionPicker.propTypes = {
    /**
     * Callback fired when the Done is clicked.
     * 
     * @param {object} selectedItems Can be: `"object"`, `"array"`.
     */
    onChange: PropTypes.func,
    /**
    * defaultValue, usually the pre selected value.
    */
    defaultValue: PropTypes.string,
    /**
     * value, usually the pre selected value.
     */
    value: PropTypes.string,
    /**
     * label, usually the text value of search placeholder.
     */
    label: PropTypes.string
};

CollectionPicker.defaultProps = {
    onChange: (selectedItems) => { },
    onClose: () => { },
    defaultValue: '',
    value: [],
    label: "Search Collection"
};

export default function CollectionPicker(props) {
    const { onChange, value, label, defaultValue } = props;
    const dispatch = useDispatch();
    const collections = useSelector((state) => state.collections.collectionPayload);

    useEffect(() => {
        dispatch(getCollections({
            next: "",
            limit: 10000,
            search: {
                status: "active",
            },
        }));
    }, []);

    return (
        <>
            <label>{label}</label>
            <Select
                defaultValue={defaultValue}
                onChange={onChange}
            >
                {collections.map((i) => {
                    return <MenuItem value={i?._id}>{i?.title}</MenuItem>
                })}
            </Select>
        </>
    );
}