import { toast } from "react-toastify";
import { disposeEmitNodes } from "typescript";
import ApiExt from "../Provider/ApiExt";

//Get FulFill
export function getFulFill(id) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });
    ApiExt.post("/api/order/get-fulfill", { oId: id })
      .then(function (response) {
        if (response.data.status === true) {
          dispatch({
            type: "SET_FULFILL",
            payload: response.data.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });

        toast.error("Something went wrong. Please try again.");
      });
  };
}

//Submit Fulfill
export function submitFulfill(id, values, callback, setLoading) {
  return async function (dispatch) {
    setLoading(true);
    const value = {
      oId: id,
      data: {
        shipAdd: {
          fName: values?.shipAdd?.fName,
          lName: values?.shipAdd?.lName,
          com: values?.shipAdd?.com,
          apertment: values?.shipAdd?.apertment,
          city: values?.shipAdd?.city,
          country: values?.shipAdd?.country?._id ? values?.shipAdd?.country?._id : values?.shipAdd?.country,
          province: values?.shipAdd?.province?._id ?values?.shipAdd?.province?._id : values?.shipAdd?.province,
          pincode: values?.shipAdd?.pincode.toString(),
          address: values?.shipAdd?.address,
          phone: values?.shipAdd?.phone.toString(),
          ext: values?.shipAdd?.ext?._id ? values?.shipAdd?.ext?.dail_code : values?.shipAdd?.ext,
        },
        items: values?.items?.map((item) => {         
            return {
              qty: item?.items?.myqty,
              pid: item?.items?.pid,
              price: item?.items?.rate,
              vid: item?.items?.vid || "",
              total: item?.items?.total,
              disVal: Math.round(item?.items?.disVal * 100) / 100 || 0,
              disAmt: Math.round(item?.items?.disAmt * 100) / 100 || 0,
              tax: item?.items?.fulfillTax || 0,
            };     
        }),
        locId: values.location,
        status: "active",
      },
    };

    ApiExt.post("/api/order/insert-fulfill", value)
      .then(function (response) {
        setLoading(false);
        if (response.data.status === true) {
          toast.success("Order fulfilled");
          dispatch({
            type: "SUBMIT_FULFILL",
            payload: value,
          });
          callback();
        } else {
          toast.warn("Please try again");
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Fulfill Tracking
export function fulfillTracking(tracking, fulfillId, callback, setLoading) {
  return async function (dispatch) {
    setLoading(true);
    const value = {
      fulfillId: fulfillId,
      trackingNo: tracking?.trackingNo,
      carrier: tracking?.Carrier?._id ? tracking?.Carrier?._id : tracking?.Carrier,
      trackingUrl:
        tracking?.Carrier?.tUrl
          ? tracking?.Carrier?.tUrl
          : tracking?.tUrl,
    };

    ApiExt.post("/api/order/fulfill-tracking", value)
      .then(function (response) {
        setLoading(false);
        if (response.data.status === true) {
          toast.success(response?.data?.msg);
          dispatch({
            type: "TRACK_FULFILL",
            payload: value,
          });
          callback();
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Shipping Carrier
export function getShippingCarrier(values) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    ApiExt.post("/api/app/shipping-carrier", values)
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_SHIPPING_CARRIER",
            payload: response?.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}
