const initialState = { collectionPayload: [], allCollectionPayload: [] };

export default function (state = initialState, action) {
  switch (action.type) {
    case "GET_COLLECTION": {
      return {
        ...state,
        collectionPayload: action.payload,
      };
    }
    case "GET_ALL_COLLECTION": {
      return {
        ...state,
        allCollectionPayload: action.payload,
      };
    }
    case "GET_COLLECTION_SINGLE": {
      return {
        ...state,
        collectionSinglePayload: action.payload,
      };
    }
    case "UNARCHIVED_COLLECTION": {
      return {
        ...state,
        collectionSinglePayload: {
          ...state.collectionSinglePayload,
          status: "draft",
        },
      };
    }

    case "ARCHIVED_COLLECTION": {
      return {
        ...state,
        collectionSinglePayload: {
          ...state.collectionSinglePayload,
          status: "archive",
        },
      };
    }
    default:
      return state;
  }
}
