const initialState = {
    blogPostPayload: [],
    manageblogPostPayload: [],
    //  productTypePayload: []
  };
  export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_ALL_BLOG": {
          return {
            ...state,
            blogPostPayload: action.payload,
          };
        }

        case "GET_SINGLE_BLOG_POST": {
          return {
            ...state,
            singleBlogPayload: action.payload,
          };
        }
        case "BLOG_TYPES": {
          return {
            ...state,
            blogTypesPayload: action.payload,
          };
        }
        // -----------------------------------------------------
        case "GET_ALL_BLOGS": {
          return {
            ...state,
            manageblogPostPayload: action.payload,
          };
        }
        case "GET_SINGLE_BLOG": {
        return {
          ...state,
          blogPayload: action.payload,
        };
      }

        default:
      return state;
    }
  }