import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import DocsLayout from "../layouts/docs";
import EditorLayout from "../layouts/editor";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
import AuthGuard from "../guards/AuthGuard";
import { useDispatch, useSelector } from "react-redux";
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from "../components/LoadingScreen";
import CollectionList from "src/pages/dashboard/collection/List";
import CollectionForm from "src/pages/dashboard/collection/CollectionForm";
import TransferList from "src/pages/dashboard/transfers/List";
import CreateTransfer from "src/pages/dashboard/transfers/CreateTransfer";
import RecvInventory from "src/pages/dashboard/transfers/RecvInventory";

//  import CustomerList from 'src/pages/dashboard/customer/List';
import Lazy from "yup/lib/Lazy";
import ReturnToRefund from "src/pages/dashboard/order/ReturntoRefund";
import CheckoutView from "src/pages/dashboard/order/CheckoutView";
import Reports from "src/pages/dashboard/Reports";
import SalesoverTime from "src/components/_dashboard/general-analytics-reports/SalesoverTime";
import AvgOrdValReport from "src/components/_dashboard/general-analytics-reports/AvgOrdValReport";
import SalesByProductReport from "src/components/_dashboard/general-analytics-reports/SalesByProductReport";
import SalesByProductVendor from "src/components/_dashboard/general-analytics-reports/SalesByProductVendor";
import SalesByProductVariant from "src/components/_dashboard/general-analytics-reports/SalesByProductVariant";
import TotalOrdReport from "src/components/_dashboard/general-analytics-reports/TotalOrdReport";
import StoreDetails from "src/pages/dashboard/setting/StoreDetails";
import UpgradePlans from "src/pages/dashboard/setting/UpgradePlans";
import DomainDetail from "src/pages/dashboard/setting/DomainDetail";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

// Dashboard
const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp"))
);
const GeneralAnalytics = Loadable(
  lazy(() => import("../pages/dashboard/GeneralAnalytics"))
);
const GeneralAnalyticsReport = Loadable(
  lazy(() => import("../pages/dashboard/GeneralAnalyticsReport"))
);
// Product
const ProductList = Loadable(
  lazy(() => import("../pages/dashboard/product/List"))
);
const ProductCreate = Loadable(
  lazy(() => import("../pages/dashboard/product/ProductForm"))
);

//Customer

const CustomerList = Loadable(
  lazy(() => import("../pages/dashboard/customer/List"))
);
const CreateCustomer = Loadable(
  lazy(() => import("../pages/dashboard/customer/CreateCustomer"))
);
const EditCustomer = Loadable(
  lazy(() => import("../pages/dashboard/customer/EditCustomer"))
);
const OnlineStore = Loadable(
  lazy(() => import("../pages/dashboard/onlinestore/OnlineStore"))
);
const Blog = Loadable(
  lazy(() => import("../pages/dashboard/onlinestore/Blog"))
);
const StorePage = Loadable(
  lazy(() => import("../pages/dashboard/onlinestore/StorePage"))
);
const Preference = Loadable(
  lazy(() => import("../pages/dashboard/onlinestore/Preference"))
);
const AddBlog = Loadable(
  lazy(() => import("../pages/dashboard/onlinestore/AddBlog"))
);
const AddPage = Loadable(
  lazy(() => import("../pages/dashboard/onlinestore/AddPage"))
);
const ManageBlog = Loadable(
  lazy(() => import("../pages/dashboard/onlinestore/ManageBlog"))
);
const NewBlog = Loadable(
  lazy(() => import("../pages/dashboard/onlinestore/ManageBlogPage"))
);
const Navigation = Loadable(
  lazy(() => import("../pages/dashboard/onlinestore/Navigation"))
);
const AddNavigation = Loadable(
  lazy(() => import("../pages/dashboard/onlinestore/AddNavigation"))
);

// Docs
const Docs = Loadable(lazy(() => import("../pages/Docs")));

// Main
const ComingSoon = Loadable(lazy(() => import("../pages/ComingSoon")));
const Maintenance = Loadable(lazy(() => import("../pages/Maintenance")));
const Payment = Loadable(lazy(() => import("../pages/Payment")));
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
const UserAndPermission = Loadable(
  lazy(() => import("../pages/dashboard/UserAndPermisson"))
);
const SettingUserDetails = Loadable(
  lazy(() => import("../pages/dashboard/setting/SettingUserDetails"))
);
const Domains = Loadable(
  lazy(() => import("../pages/dashboard/setting/Domains"))
);

const GiftCard = Loadable(
  lazy(() => import("../pages/dashboard/giftcard/List"))
);
const GiftCardList = Loadable(
  lazy(() => import("../pages/dashboard/giftcard/GiftCardList"))
);
const GiftCardForm = Loadable(
  lazy(() => import("../pages/dashboard/giftcard/GiftCardForm"))
);
const IssueGiftCard = Loadable(
  lazy(() => import("../pages/dashboard/giftcard/IssueGiftCardForm"))
);
const IssueCardView = Loadable(
  lazy(() => import("../pages/dashboard/giftcard/IssueCardView"))
);
const Discount = Loadable(
  lazy(() => import("../pages/dashboard/discount/Discount"))
);
const DiscountCode = Loadable(
  lazy(() => import("../pages/dashboard/discount/DiscountCode"))
);
const Setting = Loadable(
  lazy(() => import("../pages/dashboard/setting/Setting"))
);
const Inventory = Loadable(
  lazy(() => import("../pages/dashboard/inventory/Inventory"))
);
const CreateOrder = Loadable(
  lazy(() => import("../pages/dashboard/order/CreateOrder"))
);
const Draft = Loadable(lazy(() => import("../pages/dashboard/order/Draft")));
const Checkouts = Loadable(
  lazy(() => import("../pages/dashboard/order/Checkouts"))
);
const OrderList = Loadable(
  lazy(() => import("../pages/dashboard/order/OrderList"))
);
const EditOrder = Loadable(
  lazy(() => import("../pages/dashboard/order/EditOrder"))
);
const Refund = Loadable(lazy(() => import("../pages/dashboard/order/Refund")));
const Return = Loadable(lazy(() => import("../pages/dashboard/order/Return")));
const Fulfill = Loadable(
  lazy(() => import("../pages/dashboard/order/Fulfill"))
);
const Update = Loadable(lazy(() => import("../pages/dashboard/order/Update")));
const SettingPlan = Loadable(
  lazy(() => import("../pages/dashboard/setting/SettingPlan"))
);
const SettingLegal = Loadable(
  lazy(() => import("../pages/dashboard/setting/SettingLegal"))
);
const SettingsCheckOut = Loadable(
  lazy(() => import("../pages/dashboard/setting/SettingsCheckOut"))
);
const SettingDetails = Loadable(
  lazy(() => import("../pages/dashboard/setting/SettingDetails"))
);
const Brand = Loadable(lazy(() => import("../pages/dashboard/setting/Brand")));
const NewFile = Loadable(lazy(() => import("../pages/dashboard/NewFile")));
const SettingBilling = Loadable(
  lazy(() => import("../pages/dashboard/setting/SettingBilling"))
);
const CurrentBillingCycle = Loadable(
  lazy(() =>
    import("../components/_dashboard/settingBilling/CurrentBillingCycle")
  )
);
const BillingHistoryMenuTable = Loadable(
  lazy(() =>
    import("../components/_dashboard/settingBilling/BillingHistoryMenuTable")
  )
);
const SettingLocation = Loadable(
  lazy(() => import("../pages/dashboard/setting/SettingLocation"))
);
const SettingAddLocation = Loadable(
  lazy(() => import("../pages/dashboard/setting/SettingAddLocation"))
);
const SettingNotification = Loadable(
  lazy(() => import("../pages/dashboard/setting/SettingNotification"))
);
const NotificationTemplate = Loadable(
  lazy(() => import("../pages/dashboard/setting/NotificationTemplate"))
);
const EditNotificationTemplate = Loadable(
  lazy(() => import("../pages/dashboard/setting/EditNotificationTemplate"))
);
const SettingCustomize = Loadable(
  lazy(() =>
    import("../components/_dashboard/settingnotification/SettingCustomize")
  )
);
const SettingTaxes = Loadable(
  lazy(() => import("../pages/dashboard/setting/SettingTaxes"))
);
const TaxDetails = Loadable(
  lazy(() => import("../pages/dashboard/setting/TaxDetails"))
);
const ConnectDomain = Loadable(
  lazy(() => import("../pages/dashboard/setting/ConnectDomain"))
);
const SettingGiftCard = Loadable(
  lazy(() => import("../pages/dashboard/setting/SettingGiftCard"))
);
const ShippingandDelivery = Loadable(
  lazy(() => import("../pages/dashboard/setting/SettingShippingandDelivery"))
);
const ShippingProfile = Loadable(
  lazy(() => import("../pages/dashboard/shipping/CreateShipping"))
);
const PackingSlipTemplate = Loadable(
  lazy(() => import("../pages/dashboard/shipping/PackingSlipTemplate"))
);
const LocalDelivery = Loadable(
  lazy(() => import("../pages/dashboard/shipping/LocalDelivery"))
);
const ManageShippingProfile = Loadable(
  lazy(() => import("../pages/dashboard/shipping/ManageShippingProfile"))
);
const SettingPayment = Loadable(
  lazy(() => import("../pages/dashboard/setting/SettingPayment"))
);
const EditorLanding = Loadable(lazy(() => import("../layouts/editor/index")));


const SettingTaxCollection = Loadable(
  lazy(() =>
    import("../components/_dashboard/settingtaxes/SettingTaxCollection")
  )
);
const EditVarients = Loadable(
  lazy(() => import("../components/_dashboard/settingtaxes/EditVarients"))
);
const VendorDetails = Loadable(
  lazy(() => import("../components/_dashboard/settingvendor/VendorDetails"))
);
const VendorView = Loadable(
  lazy(() => import("../components/_dashboard/settingvendor/VendorView"))
);
export default function Router() {
  const isUser = useSelector((state) => state.auth.profilePayload);
  return useRoutes([
    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          {isUser ? <DashboardLayout /> : <LoadingScreen />}
        </AuthGuard>
      ),
      children: [
        { path: "/", element: <Navigate to="/dashboard/app" replace /> },
        { path: "app", element: <GeneralApp /> },
        // Analytics
        {
          path: "analytics",
          element: <GeneralAnalytics />,
        },
        {
          path: "reports",
          element: <Reports />,
        },
        {
          path: "viewreports",
          element: <GeneralAnalyticsReport />,
        },
        // Analytics Resports
        {
          path: "report",
          children: [
            { path: "/sales_over_time", element: <SalesoverTime /> },
            { path: "/average_order_value", element: <AvgOrdValReport /> },
            { path: "/sales_by_product", element: <SalesByProductReport /> },
            {
              path: "/sales_by_product_vendor",
              element: <SalesByProductVendor />,
            },
            {
              path: "/sales_by_product_variant_sku",
              element: <SalesByProductVariant />,
            },
            { path: "/orders_over_time", element: <TotalOrdReport /> },
          ],
        },
        // Newfile
        {
          path: "newfile",
          children: [
            { path: "/", element: <NewFile /> },
            { path: "list", element: <NewFile /> },
          ],
        },

        // Product
        {
          path: "product",
          children: [
            { path: "/list", element: <ProductList /> },
            { path: "new", element: <ProductCreate /> },
            { path: "/edit/:_id", element: <ProductCreate /> },
            { path: "/inventory", element: <Inventory /> },
            { path: "/transfers", element: <TransferList /> },
            { path: "/createTransfer", element: <CreateTransfer /> },
            { path: "/editTransfer/:_id", element: <CreateTransfer /> },
            {
              path: "/editTransfer/:_id/recvInventory",
              element: <RecvInventory />,
            },
            { path: "/giftcard", element: <GiftCardList /> },
            // { path: '/', element: <Navigate to="/dashboard/e-commerce/shop" replace /> },
            // { path: 'shop', element: <EcommerceShop /> },
            // { path: 'checkout', element: <EcommerceCheckout /> },
            // { path: 'invoice', element: <EcommerceInvoice /> }
          ],
        },

        // Customer
        {
          path: "customer",
          children: [
            { path: "/list", element: <CustomerList /> },
            { path: "/new", element: <CreateCustomer /> },
            { path: "/edit/:_id", element: <EditCustomer /> },
            { path: "/order/:_id", element: <CreateOrder /> },
          ],
        },

        // Collection
        {
          path: "collection",
          children: [
            { path: "/list", element: <CollectionList /> },
            { path: "/new", element: <CollectionForm /> },
            { path: "/edit/:_id", element: <CollectionForm /> },
          ],
        },

        // GiftCard
        {
          path: "giftcard",
          children: [
            { path: "/list", element: <GiftCard /> },
            { path: "/new", element: <GiftCardForm /> },
            { path: "/new/giftcardEdit/:_id", element: <GiftCardForm /> },
            { path: "/issue_gift_card", element: <IssueGiftCard /> },
            { path: "/view/:_id", element: <IssueCardView /> },
          ],
        },

        // Location
        {
          path: "location",
          children: [
            { path: "/", element: <SettingLocation /> },
            { path: "/new", element: <SettingLocation /> },
            { path: "/new/:_edit", element: <SettingLocation /> },
          ],
        },

        // Discount
        {
          path: "discount",
          children: [
            { path: "/", element: <Discount /> },
            { path: "create", element: <DiscountCode /> },
            { path: "/viewDiscount/:_id", element: <DiscountCode /> },
          ],
        },

        // Setting
        {
          path: "setting",
          children: [
            { path: "/", element: <Setting /> },
            { path: "user", element: <UserAndPermission /> },
            { path: "user/:id", element: <SettingUserDetails /> },
            { path: "legal", element: <SettingLegal /> },
            { path: "plan", element: <SettingPlan /> },
            { path: "plan/upgradePlan", element: <UpgradePlans /> },
            { path: "checkout", element: <SettingsCheckOut /> },
            { path: "storedetails", element: <SettingDetails /> },
            { path: "billing", element: <SettingBilling /> },
            {
              path: "billing/currentbilling",
              element: <CurrentBillingCycle />,
            },
            {
              path: "billing/billinghistory",
              element: <BillingHistoryMenuTable />,
            },
            { path: "location", element: <SettingLocation /> },
            { path: "location/new", element: <SettingAddLocation /> },
            { path: "location/edit/:_id", element: <SettingAddLocation /> },
            { path: "notification", element: <SettingNotification /> },
            {
              path: "notification/:mode/:name/preview",
              element: <NotificationTemplate />,
            },
            {
              path: "notification/:mode/:name/edit",
              element: <EditNotificationTemplate />,
            },
            {
              path: "notification/orderconfirmation",
              element: <SettingCustomize />,
            },
            { path: "taxes", element: <SettingTaxes /> },
            { path: "taxes/:_id", element: <TaxDetails /> },
            { path: "taxes/taxcollection", element: <SettingTaxCollection /> },
            { path: "taxes/editvarient", element: <EditVarients /> },
            { path: "vendor", element: <VendorDetails /> },
            { path: "addVendor", element: <VendorView /> },
            { path: "vendor/view/:_id", element: <VendorView /> },
            { path: "domains", element: <Domains /> },
            { path: "connect", element: <ConnectDomain /> },
            { path: "edit_domain/:_id", element: <ConnectDomain /> },
            { path: "verify_domain/:_id", element: <DomainDetail /> },
            { path: "branding", element: <Brand /> },
            { path: "gift_cards", element: <SettingGiftCard /> },
            { path: "shipping", element: <ShippingandDelivery /> },
            { path: "shipping/profiles/create", element: <ShippingProfile /> },
            { path: "shipping/profiles/:_id", element: <ShippingProfile /> },
            {
              path: "shipping/packing_slip_template",
              element: <PackingSlipTemplate />,
            },
            { path: "shipping/local_delivery", element: <LocalDelivery /> },
            { path: "shipping/profiles/", element: <ManageShippingProfile /> },
            { path: "payments", element: <SettingPayment /> },
          ],
        },

        // OnlineStore
        {
          path: "onlinestore",
          children: [
            { path: "/theme", element: <OnlineStore /> },
            { path: "/blogpage", element: <Blog /> },
            { path: "/manageblog", element: <ManageBlog /> },
            { path: "/:manageblog/newblog", element: <NewBlog /> },
            { path: "/:manageblog/edit/:_id", element: <NewBlog /> },
            { path: "/storepage", element: <StorePage /> },
            { path: "/preference", element: <Preference /> },
            { path: "/:blogpage/addblog", element: <AddBlog /> },
            { path: "/:blogpage/editblog/:_id", element: <AddBlog /> },
            { path: "/:storepage/addpage", element: <AddPage /> },
            { path: "/:storepage/editpage/:_id", element: <AddPage /> },
            { path: "/navigation", element: <Navigation /> },
            { path: "/navigation/new", element: <AddNavigation /> },
            { path: "/navigation/edit/:_id", element: <AddNavigation /> },
            {
              path: "/",
              element: <Navigate to="/dashboard/onlinestore" replace />,
            },
            //{ path: '/theme/preference', element: <ThemePreference /> },
          ],
        },

        // Order
        {
          path: "order",
          children: [
            { path: "/", element: <CreateOrder /> },
            { path: "/create", element: <CreateOrder /> },
            { path: "/draft", element: <Draft /> },
            { path: "/checkouts", element: <Checkouts /> },
            { path: "/checkout_view/:id", element: <CheckoutView /> },
            { path: "/list", element: <OrderList /> },
            { path: "/list/:_id", element: <OrderList /> },
            { path: "/view/:_id", element: <EditOrder /> },
            { path: "/view/:_id/refund", element: <Refund /> },
            { path: "/view/:_id/return", element: <Return /> },
            {
              path: "/view/:_id/returns/:_rId/refund",
              element: <ReturnToRefund />,
            },
            { path: "/view/:_id/fulfill", element: <Fulfill /> },
            { path: "/view/:_id/update", element: <Update /> },
          ],
        },
      ],
    },

    // Docs Routes
    {
      path: "docs",
      element: <DocsLayout />,
      children: [
        { path: "/", element: <Navigate to="/docs/introduction" replace /> },
        { path: "*", element: <Docs /> },
      ],
    },

    // theme Routes
    {
      path: "editor",
      element: <EditorLayout />,
      children: [
        //{ path: '/', element: <Navigate to="/editor/landing" replace /> },
        { path: "/landing/:_id", element: <EditorLanding /> },
        { path: "/landing/:_id/:_page", element: <EditorLanding /> },
        { path: "/landing/:_id/:_page/:page_data", element: <EditorLanding /> },
        //{ path: '*', element: <ThemePreference /> }
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "coming-soon", element: <ComingSoon /> },
        { path: "maintenance", element: <Maintenance /> },
        { path: "payment", element: <Payment /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },

    { path: "/", element: <Navigate to="/dashboard" replace /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS
