const initialState = {
    ReturnPayload: [],
    
  };
  export default function (state = initialState, action) {
    switch (action.type) {
        case "SET_RETURN": {
          return {
            ...state,
            ReturnPayload: action.payload,
          };
        }

        case "UPDATE_RETURN_QTY": {
          const items = state.ReturnPayload.map((item) => {
              if (item._id == action.payload.id) {
                item.qty = action.payload.qty;
                return item;
              } else {
               
                return item;
              }
            });
      
            return {
              ...state,
              ReturnPayload: items,
            };
        }

        // case "SUBMIT_RETURN": {
        //   return {
        //     ...state,
        //     submitreturnPayload: action.payload,
        //   };
        // }

        
        case "GET_RETURNTOREFUND": {
          return {
            ...state,
            ReturntoRefundPayload: action.payload,
          };
        }

        default:
      return state;
    }
  }