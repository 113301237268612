import React, { useState } from "react";
import PropTypes from "prop-types";
import ResourcePicker from "../../../components/_dashboard/media/ResourcePicker";
import { motion, AnimatePresence } from "framer-motion";
import { varFadeInRight } from "../../animate";
import {
    Close,
    CloudUpload,
} from "@material-ui/icons";

import {
    Paper,
    Button,
    List,
    ListItem,
    Box,
} from "@material-ui/core";

ImagePicker.propTypes = {
    onSelect: PropTypes.func,
    multiple: PropTypes.bool,
    history: PropTypes.bool,
    preview: PropTypes.bool
};

ImagePicker.defaultProps = {
    onSelect: () => { },
    onClose: () => { },
    multiple: false,
    history: true,
    preview: true
};

export default function ImagePicker(props) {
    const { onSelect, multiple, history, preview, value } = props;

    const _medias = multiple ? (value || []) : value;

    const [media, setMedia] = useState(_medias);
    const [openMediaDialog, setOpenMediaDialog] = useState(false);


    const removeImage = (op) => {
        if (multiple) {
            let val = media.map((value, index) => {
                if (op == index) {
                    //dispatch(deleteImage(value._id));
                    return {
                        ...value,
                        selected: false,
                    };
                }
                return value;
            });

            setMedia(val.filter((item) => item.selected != false));
            onSelect(val.filter((item) => item.selected != false));
        }
        else{
            setMedia(null);
            onSelect(null);
        }
    };

    return (
        <>
            {
                openMediaDialog ? (
                    <ResourcePicker
                        multiple={multiple}
                        history={history}
                        preview={preview}
                        onSelect={(selected) => {
                            if (selected) {
                                if (multiple) {
                                    const joined = media?.concat(selected);
                                    setMedia(joined);
                                    onSelect(joined);
                                } else {
                                    setMedia(selected);
                                    onSelect(selected);
                                }
                                setOpenMediaDialog(false);
                            }
                        }}
                        onClose={() => setOpenMediaDialog(false)}
                    ></ResourcePicker>
                ) : (
                    <></>
                )
            }
            <Box
                onClick={() => setOpenMediaDialog(true)}
                sx={{
                    width: "100%",
                    height: 150,
                    border: "1px dashed",
                    borderRadius: "10px",
                    borderColor: "#00800091",
                    padding: 7,
                    marginTop: "10px",
                    cursor: "pointer",
                    backgroundColor: "#29a9291c",
                }}
            >
                <center style={{ marginTop: "-18px" }}>
                    <CloudUpload />
                    <br></br>
                    <Button>Upload Image</Button>
                </center>
            </Box>
            {
                media && (
                    <List disablePadding sx={{ ...{ my: 3 } }}>
                        <AnimatePresence>
                            {multiple ?
                                media?.map((image, index) => {
                                    return (
                                        <>
                                            <ListItem
                                                key={image._id}
                                                component={motion.div}
                                                {...varFadeInRight}
                                                sx={{
                                                    p: 0,
                                                    m: 0.5,
                                                    width: multiple ? 100 : 200,
                                                    height: multiple ? 100 : 200,
                                                    borderRadius: 1.5,
                                                    overflow: "hidden",
                                                    position: "relative",
                                                    display: "inline-flex",
                                                }}
                                            >
                                                <Paper
                                                    variant="outlined"
                                                    component="img"
                                                    // src={isString(file) ? file : preview}
                                                    src={image?.url}
                                                    sx={{
                                                        width: "100%",
                                                        height: "100%",
                                                        objectFit: "cover",
                                                        position: "absolute",
                                                    }}
                                                />
                                                <Box
                                                    sx={{ top: 6, right: 6, position: "absolute" }}
                                                >
                                                    <Close
                                                        onClick={() => removeImage(index)}
                                                        color="primary"
                                                        inputProps={{
                                                            "aria-label": "secondary checkbox",
                                                        }}
                                                        sx={{
                                                            backgroundColor: "#ffffffc9",
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                </Box>
                                            </ListItem>
                                        </>
                                    );
                                })
                                :
                                <>
                                    <ListItem
                                        key={media._id}
                                        component={motion.div}
                                        {...varFadeInRight}
                                        sx={{
                                            p: 0,
                                            m: 0.5,
                                            width: 200,
                                            height: 200,
                                            borderRadius: 1.5,
                                            overflow: "hidden",
                                            position: "relative",
                                            display: "inline-flex",
                                        }}
                                    >
                                        <Paper
                                            variant="outlined"
                                            component="img"
                                            // src={isString(file) ? file : preview}
                                            src={media?.url}
                                            sx={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                                position: "absolute",
                                            }}
                                        />
                                        <Box
                                            sx={{ top: 6, right: 6, position: "absolute" }}
                                        >
                                            <Close
                                                onClick={() => removeImage(0)}
                                                color="primary"
                                                inputProps={{
                                                    "aria-label": "secondary checkbox",
                                                }}
                                                sx={{
                                                    backgroundColor: "#ffffffc9",
                                                    cursor: "pointer",
                                                }}
                                            />
                                        </Box>
                                    </ListItem>
                                </>
                            }
                        </AnimatePresence>
                    </List>
                )
            }
        </>
    )

}