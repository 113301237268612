import React from "react";
import { Typography,Link } from "@material-ui/core";
import PropTypes from "prop-types";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useNavigate } from "react-router";

RemoveCustomerTimeline.propTypes = {
  cust: PropTypes.object,
};

export default function RemoveCustomerTimeline(props) {
  const { cust } = props;
  const navigate = useNavigate();
  return (
    <>
      <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
        Removed
      </Typography>
      <Link
        style={{ cursor: "pointer", fontSize: "14px" }}
        onClick={() =>
          navigate(`${PATH_DASHBOARD.customers.edit}/${cust?.id}`)
        }
      >
        {cust?.fName} {cust?.lName}
      </Link>
    </>
  );
}
