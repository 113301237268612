import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Button, AppBar, Toolbar, IconButton, LinearProgress } from '@material-ui/core';
// components
import Logo from '../../components/Logo';
import { MHidden } from '../../components/@material-extend';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
//Router
import {
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, Slide } from 'react-toastify';
// ----------------------------------------------------------------------

const APPBAR_HEIGHT = 64;

const RootStyle = styled(AppBar)(({ theme }) => ({
  zIndex: 999,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  boxShadow: theme.customShadows.z8,
  color: theme.palette.text.primary,
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('md')]: { zIndex: 1999 }
}));

// ----------------------------------------------------------------------

DocsNavbar.propTypes = {
  isLoading: PropTypes.bool,
  onPageChange: PropTypes.func,
  onOpenSidebar: PropTypes.func,
  themeId: PropTypes.string,
  page: PropTypes.string
};

export default function DocsNavbar({ isLoading, onPageChange, onOpenSidebar, themeId, page }) {
  const navigate = useNavigate();
  const pageSchemaData = useSelector(state => state.storepage.pageSchemaData);

  return (
    <RootStyle>
      <Toolbar sx={{ minHeight: APPBAR_HEIGHT }}>
        <MHidden width="mdUp">
          <IconButton onClick={onOpenSidebar} color="inherit">
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        <MHidden width="mdDown">
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </MHidden>
        {/* <Button variant="contained" color="primary" style={{ margin: '8px' }}>
          Section
        </Button>
        <Button>
          Theme Settings
        </Button> */}

        <Box sx={{ flexGrow: 1 }} />
        {pageSchemaData?.pages &&
          <FormControl style={{ width: 250 }} size="small">
            <InputLabel id="demo-simple-select-helper-label">
              Page
            </InputLabel>
            <Select
              native
              label="Page"
              labelId="demo-simple-select-helper-label"
              onChange={(selectedValue) => {
                const page = pageSchemaData?.pages[selectedValue.target.value];
                onPageChange(page);
                //navigate(`${PATH_DASHBOARD.editor.landing}/${themeId}/${page?.url}`);
              }}
              defaultValue={page}
            >
              {
                Object.keys(pageSchemaData?.pages).map((key) => {
                  return <option value={pageSchemaData.pages[key].name}>{pageSchemaData.pages[key].title}</option>;
                })
              }
              {/* <option value="index">Home</option>
            <option value="products">Products</option>
            <option value="single-product">Single Product</option>
            <option value="discount">Discount</option>
            <option value="collections">Collections</option>
            <option value="collection/demo">Single Collection</option>
            <option value="blogs">Blogs</option>
            <option value="blog/demo">Single Blog</option>
            <option value="discount">Discount</option>
            <option value="login">Login</option>
            <option value="register">Register</option> */}
            </Select>
          </FormControl>
        }
        <Button
          disableRipple
          to={PATH_DASHBOARD.onlinestore.theme}
          component={RouterLink}
          endIcon={<Icon icon={arrowIosForwardFill} />}
        >
          Back to Dashboard
        </Button>
      </Toolbar>
      {isLoading ? <LinearProgress style={{ width: "100%" }} /> : <></>}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
        theme="colored"
      />
    </RootStyle>
  );
}
