import React, { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { Form, FormikProvider, useFormik } from "formik";
import moment from "moment";
import * as Yup from "yup";

// material
import {
  Card,
  Grid,
  Stack,
  Switch,
  Select,
  TextField,
  InputLabel,
  FormControl,
  FormHelperText,
  FormControlLabel,
  MenuItem,
  Button,
  Container,
  LinearProgress,
  Chip,
  Typography,
  RadioGroup,
  Radio,
  Divider,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  ListItem,
  Box,
  List,
  Paper,
} from "@material-ui/core";
import { motion, AnimatePresence } from "framer-motion";
import {
  Add,
  Apps,
  Archive,
  Close,
  Edit,
  CloudUpload,
} from "@material-ui/icons";
import { LoadingButton } from "@material-ui/lab";
//
import Page from "src/components/Page";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Label from "../../../components/Label";
import { QuillEditor } from "../../../components/editor";
import { UploadMultiFile } from "../../../components/upload";
import { useDispatch, useSelector } from "react-redux";
import {
  uploadImage,
  deleteImage,
  saveCollection,
  editCollection,
  getCollectionsSingle,
  deleteCollection,
  archiveCollection,
  unarchiveCollection,
} from "../../../redux/actions";
import DateTimePicker from "src/components/_dashboard/DateTimePicket";

import { PATH_DASHBOARD } from "src/routes/paths";
import StoreSeo from "src/components/_dashboard/onlinestore/StoreSeo";
import ResourcePicker from "../../../components/_dashboard/media/ResourcePicker";
import { varFadeInRight } from "src/components/animate";
// import AllProducts from "src/components/_dashboard/products/AllProducts";
// ----------------------------------------------------------------------

CollectionForm.propTypes = {
  currentUser: PropTypes.object,
};

export default function CollectionForm({ isEdit, currentUser }) {
  const { _id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [deleteCol, setdeleteCol] = useState(false);
  const [archiveCol, setArchiveCol] = useState(false);
  const [seo, setSeo] = useState({});
  const [media, setMedia] = useState([]);
  const [openMediaDialog, setOpenMediaDialog] = useState(false);

  const isLoading = useSelector((state) => state.loader.loadingPayload);
  const collectionData = useSelector(
    (state) => state.collections.collectionSinglePayload
  );

  useEffect(() => {
    _id && dispatch(getCollectionsSingle(_id));
    if (!_id) {
      dispatch({
        type: "GET_COLLECTION_SINGLE",
        payload: {},
      });
    }
  }, []);

  useEffect(() => {
    if (collectionData?._id) {
      setMedia(collectionData?.images);
      setSeo(collectionData?.seo);
    } else {
      setMedia([]);
      setSeo("");
    }
  }, [collectionData]);

  const selectStatusOptions = [
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Draft",
      value: "draft",
    },
  ];

  const removeImage = (op) => {
    let val = media.map((value, index) => {
      if (op == index) {
        dispatch(deleteImage(value._id));
        return {
          ...value,
          selected: false,
        };
      }
      return value;
    });

    setMedia(val.filter((item) => item.selected != false));
  };

  var current = new Date();
  var followingDay = new Date(current.getTime() + 86400000);

  const NewUserSchema = Yup.object().shape({
    title: Yup.string().required("Collection Name is required field").max(100,"Collection Name must be at most 100 characters"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: collectionData?.title || "",
      desc: collectionData?.desc || "",
      type: collectionData?.type || "manu",
      condition: collectionData?.condition || "all",
      // avail_date: collectionData?.avail_date || followingDay,
      theme: collectionData?.theme || "",
      status: collectionData?.status || "active",
    },
    validationSchema: NewUserSchema,

    onSubmit: async (values, actions) => {
      try {
        dispatch(
          _id
            ? editCollection(
                _id,
                values,
                seo,
                media,
                () => {
                  navigate("/dashboard/collection/list");
                },
                setLoading
              )
            : saveCollection(
                values,
                seo,
                media,
                () => {
                  navigate("/dashboard/collection/list");
                },
                setLoading
              )
        );
      } catch (error) {
        actions.setSubmitting(false);
        actions.setErrors(error);
      }
    },
  });
  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
    handleChange,
  } = formik;

  return (
    <Page title={_id ? "Edit Collection" : "Create new collection"}>
      <Container>
        {/* heading */}
        <Stack direction="row" spacing={2} >
          <Button
            variant="outlined"
            style={{
              marginBottom: "16px",
              paddingTop: "5px",
              paddingBottom: "5px",
              minWidth: "0px",
              height: "43px",
              borderRadius: "5px",
            }}
            component={RouterLink}
            to={PATH_DASHBOARD.products.collections}
          >
            <img
              width="17px"
              src="https://th.bing.com/th/id/R.9562e3998adf90b7ea409f041aab337d?rik=ERFU3YLhocBkdA&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2fKin%2feBp%2fKineBpprT.png&ehk=t39BBIm39HbdTD3%2bBxa%2bIfTm948yrYikFKGQivAW9UU%3d&risl=&pid=ImgRaw&r=0"
            />
          </Button>
          {!_id ? (
            <HeaderBreadcrumbs
              heading="Create new Collection"
              links={[
                { name: "Dashboard", href: PATH_DASHBOARD.root },
                {
                  name: "Collections",
                  href: PATH_DASHBOARD.products.collections,
                },
                {
                  name: "Collection",
                  href: PATH_DASHBOARD.products.newCollection,
                },
                { name: "New" },
              ]}
            />
          ) : (
            <>
              {isLoading && <LinearProgress style={{ width: "100%" }} />}
              {!isLoading && (
                <Stack direction="row" spacing={2}>
                  <Typography
                    variant="h5"
                    style={{ marginTop: 5, marginRight: "10px" }}
                  >
                    {collectionData?.title}
                  </Typography>

                  <Chip
                    label={collectionData?.status}
                    size="large"
                    color={
                      collectionData?.status === "active"
                        ? "success"
                        : collectionData?.status === "draft"
                        ? "default"
                        : "error"
                    }
                    style={{ marginTop: "8px", height: "22px", textTransform:'capitalize' }}
                  />
                </Stack>
              )}
            </>
          )}
        </Stack>

        {/* Main Contain */}
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3} sx={{ mb: 3, mt: 1 }}>
              {/* right Side */}
              <Grid item xs={12} md={8}>
                {/* title desc */}
                <Card sx={{ p: 3 }}>
                  <Stack spacing={2}>
                    {/* title */}
                    <TextField
                      fullWidth
                      label="Collection Name"
                      value={values.title}
                      onChange={handleChange("title")}
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                    />

                    {/* desc */}
                    <label>Description</label>
                    <QuillEditor
                      simple
                      id="Collection-description"
                      value={values.desc}
                      onChange={handleChange("desc")}
                      error={Boolean(touched.desc && errors.desc)}
                    />
                    {touched.desc && errors.desc && (
                      <FormHelperText error sx={{ px: 2 }}>
                        {touched.desc && errors.desc}
                      </FormHelperText>
                    )}
                  </Stack>
                </Card>

                {/* image */}
                <Card sx={{ mt: 3, p: 3 }}>
                  <h4>Add collection Images</h4>

                  {openMediaDialog ? (
                    <ResourcePicker
                      multiple={false}
                      history={false}
                      preview={false}
                      onSelect={(selected) => {
                        if (selected) {
                          if (Array.isArray(media)) {
                            const joined = media?.concat(selected);
                            setMedia(joined);
                          } else {
                            setMedia(selected);
                          }
                          setOpenMediaDialog(false);
                        }
                      }}
                      onClose={() => setOpenMediaDialog(false)}
                    ></ResourcePicker>
                  ) : (
                    <></>
                  )}
                  <Box
                    onClick={() => setOpenMediaDialog(true)}
                    sx={{
                      width: "100%",
                      height: 150,
                      border: "1px dashed",
                      borderRadius: "10px",
                      borderColor: "#00800091",
                      padding: 7,
                      marginTop: "10px",
                      cursor: "pointer",
                      backgroundColor: "#29a9291c",
                    }}
                  >
                    <center style={{ marginTop: "-18px" }}>
                      <CloudUpload />
                      <br></br>
                      <Button>Upload Image</Button>
                    </center>
                  </Box>
                  {media?.length > 0 && (
                    <List disablePadding sx={{ ...{ my: 3 } }}>
                      <AnimatePresence>
                        {media?.map((image, index) => {
                          return (
                            <>
                              <ListItem
                                key={image._id}
                                component={motion.div}
                                {...varFadeInRight}
                                sx={{
                                  p: 0,
                                  m: 0.5,
                                  width: 129,
                                  height: 129,
                                  borderRadius: 1.5,
                                  overflow: "hidden",
                                  position: "relative",
                                  display: "inline-flex",
                                }}
                              >
                                <Paper
                                  variant="outlined"
                                  component="img"
                                  // src={isString(file) ? file : preview}
                                  src={image?.url}
                                  sx={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    position: "absolute",
                                  }}
                                />
                                <Box
                                  sx={{
                                    top: 6,
                                    right: 6,
                                    position: "absolute",
                                  }}
                                >
                                  <Close
                                    onClick={() => removeImage(index)}
                                    color="primary"
                                    inputProps={{
                                      "aria-label": "secondary checkbox",
                                    }}
                                    sx={{
                                      backgroundColor: "#ffffffc9",
                                      cursor: "pointer",
                                    }}
                                  />
                                </Box>
                              </ListItem>
                            </>
                          );
                        })}
                      </AnimatePresence>
                    </List>
                  )}
                </Card>

                {/* Search Engine SEO */}

                <StoreSeo
                  values={values}
                  setFieldValue={setFieldValue}
                  seo={seo}
                  setSeo={setSeo}
                  collection={"collection"}
                />

                {/* archive and delete buttons */}
                {_id && (
                  <>
                    <Divider style={{ marginTop: 20, width: "150%" }} />
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      spacing={3}
                      sx={{
                        marginTop: 5,
                        marginLeft: 3,
                        right: 0,
                      }}
                    >
                      <LoadingButton
                        variant="outlined"
                        color="error"
                        size="large"
                        onClick={() => (
                          setArchiveCol(true), setArchiveCol(collectionData)
                        )}
                      >
                        {values?.status != "archive"
                          ? "Archive Collection"
                          : "Unarchive Collection"}
                      </LoadingButton>

                      <LoadingButton
                        sx={{ ml: 2 }}
                        variant="outlined"
                        size="large"
                        loading={loading}
                        onClick={() => (
                          setdeleteCol(true), setdeleteCol(collectionData)
                        )}
                      >
                        Delete Collection
                      </LoadingButton>
                    </Grid>
                  </>
                )}
              </Grid>

              {/* left Side */}
              <Grid item xs={12} md={4}>
                <Stack spacing={3}>
                  {/* status */}
                  <Card sx={{ p: 3 }}>
                    <h5>Collection Status</h5>
                    <Stack sx={{ mt: 2 }} spacing={3}>
                      {values?.status == "archive" ? (
                        <Alert
                          variant="outlined"
                          icon={<Archive style={{ color: "black" }} />}
                          style={{
                            backgroundColor: "#f4f6f8",
                            border: "solid 1px #d7dce09c",
                            color: "black",
                          }}
                        >
                          <h4>Archived</h4>
                          Hidden from your Planet Shopping admin, except your
                          Collection list.
                          <br></br>
                          <Button
                            variant="outlined"
                            style={{
                              marginTop: 10,
                              border: "solid 1px #bfbdbd",
                              color: "black",
                            }}
                            onClick={() => (
                              setArchiveCol(true), setArchiveCol(collectionData)
                            )}
                          >
                            Unarchive Collection
                          </Button>
                        </Alert>
                      ) : (
                        <FormControl fullWidth>
                          <Select
                            value={values.status}
                            {...getFieldProps("status")}
                            error={Boolean(touched.status && errors.status)}
                            helperText={touched.status && errors.status}
                          >
                            {selectStatusOptions.map((option) => {
                              return (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                  defaultValue={option.value == "Active"}
                                >
                                  {option.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      )}
                    </Stack>
                  </Card>

                  {/* type */}
                  <Card sx={{ mt: 3, p: 3 }}>
                    <h4>Collection Type</h4>

                    <FormControl sx={{ mt: 1 }}>
                      <RadioGroup
                        defaultValue="manu"
                        value={values.comment}
                        {...getFieldProps("type")}
                      >
                        <FormControlLabel
                          value="manu"
                          control={<Radio />}
                          label="Manual"
                        />
                        <Typography
                          style={{
                            color: "#808080bd",
                            marginLeft: 26,
                            fontSize: 14,
                            marginTop: -8,
                          }}
                        >
                          Add Collections to this collection one by one.
                        </Typography>
                        {/* <FormControlLabel
                          value="auto"
                          control={<Radio />}
                          label="Automated"
                        />
                        <Typography
                          style={{
                            color: "#808080bd",
                            marginLeft: 26,
                            fontSize: 14,
                            marginTop: -8,
                          }}
                        >
                          Existing and future Collections that match the
                          conditions you set will automatically be added to this
                          collection.
                        </Typography> */}
                      </RadioGroup>
                    </FormControl>

                    {/* {values.type == "auto" && (
                    <>
                      <Divider sx={{ mt: 2, mb: 2 }} />
                      <h4 sx={{ mt: 2, mb: 2 }}>CONDITIONS</h4>
                      <Stack spacing={2} direction="row">
                        <Typography sx={{ mt: 1, mb: 2 }}>
                          Collections must match:
                        </Typography>

                        <FormControl sx={{ mt: 1 }} direction="row">
                          <RadioGroup
                            row
                            value={values.comment}
                            {...getFieldProps("condition")}
                          >
                            <FormControlLabel
                              value="all"
                              control={<Radio />}
                              label="all conditions"
                            />

                            <FormControlLabel
                              value="any"
                              control={<Radio />}
                              label="any conditions"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Stack>
                    </>
                  )} */}
                  </Card>

                  {/* date */}
                  {/* <Card sx={{ p: 3, overflow: "visible" }}>
                    
                    <DateTimePicker
                      selected={values.avail_date}
                      onChange={(date) => setFieldValue("avail_date", date)}
                      label="Available Date"
                      minDate={new Date()}
                    />
                  </Card> */}
                </Stack>
              </Grid>

              {/* button */}
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={3}
                sx={{
                  backgroundColor: "#FFF",
                  padding: "15px 140px",
                  position: "fixed",
                  bottom: 0,
                  left: 0,
                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => navigate("/dashboard/collection/list")}
                >
                  Cancel
                </Button>
                <LoadingButton
                  sx={{ ml: 2 }}
                  variant="contained"
                  loading={loading}
                  onClick={handleSubmit}
                >
                  {_id ? "Save Changes" : "Create Collection"}
                </LoadingButton>
              </Grid>
            </Grid>

            {/* delete */}
            <Dialog open={deleteCol} onClose={() => setdeleteCol(false)}>
              <DialogTitle id="alert-dialog-title">
                Delete {""}
                {deleteCol?.title}?
              </DialogTitle>
              <IconButton
                onClick={() => setdeleteCol(false)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 10,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Close />
              </IconButton>
              <Divider />
              <DialogContent>
                <DialogContentText>
                  <p>
                    Are you sure you want to delete the Collection{" "}
                    {deleteCol?.title}? This can’t be undone.
                  </p>
                </DialogContentText>
              </DialogContent>{" "}
              <Divider />
              <DialogActions>
                <Button variant="outlined" onClick={() => setdeleteCol(false)}>
                  Close
                </Button>
                <LoadingButton
                  variant="contained"
                  onClick={() => {
                    dispatch(
                      deleteCollection(
                        deleteCol?._id,
                        () => {
                          navigate("/dashboard/collection/list");
                        },
                        setLoading
                      )
                    );
                    setdeleteCol(false);
                  }}
                  loading={loading}
                >
                  Delete
                </LoadingButton>
              </DialogActions>
            </Dialog>

            {/* archive */}
            <Dialog
              open={archiveCol}
              onClick={() => setArchiveCol(false)}
              maxWidth="sm"
            >
              <DialogTitle>
                {values?.status != "archive"
                  ? "Archive Collection"
                  : "Unarchive Collection"}

                <IconButton
                  aria-label="close"
                  onClick={() => setArchiveCol(false)}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 10,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <Close />
                </IconButton>
              </DialogTitle>
              <Divider style={{ marginTop: 10 }} />
              <DialogContent>
                <DialogContentText>
                  {values?.status != "archive" ? (
                    <p>
                      Archiving this Collection will hide it from your sales
                      channels and Planet Shopping admin. You’ll find it using
                      the status filter in your Collection list.
                    </p>
                  ) : (
                    <p>
                      Unarchiving this Collection will change its status to
                      draft so you can work on it again.
                    </p>
                  )}
                </DialogContentText>
              </DialogContent>
              <Divider />
              <DialogActions>
                <Button variant="outlined" onClick={() => setArchiveCol(false)}>
                  Close
                </Button>
                <LoadingButton
                  variant="contained"
                  onClick={() => {
                    values?.status != "archive"
                      ? dispatch(archiveCollection(archiveCol?._id, setLoading))
                      : dispatch(
                          unarchiveCollection(archiveCol?._id, setLoading)
                        );
                  }}
                  loading={loading}
                >
                  {values?.status != "archive" ? "Archive" : "Unarchive"}
                </LoadingButton>
              </DialogActions>
            </Dialog>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
