import React from "react";
import { Typography, Divider, Link } from "@material-ui/core";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { PATH_DASHBOARD } from "src/routes/paths";

RefundTimeline.propTypes = {
  refund: PropTypes.object,
};

export default function RefundTimeline(props) {
  const { refund } = props;
  const navigate = useNavigate();

  const store = useSelector((state) => state.auth.profilePayload);
  return (
    <>
      <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
        Order
      </Typography>
      <Link style={{ cursor: "pointer" }}>#{refund?.ordNo}</Link>
      <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
        Refunded by
      </Typography>
      <Link
        style={{ cursor: "pointer", fontSize: "14px" }}
        onClick={() =>
          navigate(`${PATH_DASHBOARD.customers.edit}/${refund?.rby?._id}`)
        }
      >
        {refund?.rby?.overview?.fName} {refund?.rby?.overview?.lName}
      </Link>
      <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
        Amount
      </Typography>
      <Typography variant="body2">
        {store?.currency?.symbol} {refund?.rAmt}
      </Typography>
      <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
        Refunded items
      </Typography>
      {refund?.items?.map((value) => {
        return (
          <>
            <Typography variant="body2">
              {value?.qty} x{" "}
              {value?.variant?.title
                ? value?.product?.title +
                  " " +
                  "/" +
                  " " +
                  value?.variant?.title
                : value?.product?.title}
            </Typography>
          </>
        );
      })}
    </>
  );
}
