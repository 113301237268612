import { toast } from "react-toastify";
import ApiExt from "../Provider/ApiExt";
import moment from "moment";

//Get All Addresses
export function Salesbyproduct(values) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });
    const data = {
      currentsDate: moment(values[0]?.startDate).format("DD/MM/YYYY").toString(),
      currenteDate: moment(values[0]?.endDate).format("DD/MM/YYYY").toString(),
    };
    ApiExt.post("/api/report/Salesbyproduct", data)
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "SALES_BY_PRODUCTS",
            payload: response.data.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

export function SalesbyproductExport(values, callback, setLoading) {
  return async function (dispatch) {
    setLoading(true);
    const data = {
      currentsDate: moment(values[0].startDate).format("DD/MM/YYYY").toString(),
      currenteDate: moment(values[0].endDate).format("DD/MM/YYYY").toString(),
    };
    ApiExt.post("/api/report/Salesbyproduct", data)
      .then(function (response) {
        setLoading(false);
        if (response.data.status === true) {
          callback(response.data.data);
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

export function SalesbyproductVariant(values) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });
    const data = {
      currentsDate: moment(values[0]?.startDate)
        .format("DD/MM/YYYY")
        .toString(),
      currenteDate: moment(values[0]?.endDate).format("DD/MM/YYYY").toString(),
    };
    ApiExt.post("/api/report/SalesbyproductvariantSKU", data)
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          console.log(response.data.data);
          dispatch({
            type: "SALES_BY_PRODUCTS_VARIANT",
            payload: response.data.data,
          });
        } else {
          dispatch({
            type: "IS_LOADING",
            payload: false,
          });
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        toast.error("Something went wrong. Please try again.");
      });
  };
}

export function ExportSalesbyproductVariant(values, callback, setLoading) {
  return async function (dispatch) {
    setLoading(true);
    const data = {
      currentsDate: moment(values[0].startDate).format("DD/MM/YYYY").toString(),
      currenteDate: moment(values[0].endDate).format("DD/MM/YYYY").toString(),
    };
    ApiExt.post("/api/report/SalesbyproductvariantSKU", data)
      .then(function (response) {
        setLoading(false);
        if (response.data.status === true) {
          callback(response.data.data);
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

export function SalesbyproductVendor(values) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });
    const data = {
      currentsDate: moment(values[0].startDate).format("DD/MM/YYYY").toString(),
      currenteDate: moment(values[0].endDate).format("DD/MM/YYYY").toString(),
    };
    ApiExt.post("/api/report/Salesbyproductvendor", data)
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "SALES_BY_PRODUCTS_VENDOR",
            payload: response.data.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

export function SalesbyproductVendorExport(values, callback, setLoading) {
  return async function (dispatch) {
    setLoading(true);
    const data = {
      currentsDate: moment(values[0].startDate).format("DD/MM/YYYY").toString(),
      currenteDate: moment(values[0].endDate).format("DD/MM/YYYY").toString(),
    };
    ApiExt.post("/api/report/Salesbyproductvendor", data)
      .then(function (response) {
        setLoading(false);
        if (response.data.status === true) {
          callback(response.data.data);
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

export function Avgordervalue(today, compare) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });
    const data = {
      currentsDate:
        today?.length > 0
          ? moment(today[0]?.startDate).format("DD/MM/YYYY").toString()
          : "",
      currenteDate:
        today?.length > 0
          ? moment(today[0]?.endDate).format("DD/MM/YYYY").toString()
          : "",
      comparesDate:
        compare?.length > 0
          ? moment(compare[0].startDate).format("DD/MM/YYYY").toString()
          : "",
      compareeDate:
        compare?.length > 0
          ? moment(compare[0].endDate).format("DD/MM/YYYY").toString()
          : "",
    };
    ApiExt.post("/api/analytics/average_order_value", data)
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "AVG_ORDER_VALUE",
            payload: response.data.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

export function ExportAvgordervalue(today, compare, callback, setLoading) {
  return async function (dispatch) {
    setLoading(true);
    const data = {
      currentsDate:
        today?.length > 0
          ? moment(today[0]?.startDate).format("DD/MM/YYYY").toString()
          : "",
      currenteDate:
        today?.length > 0
          ? moment(today[0]?.endDate).format("DD/MM/YYYY").toString()
          : "",
      comparesDate:
        compare?.length > 0
          ? moment(compare[0].startDate).format("DD/MM/YYYY").toString()
          : "",
      compareeDate:
        compare?.length > 0
          ? moment(compare[0].endDate).format("DD/MM/YYYY").toString()
          : "",
    };
    ApiExt.post("/api/analytics/average_order_value", data)
      .then(function (response) {
        setLoading(false);
        if (response.data.status === true) {
          callback(response.data.data)
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

export function TotalSale(today, compare) {
  
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });
    const data = {
      currentsDate: moment(today[0]?.startDate).format("DD/MM/YYYY").toString(),
      currenteDate: moment(today[0]?.endDate).format("DD/MM/YYYY").toString(),
      comparesDate: compare?.[0]
        ? moment(compare[0]?.startDate).format("DD/MM/YYYY").toString()
        : "",
      compareeDate: compare?.[0]
        ? moment(compare[0]?.endDate).format("DD/MM/YYYY").toString()
        : "",
    };
    ApiExt.post("/api/analytics/totalSale", data)
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response?.data?.status === true) {
          dispatch({
            type: "TOTAL_SALES",
            payload: response?.data?.data,
          });
        } else {
          dispatch({
            type: "IS_LOADING",
            payload: false,
          });
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        toast.error("Something went wrong. Please try again.");
      });
  };
}

export function ExportTotalSale(today, compare, setLoading, callback) {
  return async function (dispatch) {
    setLoading(true);
    const data = {
      currentsDate: moment(today[0].startDate).format("DD/MM/YYYY").toString(),
      currenteDate: moment(today[0].endDate).format("DD/MM/YYYY").toString(),
      comparesDate: compare?.[0]
        ? moment(compare[0].startDate).format("DD/MM/YYYY").toString()
        : "",
      compareeDate: compare?.[0]
        ? moment(compare[0].endDate).format("DD/MM/YYYY").toString()
        : "",
    };
    ApiExt.post("/api/analytics/totalSale", data)
      .then(function (response) {
        setLoading(false);
        if (response.data.status === true) {
          callback(response.data.data);
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

export function TotalOrder(today, compare) {
  
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });
    const data = {
      currentsDate: moment(today[0]?.startDate).format("DD/MM/YYYY").toString(),
      currenteDate: moment(today[0]?.endDate).format("DD/MM/YYYY").toString(),
      comparesDate: compare?.[0]
        ? moment(compare[0]?.startDate).format("DD/MM/YYYY").toString()
        : "",
      compareeDate: compare?.[0]
        ? moment(compare[0]?.endDate).format("DD/MM/YYYY").toString()
        : "",
    };
    ApiExt.post("/api/analytics/totalOrder", data)
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response?.data?.status === true) {
          dispatch({
            type: "TOTAL_ORDER",
            payload: response?.data?.data,
          });
        } else {
          dispatch({
            type: "IS_LOADING",
            payload: false,
          });
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        toast.error("Something went wrong. Please try again.");
      });
  };
}

export function ExportTotalOrder(today, compare, setLoading, callback) {
  
  return async function (dispatch) {
    setLoading(true);
    const data = {
      currentsDate: moment(today[0]?.startDate).format("DD/MM/YYYY").toString(),
      currenteDate: moment(today[0]?.endDate).format("DD/MM/YYYY").toString(),
      comparesDate: compare?.[0]
        ? moment(compare[0]?.startDate).format("DD/MM/YYYY").toString()
        : "",
      compareeDate: compare?.[0]
        ? moment(compare[0]?.endDate).format("DD/MM/YYYY").toString()
        : "",
    };
    ApiExt.post("/api/analytics/totalOrder", data)
      .then(function (response) {
        setLoading(false);
        if (response?.data?.status === true) {
          callback(response?.data?.data);
        } else {
          setLoading(false);
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}