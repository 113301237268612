const initialState = { submitPayload: false }

export default function (state = initialState, action) {
    switch (action.type) {
       case 'IS_IMAGE_LOADING':
          {
             return {
                ...state,
                loadingPayload: action.payload
             };
          }
       default:
          return state;
    }
 }
 