import { toast } from "react-toastify";
import ApiExt from "../Provider/ApiExt";

// Bills
export function getAllBills(values) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    ApiExt.post("/api/settings/get-billing", values)
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_ALL_BILLING",
            payload: response?.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// download Bill
export function downloadBill(id) {
  return async function (dispatch) {
    fetch(
      "https://api.planetshopping.com/public/download-bill/" + id,
      // 'https://api.easybhojan.com/public/download-invoice/' + id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `bill.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };
}

// update currency
export function updateCurrency(currency, setLoading) {
  return async function (dispatch) {
    setLoading(true);
    ApiExt.post("/api/store/currency-update", { currency: currency._id })
      .then(function (response) {
        setLoading(false);
        console.log("response", response);
        if (response.data.status) {
          toast.success("Currency Updated");
          dispatch({
            type: "UPDATE_CURRENCY",
            payload: currency,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Plans
export function getAllPlans(values) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    ApiExt.post("/api/settings/get-my-plan", values)
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_MY_PLAN",
            payload: response?.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Plans
export function getPlans(values) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    ApiExt.post("/api/settings/get-plans", values)
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_PLANS",
            payload: response?.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

export function updatePlan(planId, callback, setLoading) {
  return async function (dispatch) {
    setLoading(true);

    ApiExt.post("/api/settings/update-plan", { planId: planId })
      .then(function (response) {
        console.log(response);
        setLoading(false);

        if (response.data.status) {
          dispatch({
            type: "UPDATE_PLAN",
            payload: planId,
          });
          if (response.data.url) {
            window.location = response.data.url;
          }
          toast.success(response?.data?.msg);
          callback(response.data);
        } else {
          callback(response.data);
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Get Brand
export function getBrand(values) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    ApiExt.post("/api/settings/get-brand", values)
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_BRAND",
            payload: response?.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}

// Update Brand
export function updateBrand(values, setLoading, callback) {
  return async function (dispatch) {
    setLoading(true);
    // dispatch({
    //   type: "IS_LOADING",
    //   payload: true,
    // });

    const value = {
      data: {
        brand: {
          logo: values.logo?._id,
          defaultLogo: values.defaultLogo?._id,
          primary: {
            color: values.primaryColor,
            contrast: values.primaryContrast,
          },
          secondary1: {
            color: values.secondaryColor1,
            contrast: values.secondaryContrast,
          },
          secondary2: values.secondaryColor2,
          coverImg: values.coverImg?._id,
          slogan: values.slogan,
          desc: values.desc,
          links: values.link?.length > 0 ? values.link : [],
        },
      },
    };

    ApiExt.post("/api/settings/update-brand", value)
      .then(function (response) {
        setLoading(false);

        // dispatch({
        //   type: "IS_LOADING",
        //   payload: false,
        // });
        if (response.data.status === true) {
          toast.success(response?.data?.msg);
          dispatch({
            type: "UPDATE_BRAND",
            payload: value,
          });
          callback();
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        // dispatch({
        //   type: "IS_LOADING",
        //   payload: false,
        // });

        // toast.error("Something went wrong. Please try again.");
      });
  };
}

// Get Social Links
export function getSocialLinks(values) {
  return async function (dispatch) {
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    ApiExt.post("/api/app/social-link", values)
      .then(function (response) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          dispatch({
            type: "GET_SOCIAL_LINKS",
            payload: response?.data?.data,
          });
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        toast.error("Something went wrong. Please try again.");
      });
  };
}
