import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router";
// material
import { Container, Box } from '@material-ui/core';
//
import EditorSidebar from './EditorSidebar';
import DocsNavbar from './DocsNavbar';
import { useDispatch, useSelector } from "react-redux";
import {
  getEditorPage,
  getEditorSchema,
  getPageSchema,
  getSettingsSchema
} from "../../redux/actions";

import { createPortal } from 'react-dom'
import NoInternet from "src/components/NoInternet";
import NotistackProvider from "src/components/NotistackProvider";
// ----------------------------------------------------------------------

const page_link = {
  "discount": "discount",
  "collections": "collection",
  "single-collection": "collection/",
  "products": "product",
  "single-products": "product/",
  "blogs": "blogs",
  "single-blog": "blog/",
  "login": "auth/login",
  "register": "auth/register",
  "index": ""
}


export default function EditorLayout() {
  // state variable holds the state of the internet connection
  const [isOnline, setIsOnline] = useState(true);
  const { pathname } = useLocation();
  const _paths = pathname.split('/');
  const id = _paths[3];
  const [page, setPage] = useState({ page: _paths[4] || 'index', url: '' });
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.profilePayload);
  const editorData = useSelector(state => state.storepage.pageEditorData);
  const editorSection = useSelector(state => state.storepage.pageEditorSection);


  const link = `https://${user?.domain}/${page?.url || ""}?themeid=${id}`;

  const authToken = localStorage.getItem('accessToken');


  const [open, setOpen] = useState(false);
  const [sidebar, setSidebar] = useState(<></>);
  const [random, setRandom] = useState(0);
  const [frameElement, setFrameElement] = React.useState(null);
  const [templateHtml, setTemplateHtml] = React.useState(null);
  const [contentRef, setContentRef] = useState(navigator.onLine)
  const isLoading = useSelector((state) => state.loader.loadingPayload);

  function onlineHandler() {
    setIsOnline(true);
  }

  function offlineHandler() {
    setIsOnline(false);
  }

  useEffect(() => {
    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);
  }, []);

  useEffect(() => {
    onPageChange(page);
    dispatch(
      getPageSchema(user?.domain, id, authToken, (schemaData) => {
      })
    );
    dispatch(
      getSettingsSchema(user?.domain, id, authToken, (schemaData) => {
      })
    )
    //var frameDoc = contentRef.contentWindow.document;
    // frameDoc.write('<html><body style="margin:0px;"><div><script type="text/javascript" src="..."></script></div> ... more html')
  }, [contentRef]);

  const onPageChange = (page) => {
    setPage(page);
    dispatch(
      getEditorPage(user?.domain, id, page?.page || "index", authToken, (data) => {
      })
    );
    dispatch(
      getEditorSchema(user?.domain, id, authToken, (schemaData) => {
      })
    );
  }

  useEffect(() => {
    if (editorData && contentRef) {
      setSidebar(<EditorSidebar page={page} frameRef={contentRef} onCloseSidebar={() => setOpen(false)} isOpenSidebar={open} data={editorData} themeId={id} onUpdate={() => { setRandom(random + 1) }} />);
    }
  }, [editorData]);

  // useEffect(() => {
  //   //const script = 'function sectionSelector(id){$("*").removeClass("sectionSelector"); $(id).addClass("sectionSelector");}';
  //   //contentRef?.contentWindow?.document?.head?.appendChild(script);
  //   //console.log('e.target', contentRef?.contentWindow?.alert('#sticky-menu'));
  // }, [contentRef]);

  // const handleFrameElement = React.useCallback(e => {
  //   setFrameElement(e.target);
  //   //e.target.contentWindow.postMessage('<p>dfdfd</p>');
  //   console.log('e.target', e.target);
  // }, []);

  // React.useEffect(() => {
  //   console.log(
  //     "HOOK ",
  //     frameElement?.contentDocument?.body?.innerHTML?.length
  //   );
  // }, [frameElement]);

  const click = () => {
    //console.log("ref",contentRef);
    //console.log('e.target', contentRef?.contentWindow?.postMessage('#sticky-menu', 'https://valgardenashop.myplanetshopping.com'));
  }

  const handleFrameOnLoad = (content) => {
    // console.log('Iframe', content.target);
    // console.log('load Iframe', content.target.contentWindow.location);
    contentRef?.contentWindow?.postMessage(`${editorSection}`, `https://${user?.domain}`);
  }

  return (

    <NotistackProvider>
      {isOnline ? (
        <>
          <DocsNavbar isLoading={isLoading} onOpenSidebar={() => setOpen(true)} themeId={id} page={page} onPageChange={(page) => onPageChange(page)} />
          <Box sx={{ display: 'flex', minHeight: '100%', overflow: 'hidden' }} onClick={() => click()}>
            {sidebar}
            {user?.domain &&
              <div style={{ marginTop: "80px", minHeight: "100%", overflow: 'hidden', width: '100%' }} >
                <iframe key={random} src={link} ref={setContentRef} frameBorder={0} width="100%" height="100%" id="iframe"
                  //sandbox="allow-scripts allow-same-origin allow-modal"
                  //sandbox='allow-scripts'
                  //sandbox=""
                  onLoad={handleFrameOnLoad}
                >
                </iframe>
              </div>
            }
          </Box >
        </>
      ) : <NoInternet />
      }
    </NotistackProvider>
  );
}