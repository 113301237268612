import React from "react";
import { Typography, Link } from "@material-ui/core";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

MarkPaidTimeline.propTypes = {
  paid: PropTypes.object,
};

export default function MarkPaidTimeline(props) {
  const { paid } = props;
  const store = useSelector((state) => state.auth.profilePayload);
  return (
    <>
      <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
        Order
      </Typography>
      <Link style={{ cursor: "pointer" }}>#{paid?.ordNo}</Link>
      <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
        Message
      </Typography>
      <Typography variant="body2">{paid?.msg}</Typography>
      <Typography style={{ fontWeight: "bold", fontSize: "13px" }}>
        Amount
      </Typography>
      <Typography variant="body2">
        {store?.currency?.symbol} {paid?.amt}
      </Typography>
      
    </>
  );
}
