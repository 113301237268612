const initialState = {
   messagePayload: { 
      type: "", 
      status: false, 
      text: "" 
   }}

export default function (state = initialState, action) {
   switch (action.type) {

      case 'MESSAGE':
         {
            return {
               ...state,
               messagePayload: action.payload
            };
         }
      default:
         return state;
   }
}
