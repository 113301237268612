import {
  Stack,
  Box,
  Card,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Paper,
  Container,
  Button,
  Typography,
  Popover,
  LinearProgress,
  Skeleton,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import PrintComponent from "../Export-analytics-report/PrintComponent";
import { useDispatch, useSelector } from "react-redux";
import { PATH_DASHBOARD } from "src/routes/paths";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { Avgordervalue } from "src/redux/actions/AnalyticsAction";
import { Today } from "@material-ui/icons";
import { fDate } from "src/utils/formatTime";
import ReactApexChart from "react-apexcharts";
import { merge } from "lodash";
import { BaseOptionChart } from "src/components/charts";
import DatePicker from "../DateTimePicket/DatePicker";
import { addDays } from "date-fns";
import moment from "moment/moment";
import AvgOrdvalExport from "../Export-analytics-report/AvgOrdvalExport";
import Page from "src/components/Page";

export default function AvgOrdValReport() {
  const componentRef = useRef();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const isLoading = useSelector((state) => state.loader.loadingPayload);
  const orders = useSelector((state) => state.analytics.avgOrderValuePayload);
  const store = useSelector((state) => state.auth.profilePayload);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [openToday, setOpenToday] = useState(false);
  const [openCompare, setOpenCompare] = useState(false);
  const [today, setToday] = useState([
    {
      startDate: addDays(new Date(), -30),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [labels, setLabels] = useState([
    "01/01/2003",
    "02/01/2003",
    "03/01/2003",
    "04/01/2003",
    "05/01/2003",
    "06/01/2003",
    "07/01/2003",
    "08/01/2003",
    "09/01/2003",
    "10/01/2003",
    "11/01/2003",
  ]);
  const [compare, setCompare] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [comanchorEl, setComAnchorEl] = useState(null);
  const [todayData, setTodayData] = useState();
  const [compareData, setCompareData] = useState();
  const [todaySummary, setTodaySummary] = useState({
    ordreCount: 0,
    total: 0,
    discount: 0,
    grossAmt: 0,
    avg: 0,
  });
  const [compareSummary, setCompareSummary] = useState({
    ordreCount: 0,
    total: 0,
    discount: 0,
    grossAmt: 0,
    netAmt: 0,
    shipping: 0,
    tax: 0,
  });
  const [handleExport, sethandleExport] = useState(false);
  const [dataforImport, setDataforImport] = useState();

  // -----------UseEffect

  useEffect(() => {
    if (searchParams?.get("since")) {
      setToday([{
        startDate: new Date(searchParams?.get("since")),
        endDate: new Date(searchParams?.get("until")),
        key: "selection",
      }]);
    }
    else {
      const comdate = calculateCompare();
      dispatch(Avgordervalue(today, comdate));
    }
  }, []);

  useEffect(() => {
    const comdate = calculateCompare();
    dispatch(Avgordervalue(today, comdate));
  }, [today])

  const calculateCompare = () => {
    let Time = today[0].endDate.getTime() - today[0].startDate.getTime();
    let Days = Time / (1000 * 3600 * 24);
    const comdate = [
      {
        startDate:
          Days == 0.999999988425926 || Days == 0
            ? addDays(new Date(today[0].startDate), -1)
            : addDays(new Date(today[0].startDate), -(Days + 1)),
        endDate: addDays(new Date(today[0].startDate), -1),
        key: "selection",
      },
    ];
    setCompare(comdate);
    return comdate;
  }

  useEffect(() => {
    const todayArray = [];
    const compareArray = [];

    const todayloop = orders?.today?.map((op) => {
      var Difference_In_Time =
        today[0].endDate.getTime() - today[0].startDate.getTime();
      var Days_Calcualtion = Difference_In_Time / (1000 * 3600 * 24);
      if (Days_Calcualtion == 0.999999988425926 && Days_Calcualtion == 0) {
        var convertDate = moment(op._id)
          .format("MMM D YYYY hh:mm A")
          .toLocaleString();
      } else if (Days_Calcualtion > 0 && Days_Calcualtion <= 90) {
        var convertDate = moment(op._id).format("MMM D YYYY");
      } else if (Days_Calcualtion > 90) {
        var convertDate = moment(op._id).format("MMM YYYY");
      }
      return {
        ...op,
        day: convertDate,
      };
    });

    const todayLabel = orders?.todayLabel?.map((label) => {
      const findToday = todayloop.find((val) => val.day == label);
      if (findToday) {
        if (findToday.day == label) {
          todayArray.push({
            day: label,
            ordreCount: findToday.ordreCount,
            total: findToday.total,
            discount: findToday.discount,
            grossAmt: findToday.grossAmt,
            avg: findToday.avg,
          });
          todaySummary.ordreCount += findToday.ordreCount;
          todaySummary.total += findToday.total;
          todaySummary.discount += findToday.discount;
          todaySummary.grossAmt += findToday.grossAmt;
          todaySummary.avg += findToday.avg;
        }
      } else {
        todayArray.push({
          day: label,
          ordreCount: 0.0,
          total: 0.0,
          discount: 0.0,
          grossAmt: 0.0,
          avg: 0.0,
        });
        todaySummary.ordreCount += 0;
        todaySummary.total += 0;
        todaySummary.discount += 0;
        todaySummary.grossAmt += 0;
        todaySummary.avg += 0;
      }

      var Time = today[0].endDate.getTime() - today[0].startDate.getTime();
      var Days = Time / (1000 * 3600 * 24);
      if (Days == 0.999999988425926 && Days == 0) {
        var convertstartDate = moment(today[0].startDate)
          .format("MMM D YYYY hh:mm A")
          .toLocaleString();
        var convertendDate = moment(today[0].endDate)
          .format("MMM D YYYY hh:mm A")
          .toLocaleString();
      } else if (Days > 0 && Days <= 90) {
        var convertstartDate = moment(today[0].startDate)
          .format("MMM D YYYY")
          .toLocaleString();
        var convertendDate = moment(today[0].endDate)
          .format("MMM D YYYY")
          .toLocaleString();
      } else if (Days > 90) {
        var convertstartDate = moment(today[0].startDate)
          .format("MMM YYYY")
          .toLocaleString();
        var convertendDate = moment(today[0].endDate)
          .format("MMM YYYY")
          .toLocaleString();
      }
      todaySummary.startDate = convertstartDate;
      todaySummary.endDate = convertendDate;
    });

    const compareloop = orders?.comapre?.map((com) => {
      var Difference_In_Time =
        compare?.[0]?.endDate.getTime() - compare?.[0]?.startDate.getTime();
      var Days_Calcualtion = Difference_In_Time / (1000 * 3600 * 24);
      if (Days_Calcualtion == 0.999999988425926 && Days_Calcualtion == 0) {
        var convertDate = moment(com._id).format("MMM D YYYY hh:mm A");
      } else if (Days_Calcualtion > 0 && Days_Calcualtion <= 90) {
        var convertDate = moment(com._id).format("MMM D YYYY");
      } else if (Days_Calcualtion > 90) {
        var convertDate = moment(com._id).format("MMM YYYY");
      }
      return {
        ...com,
        day: convertDate,
      };
    });

    const compareLabel = orders?.compareLabel?.map((comlabel) => {
      const findCompare = compareloop.find((item) => item.day == comlabel);
      if (findCompare) {
        if (findCompare.day == comlabel) {
          compareArray.push({
            day: comlabel,
            ordreCount: findCompare.ordreCount,
            total: findCompare.total,
            discount: findCompare.discount,
            grossAmt: findCompare.grossAmt,
            avg: findCompare.avg,
          });
          compareSummary.ordreCount += findCompare.ordreCount;
          compareSummary.total += findCompare.total;
          compareSummary.discount += findCompare.discount;
          compareSummary.grossAmt += findCompare.grossAmt;
          compareSummary.avg += findCompare.avg;
        }
      } else {
        compareArray.push({
          day: comlabel,
          ordreCount: 0.0,
          total: 0.0,
          discount: 0.0,
          grossAmt: 0.0,
          avg: 0.0,
        });
        compareSummary.ordreCount += 0;
        compareSummary.total += 0;
        compareSummary.discount += 0;
        compareSummary.grossAmt += 0;
        compareSummary.avg += 0;
      }

      var Time = compare?.[0].endDate.getTime() - compare?.[0].startDate.getTime();
      var Days = Time / (1000 * 3600 * 24);
      if (Days == 0.999999988425926 && Days == 0) {
        var convertstartDate = moment(compare[0].startDate)
          .format("MMM D YYYY hh:mm A")
          .toLocaleString();
        var convertendDate = moment(compare[0].endDate)
          .format("MMM D YYYY hh:mm A")
          .toLocaleString();
      } else if (Days > 0 && Days <= 90) {
        var convertstartDate = moment(compare[0].startDate)
          .format("MMM D YYYY")
          .toLocaleString();
        var convertendDate = moment(compare[0].endDate)
          .format("MMM D YYYY")
          .toLocaleString();
      } else if (Days > 90) {
        var convertstartDate = moment(compare[0].startDate)
          .format("MMM YYYY")
          .toLocaleString();
        var convertendDate = moment(compare[0].endDate)
          .format("MMM YYYY")
          .toLocaleString();
      }
      compareSummary.startDate = convertstartDate;
      compareSummary.endDate = convertendDate;
    });

    setTodayData(todayArray);
    setCompareData(compareArray);
    setDataforImport({
      today: today,
      compare: compare,
    });
  }, [orders, today, compare, compareSummary, todaySummary]);

  // ---------Chart
  const CHART_DATA = [
    {
      type: "column",
      name:
        fDate(today[0]?.startDate) == fDate(today[0]?.endDate)
          ? (fDate(today[0]?.startDate) && fDate(today[0]?.endDate)) ==
            fDate(new Date())
            ? "Today"
            : (fDate(today[0]?.startDate) && fDate(today[0]?.endDate)) ==
              fDate(addDays(new Date(), -1))
            ? "Yesterday"
            : fDate(today[0]?.startDate)
          : fDate(today[0]?.startDate) + " - " + fDate(today[0]?.endDate),
      data: todayData?.map((el) => {
        return el.avg;
      }),
    },

    {
      type: "line",
      name:
        compare?.length > 0 &&
        (fDate(compare[0]?.startDate) == fDate(compare[0]?.endDate)
          ? (fDate(today[0]?.startDate) && fDate(today[0]?.endDate)) ==
            fDate(new Date())
            ? "Compare: Yesterday"
            : "Compare: " + fDate(compare[0]?.startDate)
          : "Compare: " +
            fDate(compare[0]?.startDate) +
            " - " +
            fDate(compare[0]?.endDate)),
      data: compareData?.map((el) => {
        return Math.round(el.avg);
      }),
    },
  ];

  const chartOptions = merge(BaseOptionChart(), {
    // stroke: { width: [0, 2, 3] },
    plotOptions: { bar: { columnWidth: "11%", borderRadius: 4 } },
    fill: { type: ["solid", "solid"] },
    labels: orders.todayLabel ? orders.todayLabel : labels,
    xaxis: { type: "datetime" },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box" style="padding:10px">' +
          '<p><span class="apexcharts-legend-marker" rel="1" data:collapsed="false" style="background: rgb(0, 171, 85) !important; color: rgb(0, 171, 85); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span> ' +
          todayData[dataPointIndex]?.day +
          " : " +
          store.currency.symbol +
          Math.round(todayData[dataPointIndex]?.avg) +
          "</p>" +
          '<p><span class="apexcharts-legend-marker" rel="2" data:collapsed="false" style="background: rgb(255, 193, 7) !important; color: rgb(255, 193, 7); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span> ' +
          compareData[dataPointIndex]?.day +
          " : " +
          store.currency.symbol +
          Math.round(compareData[dataPointIndex]?.avg) +
          "</p>" +
          "</div>"
        );
      },
    },
  });

  const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
      <div className="print-source" ref={ref}>
        <Page>
          <Container>
            <Stack
              direction="row"
              spacing={2}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Stack direction="row" spacing={2} alignItems={"center"}>
                <Typography
                  variant="h5"
                  style={{ marginTop: "-40px", marginLeft: "40px" }}
                >
                  Average order value
                </Typography>
              </Stack>
            </Stack>
            {/* dates */}
            <Stack direction="row" spacing={1} marginTop={2} marginBottom={2}>
              <Button
                variant="outlined"
                startIcon={<Today />}
                onClick={(event) => (
                  setOpenToday(true), setAnchorEl(event.currentTarget)
                )}
              >
                {fDate(today[0]?.startDate) == fDate(today[0]?.endDate)
                  ? (fDate(today[0]?.startDate) && fDate(today[0]?.endDate)) ==
                    fDate(new Date())
                    ? "Today"
                    : (fDate(today[0]?.startDate) &&
                        fDate(today[0]?.endDate)) ==
                      fDate(addDays(new Date(), -1))
                    ? "Yesterday"
                    : fDate(today[0]?.startDate)
                  : fDate(today[0]?.startDate) +
                    " - " +
                    fDate(today[0]?.endDate)}
              </Button>
              <Button
                variant="outlined"
                onClick={(event) => (
                  setOpenCompare(true), setComAnchorEl(event.currentTarget)
                )}
              >
                {compare?.length > 0 &&
                  (fDate(compare[0]?.startDate) == fDate(compare[0]?.endDate)
                    ? (fDate(today[0]?.startDate) &&
                        fDate(today[0]?.endDate)) == fDate(new Date())
                      ? "Compare: Yesterday"
                      : "Compare: " + fDate(compare[0]?.startDate)
                    : "Compare: " +
                      fDate(compare[0]?.startDate) +
                      " - " +
                      fDate(compare[0]?.endDate))}
              </Button>
              {openToday && (
                <Popover
                  id={Boolean(anchorEl) ? "simple-popper" : undefined}
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <DatePicker
                    onSelect={(selected) => {
                      if (selected) {
                        setToday(selected);
                      }
                    }}
                    onClose={() => setAnchorEl(null)}
                    value={today}
                  />
                </Popover>
              )}

              {openCompare && (
                <Popover
                  id={Boolean(comanchorEl) ? "simple-popper" : undefined}
                  open={Boolean(comanchorEl)}
                  comanchorEl={comanchorEl}
                  onClose={() => setComAnchorEl(null)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <DatePicker
                    onSelect={(selected) => {
                      if (selected) {
                        setCompare(selected);
                      }
                    }}
                    onClose={() => setComAnchorEl(null)}
                    value={today}
                  />
                </Popover>
              )}
            </Stack>
            {!isLoading ? (
              <Box
                sx={{
                  p: 2,
                  border: "1px solid grey",
                  marginTop: 2,
                  borderRadius: 2,
                  backgroundColor: "whitesmoke",
                }}
              >
                <h4>Average Order Value</h4>
                <ReactApexChart
                  series={CHART_DATA}
                  options={chartOptions}
                  height={300}
                ></ReactApexChart>
              </Box>
            ) : (
              <>
                <Skeleton width={300} />
                <Skeleton width={300} />
                <Skeleton
                  sx={{ bgcolor: "grey" }}
                  variant="rounded"
                  width={1000}
                  height={400}
                />
              </>
            )}
            <Card sx={{ p: 3, mt: 2 }}>
              <TableContainer component={Paper}>
                {isLoading && <LinearProgress style={{ width: "100%" }} />}
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableRow
                    style={{
                      borderBottom: "1px solid #d5d5d5",
                      backgroundColor: "#80808024",
                    }}
                  >
                    <TableCell>Day</TableCell>
                    <TableCell align="right">Gross Sales</TableCell>
                    <TableCell align="right">Discounts</TableCell>
                    <TableCell align="right">Orders</TableCell>
                    <TableCell align="right">Average order value</TableCell>
                  </TableRow>

                  {!isLoading && (
                    <>
                      {/* <TableRow
                        style={{
                          borderBottom:
                            !compareSummary.startDate && "1px solid #d5d5d5",
                        }}
                      >
                        <TableCell>
                          {todaySummary.startDate} - {todaySummary.endDate} <br/>
                          {compareSummary.startDate} - {compareSummary.endDate}
                        </TableCell>
  
                        <TableCell align="right">
                          {store.currency.symbol} {todaySummary?.grossAmt} <br/> {store.currency.symbol} {compareSummary?.grossAmt}
                        </TableCell>
                        <TableCell align="right"> 
                          {store.currency.symbol} {todaySummary?.discount} <br/> {store.currency.symbol} {compareSummary?.discount}
                        </TableCell>
                        <TableCell align="right">
                          {todaySummary.ordreCount} <br/> {compareSummary.ordreCount}
                        </TableCell>
                        <TableCell align="right">
                          {store.currency.symbol} {Math.round(todaySummary?.avg)} <br/> {store.currency.symbol}{" "}
                            {Math.round(compareSummary?.avg)}
                        </TableCell>
                      </TableRow> */}

                      {compareData?.length > 0
                        ? todayData?.map((item, todayIndex) => {
                            return compareData?.map((com, comIndex) => {
                              if (todayIndex == comIndex) {
                                return (
                                  <TableRow
                                    style={{
                                      borderBottom: "1px solid #d5d5d5",
                                    }}
                                  >
                                    <TableCell>
                                      {item?.day}
                                      <br />
                                      {com?.day}
                                    </TableCell>
                                    <TableCell align="right">
                                      {store.currency.symbol} {item?.grossAmt}
                                      <br />
                                      {store.currency.symbol} {com?.grossAmt}
                                    </TableCell>

                                    <TableCell align="right">
                                      {store.currency.symbol} {item?.discount}
                                      <br />
                                      {store.currency.symbol} {com?.discount}
                                    </TableCell>
                                    <TableCell align="right">
                                      {item?.ordreCount}
                                      <br />
                                      {com?.ordreCount}
                                    </TableCell>
                                    <TableCell align="right">
                                      {store.currency.symbol}{" "}
                                      {Math.round(item?.avg)}
                                      <br />
                                      {store.currency.symbol}{" "}
                                      {Math.round(com?.avg)}
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            });
                          })
                        : todayData?.map((item, todayIndex) => {
                            return (
                              <TableRow
                                style={{
                                  borderBottom:
                                    !compareSummary.startDate &&
                                    "1px solid #d5d5d5",
                                }}
                              >
                                <TableCell>{item?.day}</TableCell>
                                <TableCell align="right">
                                  {store.currency.symbol} {item?.grossAmt}
                                </TableCell>

                                <TableCell align="right">
                                  {store.currency.symbol} {item?.discount}
                                </TableCell>
                                <TableCell align="right">
                                  {item?.ordreCount}
                                </TableCell>
                                <TableCell align="right">
                                  {store.currency.symbol}{" "}
                                  {Math.round(item?.avg)}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                    </>
                  )}
                </Table>
              </TableContainer>
            </Card>
          </Container>
        </Page>
      </div>
    );
  });
  return (
    <>
      <Stack spacing={2} direction="row" justifyContent={"space-between"}>
        <Stack>
          <Button
            variant="outlined"
            style={{
              //marginBottom: "16px",
              paddingTop: "5px",
              paddingBottom: "5px",
              // minHeight: "42px",
              minWidth: "0px",
              height: "43px",
              borderRadius: "5px",
            }}
            component={RouterLink}
            to={
              searchParams?.get("since")
                ? PATH_DASHBOARD.general.analytics
                : PATH_DASHBOARD.report.reports
            }
          >
            <img
              width="17px"
              src="https://th.bing.com/th/id/R.9562e3998adf90b7ea409f041aab337d?rik=ERFU3YLhocBkdA&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2fKin%2feBp%2fKineBpprT.png&ehk=t39BBIm39HbdTD3%2bBxa%2bIfTm948yrYikFKGQivAW9UU%3d&risl=&pid=ImgRaw&r=0"
            />
          </Button>
        </Stack>
        <Stack spacing={2} direction="row" alignItems={"left"}>
          <Button
            style={{ marginRight: 15 }}
            onClick={() => sethandleExport(true)}
          >
            Export
          </Button>
          <Button onClick={handlePrint}>Print</Button>
          {handleExport && (
            <AvgOrdvalExport
              sethandleExport={sethandleExport}
              data={dataforImport}
            />
          )}
        </Stack>
      </Stack>
      <ComponentToPrint ref={componentRef} />
    </>
  );
}
