const initialState = {
    GiftCardPayload: [],
    
    //  productTypePayload: []
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      // GetAll
      case "GET_ALL_GIFTCARD": {
        return {
          ...state,
          GiftCardPayload: action.payload,
        };
      }
      case "GET_GIFTCARD_SINGLE": {
        return {
          ...state,
          singleGiftCardPayload: action.payload,
        };
      }
      
      default:
        return state;
    }
  }
  