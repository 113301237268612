import request from '../Provider/ApiExt';
import { toast } from "react-toastify";
import axios from 'axios';
import { apiConfig } from '../../config';
// import ApiExt from '../Provider/ApiExt';

// Get Profile
export function getProfile() {
    return async function (dispatch) {
        dispatch({
            type: 'IS_FIRST_LOADING',
            payload: true
        });
        request.post('api/user/get-profile')
            .then(function (response) {
                if (response?.data?.status === true) {
                    dispatch({
                        type: 'GET_PROFILE',
                        payload: response?.data?.data
                    });
                    dispatch({
                        type: 'IS_FIRST_LOADING',
                        payload: false
                    })
                } else {
                    dispatch({
                        type: 'MESSAGE',
                        payload: {
                            type: "error",
                            text: "Server connection failed. Ensure network connection"
                        }
                    })
                }
            })
            .catch(function (error) {
            });
    }
}

// Post Setup
export function postSetup(domain, token) {
    return async function (dispatch) {
        dispatch({
            type: 'GET_SETUP',
            payload: false
        });
        axios({
            method: "post",
            url: 'https://' + domain + '/api/store-setup',
            headers: {
                "Content-Type": "application/json",
                'Authorization': `${token}`
            },
        }).then(function (response) {
            //handle success
            dispatch({
                type: 'GET_SETUP',
                payload: true
            });
        }).catch(function (response) {
            //handle error
            dispatch({
                type: 'GET_SETUP',
                payload: true
            });
        });
    }
}

// Add Theme
export function addTheme(domain, themeId, token, callback) {
    return async function (dispatch) {
        axios({
            method: "post",
            url: 'https://' + domain + '/api/add-theme',
            data: { themeId: themeId },
            headers: {
                "Content-Type": "application/json",
                'Authorization': `${token}`
            },
        }).then(function (response) {
            //handle success
            callback();
            toast.success("Theme added successfully");
        }).catch(function (response) {
            //handle error
        });
    }
}

// Remove Theme
export function removeTheme(domain, themeId, token, callback) {
    return async function (dispatch) {
        axios({
            method: "post",
            url: 'https://' + domain + '/api/remove-theme',
            data: { themeId: themeId },
            headers: {
                "Content-Type": "application/json",
                'Authorization': `${token}`
            },
        }).then(function (response) {
            //handle success
            callback();
            toast.success("Theme removed successfully");
        }).catch(function (response) {
            //handle error
        });
    }
}

// Publish Theme
export function publishTheme(domain, themeId, token, callback) {
    return async function (dispatch) {
        dispatch({
            type: 'IS_LOADING',
            payload: true
        });
        axios({
            method: "post",
            url: 'https://' + domain + '/api/publish-theme',
            data: { themeId: themeId },
            headers: {
                "Content-Type": "application/json",
                'Authorization': `${token}`
            },
        }).then(function (response) {
            //handle success
            callback();
            dispatch({
                type: 'IS_LOADING',
                payload: false
            });
            toast.success("Theme published successfully");
        }).catch(function (response) {
            //handle error
            dispatch({
                type: 'IS_LOADING',
                payload: false
            });
            toast.error("Some issue occured!");
        });
    }
}

// Set Editor Page
export function setEditorPage(domain, themeId, page, data, token, callback) {
    return async function (dispatch) {
        dispatch({
            type: 'IS_LOADING',
            payload: true
        });

        axios({
            method: "post",
            url: 'https://' + domain + '/api/editor/set-page',
            data: { themeId: themeId, page: page, data: data },
            headers: {
                "Content-Type": "application/json",
                'Authorization': `${token}`
            },
        }).then(function (response) {
            dispatch({
                type: 'IS_LOADING',
                payload: false
            });
            //handle success
            callback();
            toast.success("Theme updated successfully");
        }).catch(function (response) {
            //handle error
        });
    }
}

// Get Editor Page
export function getEditorPage(domain, themeId, page, token, callback) {
    return async function (dispatch) {
        dispatch({
            type: 'IS_LOADING',
            payload: true
        });
        //PAGE 
        axios({
            method: "post",
            url: 'https://' + domain + '/api/editor/get-page',
            data: { themeId: themeId, page: page },
            headers: {
                "Content-Type": "application/json",
                'Authorization': `${token}`
            },
        }).then(function (response) {
            //handle success
            callback(response.data);
            console.log('data captured', response.data?.data);
            dispatch({
                type: 'SET_EDITOR_PAGE_DATA',
                payload: response.data?.data
            });
            //toast.success("Page preference updated successfully");
        }).catch(function (response) {
            //handle error
            dispatch({
                type: 'IS_LOADING',
                payload: false
            });
            callback(response.data);
        });

        //HEADER
        axios({
            method: "post",
            url: 'https://' + domain + '/api/editor/get-page',
            data: { themeId: themeId, page: 'header' },
            headers: {
                "Content-Type": "application/json",
                'Authorization': `${token}`
            },
        }).then(function (response) {
            //handle success
            dispatch({
                type: 'SET_HEADER_PAGE_DATA',
                payload: response.data?.data
            });
        }).catch(function (response) {
            //handle error
            dispatch({
                type: 'IS_LOADING',
                payload: false
            });
        });

        //FOOTER
        axios({
            method: "post",
            url: 'https://' + domain + '/api/editor/get-page',
            data: { themeId: themeId, page: 'footer' },
            headers: {
                "Content-Type": "application/json",
                'Authorization': `${token}`
            },
        }).then(function (response) {
            //handle success
            dispatch({
                type: 'SET_FOOTER_PAGE_DATA',
                payload: response.data?.data
            });
            dispatch({
                type: 'IS_LOADING',
                payload: false
            });
        }).catch(function (response) {
            //handle error
            dispatch({
                type: 'IS_LOADING',
                payload: false
            });
        });
    }
}

// Get Editor Schema
export function getEditorSchema(domain, themeId, token, callback) {
    return async function (dispatch) {
        dispatch({
            type: 'IS_LOADING',
            payload: true
        });
        axios({
            method: "post",
            url: 'https://' + domain + '/api/editor/schema',
            data: { themeId: themeId },
            headers: {
                "Content-Type": "application/json",
                'Authorization': `${token}`
            },
        }).then(function (response) {
            //handle success
            callback(response.data);
            dispatch({
                type: 'SET_EDITOR_SCHEMA_DATA',
                payload: response.data?.sectionSchema
            });
            dispatch({
                type: 'IS_LOADING',
                payload: false
            });
            //toast.success("Page preference updated successfully");
        }).catch(function (response) {
            //handle error
            callback(response.data);
            dispatch({
                type: 'IS_LOADING',
                payload: false
            });
        });
    }
}

// Get Editor Schema
export function getPageSchema(domain, themeId, token, callback) {
    return async function (dispatch) {
        dispatch({
            type: 'IS_LOADING',
            payload: true
        });
        axios({
            method: "post",
            url: 'https://' + domain + '/api/page/schema',
            data: { themeId: themeId },
            headers: {
                "Content-Type": "application/json",
                'Authorization': `${token}`
            },
        }).then(function (response) {
            //handle success
            callback(response.data);
            dispatch({
                type: 'SET_PAGE_SCHEMA_DATA',
                payload: response.data?.data
            });
            dispatch({
                type: 'IS_LOADING',
                payload: false
            });
            //toast.success("Page preference updated successfully");
        }).catch(function (response) {
            //handle error
            callback(response.data);
            dispatch({
                type: 'IS_LOADING',
                payload: false
            });
        });
    }
}

// Get Editor Schema
export function getSettingsSchema(domain, themeId, token, callback) {
    return async function (dispatch) {
        dispatch({
            type: 'IS_LOADING',
            payload: true
        });
        axios({
            method: "post",
            url: 'https://' + domain + '/api/editor/settings-schema',
            data: { themeId: themeId },
            headers: {
                "Content-Type": "application/json",
                'Authorization': `${token}`
            },
        }).then(function (response) {
            //handle success
            callback(response.data);
            dispatch({
                type: 'SET_SETTINGS_SCHEMA',
                payload: response.data?.schema
            });
            dispatch({
                type: 'SET_SETTINGS_DATA',
                payload: response.data?.data
            });
            dispatch({
                type: 'IS_LOADING',
                payload: false
            });
            //toast.success("Page preference updated successfully");
        }).catch(function (response) {
            //handle error
            callback(response.data);
            dispatch({
                type: 'IS_LOADING',
                payload: false
            });
        });
    }
}

// Get Editor Schema
export function setSettingsData(domain, themeId, data, token, callback) {
    return async function (dispatch) {
        dispatch({
            type: 'IS_LOADING',
            payload: true
        });
        axios({
            method: "post",
            url: 'https://' + domain + '/api/editor/set-settings-data',
            data: { themeId: themeId, data: data },
            headers: {
                "Content-Type": "application/json",
                'Authorization': `${token}`
            },
        }).then(function (response) {
            //handle success
            callback(response.data);
            dispatch({
                type: 'SET_PAGE_SCHEMA_DATA',
                payload: response.data?.data
            });
            dispatch({
                type: 'IS_LOADING',
                payload: false
            });
            //toast.success("Page preference updated successfully");
        }).catch(function (response) {
            //handle error
            callback(response.data);
            dispatch({
                type: 'IS_LOADING',
                payload: false
            });
        });
    }
}

// Log out
export function logoutAction() {
    return async function (dispatch) {
        dispatch({
            type: 'RESET_STATE'
        })
        localStorage.clear();
        window.location.replace('https://accounts.planetshopping.com/auth/logout')
    }
}