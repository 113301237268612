const initialState = { billingPayload: [], plansPayload: [] };

export default function (state = initialState, action) {
  switch (action.type) {
    case "GET_ALL_BILLING": {
      return {
        ...state,
        billingPayload: action.payload,
      };
    }
    

    
    case "GET_PLANS": {
      return {
        ...state,
        plansPayload: action.payload,
      };
    }
    case "GET_MY_PLAN": {
      return {
        ...state,
        planPayload: action.payload,
      };
    }

    case "GET_BRAND": {
      return {
        ...state,
        brandPayload: action.payload,
      };
    }

    case "GET_SOCIAL_LINKS": {
      return {
        ...state,
        socialPayload: action.payload,
      };
    }

   //  case "UPDATE_CURRENCY": {
   //    console.log("a", action.payload);
   //    return {
   //      ...state,
   //      socialPayload: action.payload,
   //    };
   //  }

    default:
      return state;
  }
}
