import { toast } from "react-toastify";
import ApiExt from "../Provider/ApiExt";

export function uploadData(legaldata, setLoading, callback) {
  console.log("legaldata",legaldata);

  return async function (dispatch) {
    setLoading(true);
    dispatch({
      type: "IS_LOADING",
      payload: true,
    });

    const value = {
      data: {
        refund: legaldata?.data.refund || "",
        privacy: legaldata?.data.privacy || "",
        terms: legaldata?.data?.terms || "",
        shipping: legaldata?.data?.shipping || "",
        contact : legaldata?.data?.contact || ""
      },
    };

    ApiExt.post("/api/settings/update-legal", value)
      .then(function (response) {
        // console.log(
        //   "Update update overview action=================",
        //   response.data
        // );
        setLoading(false);
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        if (response.data.status === true) {
          toast.success(response?.data?.msg);
          dispatch({
            type: "UPDATE_LEGAL",
            payload: value,
          });
          callback();
        } else {
          toast.warn(response?.data?.msg);
        }
      })
      .catch(function (error) {
        setLoading(false);
        dispatch({
          type: "IS_LOADING",
          payload: false,
        });
        // toast.error("Something went wrong. Please try again.");
      });
  };
}
